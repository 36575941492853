<template>
    <!-- Skeleton table body -->
    <tr v-for="index in 20" :key="index">
        <td class="whitespace-nowrap px-2 py-3.5 pl-5">
            <div class="flex items-center">
                <div class="mr-2 shrink-0 sm:mr-3">
                    <div class="h-7 w-7 animate-pulse rounded-full bg-slate-200" />
                </div>

                <div class="mr-[30px] h-3 w-full animate-pulse rounded bg-slate-200" />
            </div>
        </td>

        <td class="whitespace-nowrap px-2 py-3.5 pr-5" colspan="100">
            <div class="h-3 w-full animate-pulse rounded bg-slate-200" />
        </td>
    </tr>
</template>

<script>
export default {
    name: 'SkeletonReportSubpage',
}
</script>
