<script setup>
import _ from 'lodash'
import numeral from 'numeral'
import moment from 'moment'
import { h, inject, watch, ref, computed } from 'vue'
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'
import DataTable from '@/components/ui/data-table/DataTableServerSide.vue'
import DataTableSortableColumnHeader from '@/components/ui/data-table/DataTableSortableColumnHeader.vue'
import ContentInfoCell from './content/ContentInfoCell.vue'
import ExternalLinkCell from './content/ExternalLinkCell.vue'

const axios = inject('axios')
const filters = inject('filters')
const store = useStore()
const router = useRouter()
const route = useRoute()

const props = defineProps({
    platformId: {
        type: String,
        required: true,
    },
    platformUserId: {
        type: String,
        required: true,
    },
    platformUserName: {
        type: String,
        required: true,
    },
    contentType: {
        type: String,
    },
    dayRange: {
        type: String,
    },
    games: {
        type: Object,
        default: () => {},
    },
})

const columns = computed(() => {
    switch (props.platformId) {
        case 'twitch':
            return [
                {
                    accessorKey: '_id',
                    meta: {
                        title: 'Title',
                    },
                    header: ({ column }) =>
                        h(DataTableSortableColumnHeader, { column, title: column.columnDef.meta.title }),
                    cell: ({ row }) => {
                        return h(ContentInfoCell, {
                            data: row.original,
                            platformId: props.platformId,
                            contentType: props.contentType,
                            row,
                        })
                    },
                    enableSorting: false,
                },
                {
                    accessorKey: 'games',
                    accessorFn: (row) => _.map(row.chapters, 'game_id'),
                    header: () => null,
                    cell: () => null,
                    enableHiding: false,
                },
                {
                    accessorKey: 'tags',
                    accessorFn: (row) => _.map(row.tag_ids, 'tag_id'),
                    header: () => null,
                    cell: () => null,
                    enableHiding: false,
                },
                {
                    accessorKey: 'started_at',
                    meta: {
                        title: 'Started At',
                    },
                    header: ({ column }) =>
                        h(DataTableSortableColumnHeader, { column, title: column.columnDef.meta.title }),
                    cell: ({ row, column }) => {
                        return h(
                            'div',
                            { class: 'whitespace-nowrap' },
                            moment(row.getValue(column.id)).format('MMM Do, YY @ h:mm A'),
                        )
                    },
                },
                {
                    accessorKey: 'ended_at',
                    meta: {
                        title: 'Ended At',
                    },
                    header: ({ column }) =>
                        h(DataTableSortableColumnHeader, { column, title: column.columnDef.meta.title }),
                    cell: ({ row, column }) => {
                        return h(
                            'div',
                            { class: 'whitespace-nowrap' },
                            moment(row.getValue(column.id)).format('MMM Do, YY @ h:mm A'),
                        )
                    },
                },
                {
                    accessorKey: 'duration_seconds',
                    accessorFn: (row) => row.duration_seconds * 1000,
                    meta: {
                        title: 'Hours Streamed',
                    },
                    header: ({ column }) =>
                        h(DataTableSortableColumnHeader, { column, title: column.columnDef.meta.title }),
                    cell: ({ row, column }) => {
                        return h('div', filters.humanizeDurationShort(row.getValue(column.id)))
                    },
                },
                {
                    accessorKey: 'avg_viewers',
                    accessorFn: (row) => row?.statistics?.avg_viewers ?? 0,
                    meta: {
                        sortKey: 'statistics.avg_viewers',
                        title: 'Avg Viewers',
                    },
                    header: ({ column }) =>
                        h(DataTableSortableColumnHeader, { column, title: column.columnDef.meta.title }),
                    cell: ({ row, column }) => {
                        return h('div', numeral(row.getValue(column.id)).format('0,0'))
                    },
                },
                {
                    accessorKey: 'peak_viewers',
                    accessorFn: (row) => row?.statistics?.peak_viewers?.value ?? 0,
                    meta: {
                        title: 'Peak Viewers',
                    },
                    header: ({ column }) =>
                        h(DataTableSortableColumnHeader, { column, title: column.columnDef.meta.title }),
                    cell: ({ row, column }) => {
                        return h('div', numeral(row.getValue(column.id)).format('0,0'))
                    },
                },
                {
                    accessorKey: 'duration_watched',
                    accessorFn: (row) => row.statistics.sum_minutes_watched / 60 / 60,
                    meta: {
                        title: 'Hours Watched',
                    },
                    header: ({ column }) =>
                        h(DataTableSortableColumnHeader, { column, title: column.columnDef.meta.title }),
                    cell: ({ row, column }) => {
                        return h('div', numeral(row.getValue(column.id)).format('0,0'))
                    },
                },
                {
                    accessorKey: 'sum_views',
                    accessorFn: (row) => row?.statistics?.sum_views ?? 0,
                    meta: {
                        title: 'Total Views',
                    },
                    header: ({ column }) =>
                        h(DataTableSortableColumnHeader, { column, title: column.columnDef.meta.title }),
                    cell: ({ row, column }) => {
                        return h('div', numeral(row.getValue(column.id)).format('0,0'))
                    },
                },
                {
                    accessorKey: 'engagement_rate',
                    accessorFn: (row) => row?.statistics?.engagement_rate ?? 0,
                    meta: {
                        title: 'Engagement Rate',
                    },
                    header: ({ column }) =>
                        h(DataTableSortableColumnHeader, { column, title: column.columnDef.meta.title }),
                    cell: ({ row, column }) => {
                        return h('div', numeral(row.getValue(column.id)).format('0.0%'))
                    },
                },
                {
                    accessorKey: 'avg_chatters',
                    accessorFn: (row) => row?.statistics?.avg_chatters ?? 0,
                    meta: {
                        title: 'Avg Chatters',
                    },
                    header: ({ column }) =>
                        h(DataTableSortableColumnHeader, { column, title: column.columnDef.meta.title }),
                    cell: ({ row, column }) => {
                        return h('div', numeral(row.getValue(column.id)).format('0,0'))
                    },
                },
                {
                    accessorKey: 'avg_messages',
                    accessorFn: (row) => row?.statistics?.avg_messages ?? 0,
                    meta: {
                        title: 'Avg Messages',
                    },
                    header: ({ column }) =>
                        h(DataTableSortableColumnHeader, { column, title: column.columnDef.meta.title }),
                    cell: ({ row, column }) => {
                        return h('div', numeral(row.getValue(column.id)).format('0,0'))
                    },
                },
                {
                    accessorKey: 'total_messages',
                    accessorFn: (row) => row?.statistics?.total_messages ?? 0,
                    meta: {
                        title: 'Total Messages',
                    },
                    header: ({ column }) =>
                        h(DataTableSortableColumnHeader, { column, title: column.columnDef.meta.title }),
                    cell: ({ row, column }) => {
                        return h('div', numeral(row.getValue(column.id)).format('0,0'))
                    },
                },
            ].filter((column) => (column?.isEnabled ? column.isEnabled() : true))
        case 'youtube':
            return [
                {
                    accessorKey: '_id',
                    meta: {
                        title: 'Title',
                    },
                    header: ({ column }) =>
                        h(DataTableSortableColumnHeader, { column, title: column.columnDef.meta.title }),
                    cell: ({ row }) => {
                        return h(ContentInfoCell, {
                            data: row.original,
                            platformId: props.platformId,
                            contentType: props.contentType,
                            row,
                        })
                    },
                    enableSorting: false,
                },
                {
                    accessorKey: 'games',
                    accessorFn: (row) => _.map(row.chapters, 'game_id'),
                    header: () => null,
                    cell: () => null,
                    enableHiding: false,
                },
                {
                    accessorKey: 'tags',
                    accessorFn: (row) => _.map(row.tag_ids, 'tag_id'),
                    header: () => null,
                    cell: () => null,
                    enableHiding: false,
                },
                {
                    accessorKey: 'started_at',
                    accessorFn: (row) => row.snippet?.publishedAt,
                    meta: {
                        title: 'Posted At',
                    },
                    header: ({ column }) =>
                        h(DataTableSortableColumnHeader, { column, title: column.columnDef.meta.title }),
                    cell: ({ row, column }) => {
                        return h(
                            'div',
                            { class: 'whitespace-nowrap' },
                            moment(row.getValue(column.id)).format('MMM Do, YY @ h:mm A'),
                        )
                    },
                },
                {
                    accessorKey: 'category_id',
                    accessorFn: (row) => row.snippet?.categoryId,
                    meta: {
                        title: 'Category',
                    },
                    header: ({ column }) =>
                        h(DataTableSortableColumnHeader, { column, title: column.columnDef.meta.title }),
                    cell: ({ row, column }) => {
                        return h(
                            'div',
                            { class: 'whitespace-nowrap' },
                            store.getters.getFromDictionary(`etl.youtube.videoCategory.${row.getValue(column.id)}`),
                        )
                    },
                },
                {
                    accessorKey: 'duration',
                    accessorFn: (row) => row.contentDetails?.duration,
                    meta: {
                        title: 'Duration',
                    },
                    header: ({ column }) =>
                        h(DataTableSortableColumnHeader, { column, title: column.columnDef.meta.title }),
                    cell: ({ row, column }) => {
                        const humanizedDuration = filters.humanizeDurationShort(
                            moment.duration(row.getValue(column.id)).asSeconds() * 1000,
                        )

                        return h('div', humanizedDuration)
                    },
                    isEnabled: () => props.contentType !== 'stream',
                },
                {
                    accessorKey: 'duration',
                    accessorFn: (row) => {
                        const startTime = row?.liveStreamingDetails?.actualStartTime
                        const endTime = row?.liveStreamingDetails?.actualEndTime

                        if (!startTime || !endTime) return null
                        return moment.utc(endTime).diff(startTime, 'milliseconds')
                    },
                    meta: {
                        title: 'Duration',
                    },
                    header: ({ column }) =>
                        h(DataTableSortableColumnHeader, { column, title: column.columnDef.meta.title }),
                    cell: ({ row, column }) => {
                        if (!row.getValue(column.id)) return h('div', '---')

                        const humanizedDuration = filters.humanizeDurationShort(row.getValue(column.id))

                        return h('div', humanizedDuration)
                    },
                    isEnabled: () => props.contentType === 'stream',
                },
                {
                    accessorKey: 'avg_viewers',
                    accessorFn: (row) => row?.aggregates?.avg_viewers ?? 0,
                    meta: {
                        title: 'Avg Viewers',
                    },
                    header: ({ column }) =>
                        h(DataTableSortableColumnHeader, { column, title: column.columnDef.meta.title }),
                    cell: ({ row, column }) => {
                        return h(
                            'div',
                            row.getValue(column.id) ? numeral(row.getValue(column.id)).format('0,0') : '---',
                        )
                    },
                    isEnabled: () => props.contentType === 'stream',
                    enableSorting: false,
                },
                {
                    accessorKey: 'avg_chatters',
                    accessorFn: (row) => row?.aggregates?.avg_chatters ?? 0,
                    meta: {
                        title: 'Avg Chatters',
                    },
                    header: ({ column }) =>
                        h(DataTableSortableColumnHeader, { column, title: column.columnDef.meta.title }),
                    cell: ({ row, column }) => {
                        return h(
                            'div',
                            row.getValue(column.id) ? numeral(row.getValue(column.id)).format('0,0') : '---',
                        )
                    },
                    isEnabled: () => props.contentType === 'stream',
                    enableSorting: false,
                },
                {
                    accessorKey: 'avg_messages',
                    accessorFn: (row) => row?.aggregates?.avg_messages ?? 0,
                    meta: {
                        title: 'Avg Messages',
                    },
                    header: ({ column }) =>
                        h(DataTableSortableColumnHeader, { column, title: column.columnDef.meta.title }),
                    cell: ({ row, column }) => {
                        return h(
                            'div',
                            row.getValue(column.id) ? numeral(row.getValue(column.id)).format('0,0') : '---',
                        )
                    },
                    isEnabled: () => props.contentType === 'stream',
                    enableSorting: false,
                },
                {
                    accessorKey: 'duration_watched',
                    accessorFn: (row) => {
                        return row.aggregates?.minutes_watched ? row.aggregates.minutes_watched / 60 / 60 : null
                    },
                    meta: {
                        title: 'Hours Watched',
                    },
                    header: ({ column }) =>
                        h(DataTableSortableColumnHeader, { column, title: column.columnDef.meta.title }),
                    cell: ({ row, column }) => {
                        return h(
                            'div',
                            row.getValue(column.id) ? numeral(row.getValue(column.id)).format('0,0') : '---',
                        )
                    },
                    isEnabled: () => props.contentType === 'stream',
                    enableSorting: false,
                },
                {
                    accessorKey: 'sum_views',
                    accessorFn: (row) => row?.statistics?.viewCount ?? 0,
                    meta: {
                        title: 'Views',
                    },
                    header: ({ column }) =>
                        h(DataTableSortableColumnHeader, { column, title: column.columnDef.meta.title }),
                    cell: ({ row, column }) => {
                        return h('div', numeral(row.getValue(column.id)).format('0,0'))
                    },
                },
                {
                    accessorKey: 'sum_likes',
                    accessorFn: (row) => row?.statistics?.likeCount ?? 0,
                    meta: {
                        title: 'Likes',
                    },
                    header: ({ column }) =>
                        h(DataTableSortableColumnHeader, { column, title: column.columnDef.meta.title }),
                    cell: ({ row, column }) => {
                        return h('div', numeral(row.getValue(column.id)).format('0,0'))
                    },
                },
                {
                    accessorKey: 'sum_comments',
                    accessorFn: (row) => row?.statistics?.commentCount ?? 0,
                    meta: {
                        title: 'Comments',
                    },
                    header: ({ column }) =>
                        h(DataTableSortableColumnHeader, { column, title: column.columnDef.meta.title }),
                    cell: ({ row, column }) => {
                        return h('div', numeral(row.getValue(column.id)).format('0,0'))
                    },
                },
                {
                    accessorKey: 'stream_engagement_rate',
                    accessorFn: (row) => {
                        return row?.aggregates?.avg_chatters && row?.aggregates?.avg_viewers
                            ? row.aggregates.avg_chatters / row.aggregates.avg_viewers
                            : null
                    },
                    meta: {
                        title: 'Engagement Rate',
                    },
                    header: ({ column }) =>
                        h(DataTableSortableColumnHeader, { column, title: column.columnDef.meta.title }),
                    cell: ({ row, column }) => {
                        return h(
                            'div',
                            row.getValue(column.id) ? numeral(row.getValue(column.id)).format('0.0%') : '---',
                        )
                    },
                    isEnabled: () => props.contentType === 'stream',
                    enableSorting: false,
                },
                {
                    accessorKey: 'video_engagement_rate',
                    accessorFn: (row) => {
                        const comments = row?.statistics?.commentCount ?? 0
                        const likes = row?.statistics?.likeCount ?? 0
                        const views = row?.statistics?.viewCount ?? 0
                        return views ? (comments + likes) / views : 0
                    },
                    meta: {
                        title: 'Engagement Rate',
                    },
                    header: ({ column }) =>
                        h(DataTableSortableColumnHeader, { column, title: column.columnDef.meta.title }),
                    cell: ({ row, column }) => {
                        return h(
                            'div',
                            row.getValue(column.id) ? numeral(row.getValue(column.id)).format('0.0%') : '---',
                        )
                    },
                    isEnabled: () => props.contentType !== 'stream',
                    enableSorting: false,
                },
                {
                    accessorKey: 'link',
                    accessorFn: (row) => {
                        return `https://youtube.com/watch?v=${row._id}`
                    },
                    meta: {
                        title: 'Link',
                    },
                    header: ({ column }) =>
                        h(DataTableSortableColumnHeader, { column, title: column.columnDef.meta.title }),
                    cell: ({ row, column }) => {
                        return h(ExternalLinkCell, {
                            link: row.getValue(column.id),
                        })
                    },
                    enableSorting: false,
                },
            ].filter((column) => (column?.isEnabled ? column.isEnabled() : true))
        case 'tiktok':
            return [
                {
                    accessorKey: '_id',
                    meta: {
                        title: 'Title',
                    },
                    header: ({ column }) =>
                        h(DataTableSortableColumnHeader, { column, title: column.columnDef.meta.title }),
                    cell: ({ row }) => {
                        return h(ContentInfoCell, {
                            data: row.original,
                            platformId: props.platformId,
                            contentType: props.contentType,
                            row,
                        })
                    },
                    enableSorting: false,
                },
                {
                    accessorKey: 'tags',
                    accessorFn: (row) => _.map(row.tag_ids, 'tag_id'),
                    header: () => null,
                    cell: () => null,
                    enableHiding: false,
                },
                {
                    accessorKey: 'started_at',
                    accessorFn: (row) => row.createdAt,
                    meta: {
                        title: 'Posted At',
                    },
                    header: ({ column }) =>
                        h(DataTableSortableColumnHeader, { column, title: column.columnDef.meta.title }),
                    cell: ({ row, column }) => {
                        return h(
                            'div',
                            { class: 'whitespace-nowrap' },
                            moment(row.getValue(column.id)).format('MMM Do, YY @ h:mm A'),
                        )
                    },
                },
                {
                    accessorKey: 'duration',
                    accessorFn: (row) => row.video?.duration,
                    meta: {
                        title: 'Duration',
                    },
                    header: ({ column }) =>
                        h(DataTableSortableColumnHeader, { column, title: column.columnDef.meta.title }),
                    cell: ({ row, column }) => {
                        const humanizedDuration = filters.humanizeDurationShort(
                            Math.round(row.getValue(column.id) / 1000) * 1000,
                        )

                        return h('div', humanizedDuration)
                    },
                },
                {
                    accessorKey: 'sum_views',
                    accessorFn: (row) => row?.statistics?.views ?? 0,
                    meta: {
                        title: 'Views',
                    },
                    header: ({ column }) =>
                        h(DataTableSortableColumnHeader, { column, title: column.columnDef.meta.title }),
                    cell: ({ row, column }) => {
                        return h('div', numeral(row.getValue(column.id)).format('0,0'))
                    },
                },
                {
                    accessorKey: 'sum_likes',
                    accessorFn: (row) => row?.statistics?.likes ?? 0,
                    meta: {
                        title: 'Likes',
                    },
                    header: ({ column }) =>
                        h(DataTableSortableColumnHeader, { column, title: column.columnDef.meta.title }),
                    cell: ({ row, column }) => {
                        return h('div', numeral(row.getValue(column.id)).format('0,0'))
                    },
                },
                {
                    accessorKey: 'sum_comments',
                    accessorFn: (row) => row?.statistics?.comments ?? 0,
                    meta: {
                        title: 'Comments',
                    },
                    header: ({ column }) =>
                        h(DataTableSortableColumnHeader, { column, title: column.columnDef.meta.title }),
                    cell: ({ row, column }) => {
                        return h('div', numeral(row.getValue(column.id)).format('0,0'))
                    },
                },
                {
                    accessorKey: 'engagement_rate',
                    accessorFn: (row) => {
                        const comments = row?.statistics?.comments ?? 0
                        const likes = row?.statistics?.likes ?? 0
                        const views = row?.statistics?.views ?? 0
                        return views ? (comments + likes) / views : 0
                    },
                    meta: {
                        title: 'Engagement Rate',
                    },
                    header: ({ column }) =>
                        h(DataTableSortableColumnHeader, { column, title: column.columnDef.meta.title }),
                    cell: ({ row, column }) => {
                        return h(
                            'div',
                            row.getValue(column.id) ? numeral(row.getValue(column.id)).format('0.0%') : '---',
                        )
                    },
                    isEnabled: () => props.contentType !== 'stream',
                    enableSorting: false,
                },
                {
                    accessorKey: 'link',
                    accessorFn: (row) => {
                        return `https://www.tiktok.com/@${props.platformUserName}/video/${row._id}`
                    },
                    meta: {
                        title: 'Link',
                    },
                    header: ({ column }) =>
                        h(DataTableSortableColumnHeader, { column, title: column.columnDef.meta.title }),
                    cell: ({ row, column }) => {
                        return h(ExternalLinkCell, {
                            link: row.getValue(column.id),
                        })
                    },
                    enableSorting: false,
                },
            ].filter((column) => (column?.isEnabled ? column.isEnabled() : true))
        case 'twitter':
            return [
                {
                    accessorKey: '_id',
                    meta: {
                        title: 'Title',
                    },
                    header: ({ column }) =>
                        h(DataTableSortableColumnHeader, { column, title: column.columnDef.meta.title }),
                    enableSorting: false,
                    cell: ({ row }) => {
                        return h(ContentInfoCell, {
                            data: row.original,
                            platformId: props.platformId,
                            contentType: props.contentType,
                            row,
                        })
                    },
                },
                {
                    accessorKey: 'tags',
                    accessorFn: (row) => _.map(row.tag_ids, 'tag_id'),
                    header: () => null,
                    cell: () => null,
                    enableHiding: false,
                },
                {
                    accessorKey: 'started_at',
                    accessorFn: (row) => row.created_at,
                    meta: {
                        title: 'Posted At',
                    },
                    header: ({ column }) =>
                        h(DataTableSortableColumnHeader, { column, title: column.columnDef.meta.title }),
                    cell: ({ row, column }) => {
                        return h(
                            'div',
                            { class: 'whitespace-nowrap' },
                            moment(row.getValue(column.id)).format('MMM Do, YY @ h:mm A'),
                        )
                    },
                },
                {
                    accessorKey: 'sum_views',
                    accessorFn: (row) => row?.statistics?.views ?? 0,
                    meta: {
                        title: 'Views',
                    },
                    header: ({ column }) =>
                        h(DataTableSortableColumnHeader, { column, title: column.columnDef.meta.title }),
                    cell: ({ row, column }) => {
                        return h('div', numeral(row.getValue(column.id)).format('0,0'))
                    },
                },
                {
                    accessorKey: 'sum_likes',
                    accessorFn: (row) => row?.statistics?.likes ?? 0,
                    meta: {
                        title: 'Likes',
                    },
                    header: ({ column }) =>
                        h(DataTableSortableColumnHeader, { column, title: column.columnDef.meta.title }),
                    cell: ({ row, column }) => {
                        return h('div', numeral(row.getValue(column.id)).format('0,0'))
                    },
                },
                {
                    accessorKey: 'sum_comments',
                    accessorFn: (row) => row?.statistics?.replies ?? 0,
                    meta: {
                        title: 'Comments',
                    },
                    header: ({ column }) =>
                        h(DataTableSortableColumnHeader, { column, title: column.columnDef.meta.title }),
                    cell: ({ row, column }) => {
                        return h('div', numeral(row.getValue(column.id)).format('0,0'))
                    },
                },
                {
                    accessorKey: 'engagement_rate',
                    accessorFn: (row) => {
                        const comments = row?.statistics?.replies ?? 0
                        const likes = row?.statistics?.likes ?? 0
                        const views = row?.statistics?.views ?? 0
                        return views ? (comments + likes) / views : 0
                    },
                    meta: {
                        title: 'Engagement Rate',
                    },
                    header: ({ column }) =>
                        h(DataTableSortableColumnHeader, { column, title: column.columnDef.meta.title }),
                    enableSorting: false,
                    cell: ({ row, column }) => {
                        return h(
                            'div',
                            row.getValue(column.id) ? numeral(row.getValue(column.id)).format('0.0%') : '---',
                        )
                    },
                    isEnabled: () => props.contentType !== 'stream',
                },
                {
                    accessorKey: 'link',
                    accessorFn: (row) => {
                        return `https://twitter.com/${props.platformUserName}/status/${row._id}`
                    },
                    meta: {
                        title: 'Link',
                    },
                    header: ({ column }) =>
                        h(DataTableSortableColumnHeader, { column, title: column.columnDef.meta.title }),
                    enableSorting: false,
                    cell: ({ row, column }) => {
                        return h(ExternalLinkCell, {
                            link: row.getValue(column.id),
                        })
                    },
                },
            ].filter((column) => (column?.isEnabled ? column.isEnabled() : true))
        case 'instagram':
            return [
                {
                    accessorKey: '_id',
                    meta: {
                        title: 'Title',
                    },
                    header: ({ column }) =>
                        h(DataTableSortableColumnHeader, { column, title: column.columnDef.meta.title }),
                    enableSorting: false,
                    cell: ({ row }) => {
                        return h(ContentInfoCell, {
                            data: row.original,
                            platformId: props.platformId,
                            contentType: props.contentType,
                            row,
                        })
                    },
                },
                {
                    accessorKey: 'tags',
                    accessorFn: (row) => row.hashtags,
                    header: () => null,
                    cell: () => null,
                    enableHiding: false,
                },
                {
                    accessorKey: 'type',
                    accessorFn: (row) => row.type,
                    meta: {
                        title: 'Type',
                    },
                    header: ({ column }) =>
                        h(DataTableSortableColumnHeader, { column, title: column.columnDef.meta.title }),
                    cell: ({ row, column }) => {
                        return h('div', { class: 'whitespace-nowrap capitalize' }, row.getValue(column.id))
                    },
                },
                {
                    accessorKey: 'started_at',
                    accessorFn: (row) => row.created_at,
                    meta: {
                        title: 'Posted At',
                    },
                    header: ({ column }) =>
                        h(DataTableSortableColumnHeader, { column, title: column.columnDef.meta.title }),
                    cell: ({ row, column }) => {
                        return h(
                            'div',
                            { class: 'whitespace-nowrap' },
                            moment(row.getValue(column.id)).format('MMM Do, YY @ h:mm A'),
                        )
                    },
                },
                {
                    accessorKey: 'sum_views',
                    accessorFn: (row) => row?.statistics?.views ?? 0,
                    meta: {
                        title: 'Views',
                    },
                    header: ({ column }) =>
                        h(DataTableSortableColumnHeader, { column, title: column.columnDef.meta.title }),
                    cell: ({ row, column }) => {
                        return h(
                            'div',
                            row.getValue(column.id) ? numeral(row.getValue(column.id)).format('0,0') : '---',
                        )
                    },
                },
                {
                    accessorKey: 'sum_likes',
                    accessorFn: (row) => row?.statistics?.likes ?? 0,
                    meta: {
                        title: 'Likes',
                    },
                    header: ({ column }) =>
                        h(DataTableSortableColumnHeader, { column, title: column.columnDef.meta.title }),
                    cell: ({ row, column }) => {
                        return h('div', numeral(row.getValue(column.id)).format('0,0'))
                    },
                },
                {
                    accessorKey: 'sum_comments',
                    accessorFn: (row) => row?.statistics?.comments ?? 0,
                    meta: {
                        title: 'Comments',
                    },
                    header: ({ column }) =>
                        h(DataTableSortableColumnHeader, { column, title: column.columnDef.meta.title }),
                    cell: ({ row, column }) => {
                        return h('div', numeral(row.getValue(column.id)).format('0,0'))
                    },
                },
                {
                    accessorKey: 'engagement_rate',
                    accessorFn: (row) => row?.statistics?.engagement_rate ?? 0,
                    meta: {
                        title: 'Engagement Rate',
                    },
                    header: ({ column }) =>
                        h(DataTableSortableColumnHeader, { column, title: column.columnDef.meta.title }),
                    enableSorting: false,
                    cell: ({ row, column }) => {
                        return h(
                            'div',
                            row.getValue(column.id) ? numeral(row.getValue(column.id)).format('0.0%') : '---',
                        )
                    },
                    isEnabled: () => props.contentType !== 'stream',
                },
                {
                    accessorKey: 'link',
                    accessorFn: (row) => {
                        return `https://instagram.com/p/${row.code}`
                    },
                    meta: {
                        title: 'Link',
                    },
                    header: ({ column }) =>
                        h(DataTableSortableColumnHeader, { column, title: column.columnDef.meta.title }),
                    enableSorting: false,
                    cell: ({ row, column }) => {
                        return h(ExternalLinkCell, {
                            link: row.getValue(column.id),
                        })
                    },
                },
            ].filter((column) => (column?.isEnabled ? column.isEnabled() : true))
        default:
            return []
    }
})

const toolbar = ref({
    placeholder: 'Filter by title...',
    columnToFilter: '_id', // Assuming 'tag' is the main field to filter on
    filterValue: '',
})

const rowCount = ref(-1)

const getPreferences = () => {
    return store?.state?.preferences?.tables['creator-content']
}

const pagination = ref(
    (() => {
        return {
            pageIndex: 0,
            pageSize: getPreferences()?.paging?.pageSize ? getPreferences().paging.pageSize : 10,
        }
    })(),
)
const sorting = ref(
    (() => {
        return getPreferences()?.sorting
            ? getPreferences().sorting
            : [
                  {
                      id: 'started_at',
                      desc: true,
                  },
              ]
    })(),
)

const filtering = ref([])

const data = ref([])
const isLoaded = ref(false)

const fetchData = async () => {
    isLoaded.value = false
    const endpoint = `/platforms/${props.platformId}/${props.platformUserId}/content`

    try {
        const requestParams = {
            content_type: props.contentType,
            day_range: props.dayRange,
            limit: pagination.value.pageSize,
            offset: pagination.value.pageSize * pagination.value.pageIndex,
            sort_field: sorting.value?.[0]?.id,
            sort_direction: sorting.value?.[0]?.desc === true ? -1 : 1,
            text_filter: toolbar.value.filterValue ? toolbar.value.filterValue : undefined,
        }

        if (filtering?.value?.length > 0) {
            _.forEach(filtering.value, ({ id, value }) => {
                requestParams[id] = value
            })
        }

        const response = await axios.get(endpoint, {
            params: requestParams,
        })

        data.value = response.data.payload.content
        rowCount.value = response.data.payload.count
        isLoaded.value = true
    } catch (error) {
        console.error('Error fetching tags data:', error)
        data.value = []
        isLoaded.value = true
    }
}

const tags = ref([])
const isTagsLoaded = ref(false)

const fetchTags = async () => {
    isTagsLoaded.value = false
    const endpoint = `/platforms/${props.platformId}/${props.platformUserId}/tags`

    try {
        const response = await axios.get(endpoint, {
            params: {
                content_type: props.contentType,
                day_range: props.dayRange,
                limit: 100,
                offset: 0,
            },
        })
        tags.value = _.filter(response.data.payload.tags, (item) => item._id)
        isTagsLoaded.value = true
    } catch (error) {
        console.error('Error fetching tags data:', error)
        tags.value = []
        isTagsLoaded.value = true
    }
}

const filterFields = computed(() => [
    {
        label: 'Tags',
        value: 'tags',
        options: _(tags.value)
            .map((item) => {
                return {
                    label: item.tag,
                    value: item.tag,
                    count: item?.count,
                    // icon: getPriorityIcon(priority),
                    withCount: true,
                }
            })
            .orderBy(['count'], ['desc'])
            .value(),
    },
    {
        label: 'Games',
        value: 'games',
        options: _(props?.games)
            .map((game, gameKey) => ({
                label: game.name,
                value: gameKey,
                count: game.count,
                // icon: getStatusIcon(status),
                withCount: true,
            }))
            .orderBy(['count'], ['desc'])
            .value(),
    },
])

if (route.query['profile.tabs.secondary.filters']) {
    filtering.value = []

    console.log(route.query['profile.tabs.secondary.filters'])

    const urlParameterFilters = Array.isArray(route.query['profile.tabs.secondary.filters'])
        ? route.query['profile.tabs.secondary.filters']
        : [route.query['profile.tabs.secondary.filters']]

    _.forEach(urlParameterFilters, (urlParameterFilter) => {
        console.log(urlParameterFilter, decodeURIComponent(urlParameterFilter))

        const [id, value] = decodeURIComponent(urlParameterFilter).split(':')

        // Only append a filter if it's an option
        const values = value?.includes(',') ? value.split(',') : [value]

        // const validValues = _.filter(values, (item) => {
        //     const filterField = _.find(filterFields.value, filterItem => filterItem.value === id)

        //     if (filterField) {
        //         const filterFieldOption = _.find(filterField.options, option => option.value === item)

        //         if (filterFieldOption) {
        //             return true
        //         }
        //     }

        //     return false
        // })

        filtering.value.push({
            id,
            value: values,
        })

        console.log('filtering.value', filtering.value)
    })
}

// Anytime the filters change, persist it to the url
watch(
    () => [filtering],
    () => {
        router.push({
            query: {
                ...route.query,
                'profile.tabs.secondary.filters': _.map(filtering.value, ({ id, value }) => {
                    console.log(`${encodeURIComponent(id)}:${encodeURIComponent(value)}`)
                    return `${encodeURIComponent(id)}:${encodeURIComponent(value)}`
                }),
            },
        })
    },
    {
        immediate: false,
        deep: true,
    },
)

watch(
    () => [
        props.platformId,
        props.platformUserId,
        props.contentType,
        props.dayRange,
        pagination,
        sorting,
        toolbar,
        filtering,
    ],
    fetchData,
    {
        immediate: true, // This ensures fetchData is called on initial render too
        deep: true,
    },
)

// Anytime the content type or day range are changed, clear filters
watch(
    () => [props.contentType, props.dayRange],
    () => {
        filtering.value = []
    },
    {
        immediate: false,
    },
)

watch(
    () => [props.platformId, props.platformUserId, props.contentType, props.dayRange],
    () => {
        fetchTags()
    },
    {
        immediate: true,
    },
)

const handlePaginationUpdate = (newPagination) => {
    pagination.value.pageSize = newPagination.pageSize
    pagination.value.pageIndex = newPagination.pageIndex
    // console.log('@update:pagination', pagination.value)
}

const handleSortingUpdate = (newSorting) => {
    sorting.value = [...newSorting]
    // console.log('@update:sorting', sorting.value)
}

const handleTextFilterUpdate = (newTextFilterValue) => {
    toolbar.value.filterValue = newTextFilterValue
    pagination.value.pageIndex = 0
    // console.log('@update:text-filter', newTextFilterValue)
}

const handleFilteringUpdate = (newFilteringValue) => {
    filtering.value = [...newFilteringValue]
    pagination.value.pageIndex = 0
    // console.log('@update:filtering', newFilteringValue)
}

const debouncedTextFilterUpdate = _.debounce(handleTextFilterUpdate, 250)
</script>

<template>
    <DataTable
        :columns="columns"
        :data="data"
        :toolbar="toolbar"
        :is-loaded="isLoaded"
        :row-count="rowCount"
        :is-server-side="true"
        :pagination="pagination"
        :filter-fields="filterFields"
        :filtering="filtering"
        table-header-cell-class="first:pl-4 whitespace-nowrap"
        table-cell-class="px-3 py-0 text-sm data-[subrow=true]:bg-slate-100 h-[50px]"
        preferences-id="creator-content"
        @update:pagination="handlePaginationUpdate"
        @update:sorting="handleSortingUpdate"
        @update:text-filter="debouncedTextFilterUpdate"
        @update:filtering="handleFilteringUpdate"
    >
        <template #loading-skeleton>
            <tr v-for="rowIndex in pagination.pageSize" :key="rowIndex">
                <td v-for="colIndex in columns?.length" :key="colIndex" class="h-[50px]">
                    <div class="my-0.5 h-full w-full animate-pulse rounded bg-slate-100" />
                </td>
            </tr>
        </template>
    </DataTable>
</template>
