import api from './api'

const apireports = {
    async getCampaignReport(
        axios,
        superCampaignId,
        campaignId,
        platformId,
        paging,
        isExport,
        participantPlatformUserId,
    ) {
        const body = {
            export: isExport === true,
            paging,
            options: {
                platform_id: platformId,
            },
        }

        if (participantPlatformUserId) {
            body.paging.filters = [{ id: 'platform-user-id', value: participantPlatformUserId }]
        }

        return axios
            .post(`/supercampaigns/${superCampaignId}/campaigns/${campaignId}/summary`, body)
            .then(({ data }) => api.createSuccessResponse(data.payload))
            .catch((error) => {
                api.handleError(error)
                return api.createErrorResponse(error)
            })
    },

    async getListReport(axios, listId, platformId, paging, options, filters, isExport) {
        if (!paging) {
            paging = {}
        }
        paging.filters = filters

        const body = {
            platformId,
            paging,
            options,
            filters,
            isExport,
        }

        return axios
            .post(`/lists/${listId}/report`, body)
            .then(({ data }) => api.createSuccessResponse(data.payload))
            .catch((error) => {
                api.handleError(error)
                return api.createErrorResponse(error)
            })
    },
}

export default apireports
