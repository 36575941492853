<template>
    <ModalBasic title="Create contract" :modal-open="modals.create.state.isOpen" @close-modal="closeCreateContract">
        <div class="px-5 py-6">
            <FormKit id="create-contract" type="form" :actions="false" @submit="onCreateContract">
                <!--<FormKit
                type="text"
                v-model="modals.create.values.creator.first_name"
                name="firstname"
                label="First name"
                placeholder="Creators first name"
                validation="required"
            />

            <FormKit
                type="text"
                v-model="modals.create.values.creator.last_name"
                name="lastname"
                label="Last name"
                placeholder="Creators last name"
                validation="required"
            />
            -->

                <FormKit
                    v-model="modals.create.values.channel_name"
                    type="text"
                    name="channel_anem"
                    label="Channel name"
                    placeholder="Creator's channel name"
                    validation="required"
                />

                <FormKit
                    v-model="modals.create.values.creator.email"
                    type="email"
                    name="email"
                    label="Email"
                    placeholder="Creator's email address"
                    validation="required"
                />

                <FormKit
                    v-model="modals.create.values.title"
                    type="text"
                    name="title"
                    label="Contract title"
                    placeholder="The title of the contract"
                    validation="required"
                />

                <FormKit
                    v-model="modals.create.values.game_name"
                    type="text"
                    name="gamename"
                    label="Game name"
                    placeholder="Name of the game"
                    validation="required"
                />

                <FormKit
                    v-model="modals.create.values.contracted_date"
                    type="text"
                    name="contracteddate"
                    label="Contracted date"
                    placeholder="Date the creator is contracted on"
                    validation="required"
                />

                <FormKit
                    v-model="modals.create.values.price"
                    type="text"
                    name="price"
                    label="Price"
                    placeholder="Price"
                    validation="required"
                    disabled="true"
                />

                <FormKit
                    v-model="modals.create.values.publisher"
                    type="text"
                    name="publisher"
                    label="Publisher"
                    placeholder="Publisher"
                    validation="required"
                />

                <div class="mb-3 text-sm font-bold">Deliverables</div>

                <template v-for="(deliverable, i) in modals.create.values.deliverables" :key="deliverable.id">
                    <div class="bg-slate-100">
                        <div class="m-3 flex pt-3">
                            Deliverable {{ i + 1 }}
                            <div v-if="modals.create.allowRemoveDeliverable" class="ml-auto">
                                <a
                                    class="cursor-pointer pt-3 text-xs text-indigo-500"
                                    @click="onRemoveDeliverable(deliverable)"
                                    >Remove</a
                                >
                            </div>
                        </div>

                        <FormKit v-model="deliverable.value" type="textarea" validation="required" />
                    </div>
                </template>

                <div v-if="modals.create.allowAddDeliverable">
                    <a class="cursor-pointer pt-3 text-xs text-indigo-500" @click="onAddDeliverable()"
                        >Add deliverable</a
                    >
                </div>

                <div class="mt-4 flex justify-end">
                    <button class="btn bg-indigo-500 text-white hover:bg-indigo-600">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="h-4 w-4 shrink-0 fill-current opacity-50"
                            viewBox="0 0 512 512"
                        >
                            <path
                                fill="currentColor"
                                d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.8 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z"
                                class=""
                            ></path>
                        </svg>
                        <span v-if="!creating" class="ml-2 hidden xs:block">Create</span>
                        <span v-if="creating" class="ml-2 hidden xs:block">Creating...</span>
                    </button>
                </div>
            </FormKit>
        </div>
    </ModalBasic>

    <ModalBasic title="Send contract" :modal-open="modals.send.state.isOpen" @close-modal="onCloseSendContract">
        <div class="px-5 py-6">Are you sure you want to send the contract? This will email the creator.</div>
        <div class="m-4 flex">
            <button
                class="btn-sm ml-auto border-slate-200 text-slate-600 hover:border-slate-300"
                @click="onCloseSendContract()"
            >
                Cancel
            </button>
            <button class="btn-sm ml-2 bg-indigo-500 text-white hover:bg-indigo-600" @click="onSendContract()">
                <span v-if="!sending">Send</span>
                <span v-if="sending">Sending...</span>
            </button>
        </div>
    </ModalBasic>

    <ModalBasic title="Delete contract" :modal-open="modals.delete.state.isOpen" @close-modal="onCloseDeleteContract">
        <div class="px-5 py-6">Are you sure you want to delete the contract? This cannot be undone.</div>
        <div class="m-4 flex">
            <button
                class="btn-sm ml-auto border-slate-200 text-slate-600 hover:border-slate-300"
                @click="onCloseDeleteContract()"
            >
                Cancel
            </button>
            <button class="btn-sm ml-2 bg-red-500 text-white hover:bg-red-700" @click="onDeleteContract()">
                <span v-if="!deleting">Delete</span>
                <span v-if="deleting">Deleting...</span>
            </button>
        </div>
    </ModalBasic>

    <div class="col-span-12 mt-3 md:col-span-8">
        <template v-if="canCreateContract">
            <div class="flex justify-between rounded bg-slate-100 px-3 py-2 text-sm">
                <div>
                    <span class="font-semibold text-slate-700">Create contract</span>
                </div>
            </div>
            <div class="mt-4 px-3">
                <div>
                    A contract has not been created for the creator yet. Creating a contract will create a contract
                    document which can be reviewed before sending to the creator.
                </div>
                <div class="mt-4 flex justify-end">
                    <button
                        v-if="!loading"
                        class="btn bg-indigo-500 text-white hover:bg-indigo-600"
                        @click="openCreateContract()"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="h-4 w-4 shrink-0 fill-current opacity-50"
                            viewBox="0 0 512 512"
                        >
                            <path
                                fill="currentColor"
                                d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.8 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z"
                                class=""
                            ></path>
                        </svg>
                        <span class="ml-2 hidden xs:block">Create contract</span>
                    </button>
                </div>
            </div>
        </template>
        <template v-if="canViewContract">
            <div class="flex justify-between rounded bg-slate-100 px-3 py-2 text-sm">
                <div class="flex">
                    <span class="font-semibold text-slate-700">Contract</span>
                </div>

                <div class="flex">
                    <span class="ml-auto font-semibold text-slate-700">{{ getContractStatus }}</span>
                </div>
            </div>
            <div class="mb-4 mt-4 px-3">
                <div class="mt-4 flex justify-end">
                    <a
                        v-if="!loading && canEditContract"
                        :href="`${participant.stages.contracting.contract_edit_url}`"
                        target="_blank"
                    >
                        <button class="btn mr-5 bg-indigo-500 text-white hover:bg-indigo-600">
                            <span class="ml-2 hidden xs:block">Edit contract</span>
                        </button>
                    </a>

                    <button
                        v-if="!loading"
                        class="btn bg-indigo-500 text-white hover:bg-indigo-600"
                        @click="onDownloadContract()"
                    >
                        <span v-if="!downloading" class="ml-2 hidden xs:block">Download contract</span>
                        <span v-if="downloading" class="ml-2 hidden xs:block">Downloading...</span>
                    </button>
                </div>
            </div>
        </template>
        <template v-if="canSendContract">
            <div class="flex justify-between rounded bg-slate-100 px-3 py-2 text-sm">
                <div>
                    <span class="font-semibold text-slate-700">Send contract</span>
                </div>
            </div>
            <div class="mb-4 mt-4 px-3">
                <div class="mt-4 flex justify-end">
                    <button
                        v-if="!loading"
                        class="btn bg-indigo-500 text-white hover:bg-indigo-600"
                        @click="onOpenSendContract()"
                    >
                        <span class="ml-2 hidden xs:block">Send contract</span>
                    </button>
                </div>
            </div>
        </template>
        <template v-if="!canCreateContract && !canViewContract && !canSendContract">
            <div class="flex justify-between rounded bg-slate-100 px-3 py-2 text-sm">
                <div>
                    <span class="font-semibold text-slate-700">Contract unavailable</span>
                </div>
            </div>
            <div class="mt-4 px-3">Contracting is unavailable for this creator.</div>
        </template>
        <template v-if="canDeleteContract">
            <div class="flex justify-between rounded bg-slate-100 px-3 py-2 text-sm">
                <div>
                    <span class="font-semibold text-slate-700">Delete contract</span>
                </div>
            </div>
            <div class="mb-4 mt-4 px-3">
                <div class="mt-4 flex justify-end">
                    <button
                        v-if="!loading"
                        class="btn bg-red-500 text-white hover:bg-red-700"
                        @click="onOpenDeleteContract()"
                    >
                        <span class="ml-2 hidden xs:block">Delete contract</span>
                    </button>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import _ from 'lodash'
import moment from 'moment'
import { mapGetters } from 'vuex'
import ModalBasic from '@/components/ModalBasic.vue'
import campaignTaskAPI from '@/utils/api/api.campaign.tasks'
import campaignContractAPI from '@/utils/api/api.campaign.contracts'

export default {
    name: 'Contracting',

    components: {
        ModalBasic,
    },

    props: {
        supercampaign: Object,
        campaign: Object,
        participantId: String,
        participant: Object,
        superview: Object,
    },

    emits: ['contract-change'],

    data() {
        return {
            modals: {
                create: {
                    state: {
                        isOpen: false,
                    },
                    values: {
                        title: '',
                        game_name: '',
                        creator: {
                            first_name: '',
                            last_name: '',
                            email: '',
                        },
                        channel_name: '',
                        deliverables: {},
                        contracted_date: '',
                        price: '',
                        publisher: '',
                    },
                    allowAddDeliverable: true,
                    allowRemoveDeliverable: true,
                },
                send: {
                    state: {
                        isOpen: false,
                    },
                },
                delete: {
                    state: {
                        isOpen: false,
                    },
                },
            },
            tasks: [],
            loading: true,
            downloading: false,
            sending: false,
            deleting: false,
            creating: false,
        }
    },

    watch: {
        participantId() {
            this.reset()
        },
        campaign() {
            this.reset()
        },
    },

    async created() {
        this.reset()
    },

    computed: {
        ...mapGetters(['getFromDictionary']),

        canCreateContract() {
            return this.participant.stages.contracting.contract_can_create
        },

        canViewContract() {
            return this.participant.stages.contracting.contract_can_view
        },

        canDeleteContract() {
            return this.participant.stages.contracting.contract_can_delete
        },

        canSendContract() {
            return this.participant.stages.contracting.contract_can_send
        },

        canEditContract() {
            return this.participant.stages.contracting.contract_can_edit
        },

        getContractStatus() {
            return this.getFromDictionary(
                'campaign.stages.contracting.status',
                this.participant.stages.contracting.status,
            ).label
        },
    },

    methods: {
        async reset() {
            this.loading = true
            this.downloading = false
            this.sending = false
            this.deleting = false
            this.creating = false
            await this.resetCreate()
        },

        async resetCreate() {
            this.tasks = await this.getParticipantTasks()

            this.modals.create.values.title = this.participant.platform_user_name
            this.modals.create.values.game_name = ''
            this.modals.create.values.channel_name = this.participant.platform_user_name
            this.modals.create.values.contracted_date = ''
            this.modals.create.values.price = ''
            this.modals.create.values.publisher = this.supercampaign?.organization?.name
            this.modals.create.values.deliverables = []
            this.modals.create.values.creator.first_name = ''
            this.modals.create.values.creator.last_name = ''
            this.modals.create.values.creator.email = ''

            const dueDate = _.min(_.map(this.tasks, (t) => t.due_at))
            this.modals.create.values.contracted_date = this.$filters.formatDate(dueDate)
            this.modals.create.values.game_name = this.supercampaign?.game?.name
            this.modals.create.values.creator.email = this.superview?.custom?.metadata?.email
            this.modals.create.values.price = _.sum(_.map(this.tasks, (t) => t.payout_amount))

            if (this.modals.create.values.game_name !== '') {
                this.modals.create.values.title = `${this.modals.create.values.title} - ${
                    this.modals.create.values.game_name || ''
                }`
            }
        },

        openCreateContract() {
            this.createDeliverables()
            this.modals.create.state.isOpen = true
        },

        closeCreateContract() {
            this.generating = false
            this.modals.create.state.isOpen = false
        },

        createDeliverables() {
            const deliverableList = _.map(this.tasks, (task) => {
                const props = {
                    game_name: this.modals.create.values.game_name,
                    twitch_category: this.modals.create.values.game_name,
                    stream_date: this.$filters.formatDate(task.due_at),
                    stream_time: moment(task.due_at).format('h:mm a'),
                }

                if (task.payload && task.payload.duration_seconds) {
                    props.stream_length = this.$filters.humanizeDuration(task.payload.duration_seconds * 1000)
                }

                return this.createDeliverablesFromTask(task, props)
            }).flat()

            this.modals.create.values.deliverables = _.map(deliverableList, (del, index) => ({ id: index, value: del }))
        },

        createDeliverablesFromTask(task, props) {
            const deliverables = []
            switch (task.type) {
                case 'twitch-stream':
                    deliverables.push(
                        `Talent shall stream a live broadcast on Twitch for at least ${props.stream_length} featuring ${props.game_name} on ${props.stream_date}, after ${props.stream_time} EST on that day.`,
                    )
                    deliverables.push(
                        `Talent will add the provided visual panel and trackable link to his or her panel section. The trackable link will need to be linked to the visual panel to make the panel clickable and trackable. The clickable panel will stay up for the entirety of the sponsored stream.`,
                    )
                    deliverables.push(
                        `Talent will use the Twitch category ${props.twitch_category} for the entirety of the sponsored stream and use #ad or #sponsored in the stream title.`,
                    )
                    deliverables.push(
                        `Talent will ideally stream his or her regular game(s) or category for 1 hour before starting to stream ${props.game_name} to drive viewership up in preparation for the sponsored stream.`,
                    )
                    deliverables.push(
                        `Talent will complete the feedback survey sent to him by the Contractor after completing the above deliverables.`,
                    )
                    return deliverables
                case 'twitch-panel':
                    return deliverables
                case 'youtube-video':
                    deliverables.push(
                        `Talent shall produce a YouTube video of at least 10 minutes featuring ${props.game_name} on ${props.stream_date}, published after ${props.stream_time} EST on that day.`,
                    )
                    deliverables.push(`Talent will add the provided trackable link to their description section.`)
                    deliverables.push(
                        `Talent will complete the feedback survey sent to him by the Contractor after completing the above deliverables.`,
                    )
                    return deliverables
                default:
                    return deliverables
            }
        },

        onOpenSendContract() {
            this.modals.send.state.isOpen = true
        },

        onCloseSendContract() {
            this.modals.send.state.isOpen = false
        },

        onOpenDeleteContract() {
            this.modals.delete.state.isOpen = true
        },

        onCloseDeleteContract() {
            this.modals.delete.state.isOpen = false
        },

        onAddDeliverable() {
            let nextId = _.max(_.map(this.modals.create.values.deliverables, (del) => del.id)) + 1
            if (!nextId) {
                nextId = 1
            }
            this.modals.create.values.deliverables.push({ id: nextId, value: '' })
        },

        onRemoveDeliverable(deliverable) {
            this.modals.create.values.deliverables = _.filter(
                this.modals.create.values.deliverables,
                (item) => deliverable.id !== item.id,
            )
        },

        async getParticipantTasks() {
            const response = await campaignTaskAPI.getCampaignParticipantTasks(
                this.$axios,
                this.campaign.parent_id,
                this.campaign.id,
                this.participantId,
            )
            this.loading = false

            if (response.success) {
                return response.value
            }

            return []
        },

        onDeleteContract: _.debounce(
            function () {
                this.deleting = true
                this.deleteContract()
            },
            5000,
            { leading: true, trailing: false },
        ),

        async deleteContract() {
            await campaignContractAPI.deleteContract(this.$axios, this.participantId)
            this.onCloseDeleteContract()
            this.deleting = false
            this.$emit('contract-change')
        },

        onDownloadContract: _.debounce(
            function () {
                this.downloading = true
                this.downloadContract()
            },
            10000,
            { leading: true, trailing: false },
        ),

        async downloadContract() {
            const response = await campaignContractAPI.downloadContract(this.$axios, this.participantId)

            if (response.success) {
                const url = window.URL.createObjectURL(new Blob([response.value.data]))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('download', 'contract.pdf')
                document.body.appendChild(link)
                link.click()
            }

            this.downloading = false
        },

        onSendContract: _.debounce(
            function () {
                this.sending = true
                this.sendContract()
            },
            5000,
            { leading: true, trailing: false },
        ),

        async sendContract() {
            await campaignContractAPI.sendContract(this.$axios, this.participantId)
            this.onCloseSendContract()
            this.sending = false
            this.$emit('contract-change')
        },

        onCreateContract: _.debounce(
            function () {
                this.creating = true
                this.createContract()
            },
            5000,
            { leading: true, trailing: false },
        ),

        async createContract() {
            const contractDetails = {
                provider: 'pandadoc',
                templateId: 'LGpAsWRk3aKwiP6o2nhq5B',
                document_name: this.modals.create.values.title,
                recipient: {
                    first_name: this.modals.create.values.creator.first_name,
                    last_name: this.modals.create.values.creator.last_name,
                    email: this.modals.create.values.creator.email,
                },
                countersigner: {
                    first_name: 'Daniel',
                    last_name: 'Trang',
                    email: 'daniel@streamforge.com',
                    title: 'Head of Finance and Operations',
                },
                channel_name: this.modals.create.values.channel_name,
                contracted_date: this.modals.create.values.contracted_date,
                creator_name: `${this.modals.create.values.creator.first_name} ${this.modals.create.values.creator.last_name}`,
                game_name: this.modals.create.values.game_name,
                price: this.modals.create.values.price,
                publisher: this.modals.create.values.publisher,
                deliverables: _.map(this.modals.create.values.deliverables, (deliverable) => deliverable.value),
            }

            await campaignContractAPI.createContract(this.$axios, this.participantId, contractDetails)
            this.modals.create.state.isOpen = false
            this.creating = false
            this.$emit('contract-change')
        },
    },
}
</script>
