<template>
    <Dialog v-if="modals.creator.is_open" :open="modals.creator.is_open" @update:open="onCreatorProfileModalClose">
        <DialogScrollContent :class="'max-w-5xl border-none p-0'">
            <CreatorProfile
                :platform-id="modals.creator.platform_id"
                :platform-user-id="modals.creator.platform_user_id"
                :supercampaign-id="modals.creator.supercampaign_id"
                :campaign-id="modals.creator.campaign_id"
                :participant-id="modals.creator.participant_id"
                :participant="modals.creator.participant"
                :campaign="modals.creator.campaign"
                :is-limited-mode="modals.creator.is_limited_mode"
                :is-in-workflow="modals.creator.is_in_workflow"
            />
        </DialogScrollContent>
    </Dialog>
</template>

<script>
import _ from 'lodash'
import { mapGetters } from 'vuex'
import { Dialog, DialogScrollContent } from '@/components/ui/dialog'
import CreatorProfile from '@/partials/profiles/CreatorProfile/CreatorProfile.vue'

export default {
    components: {
        // eslint-disable-next-line
        Dialog,
        DialogScrollContent,
        CreatorProfile,
    },

    data() {
        return {}
    },

    computed: {
        ...mapGetters(['modals']),
    },

    watch: {},

    methods: {
        onCreatorProfileModalClose() {
            this.$router.push({
                query: _(this.$route.query)
                    .omit(['modal', 'platform_id', 'platform_user_id'])
                    .omitBy((value, key) => key.startsWith('profile'))
                    .value(),
            })

            console.log(
                _(this.$route.query)
                    .omit(['modal', 'platform_id', 'platform_user_id'])
                    .omitBy((value, key) => key.startsWith('profile'))
                    .value(),
            )

            this.$store.commit('modal:close', 'creator')
        },
    },
}
</script>
