<template>
    <DialogModal :open="modalOpen" @update:open="onCloseModal">
        <DialogContent
            :class="'max-w-2xl border-none p-0 outline-none'"
            @interact-outside="(event) => event.preventDefault()"
        >
            <DialogHeader class="p-6 pb-0">
                <DialogTitle>Bulk Import Creators</DialogTitle>
                <DialogDescription class="text-sm">
                    <ul class="list-disc px-6">
                        <li>Please keep this modal open for the entirety of the import process.</li>
                        <li>Importing can take up to 5 minutes depending on how many creators you provide.</li>
                        <li>You can paste in either usernames, profile links, or IDs.</li>
                        <li>
                            If you select "any platform", and provide a username, all profiles across all platforms
                            exactly matching that name will be imported.
                        </li>
                        <li>Usernames are not case sensitive.</li>
                        <li>
                            For YouTube, trying to import by a channel's title will not work as they are not unique.
                        </li>
                    </ul>
                </DialogDescription>
            </DialogHeader>
            <div v-if="!inProgressCreators?.length" class="relative flex flex-col">
                <!-- Modal content -->
                <div class="px-5">
                    <div class="text-sm">
                        <div class="mb-3">
                            <div class="mt-3 flex items-center">
                                <DropdownImportPlatform
                                    :selected-platform-id="search.platformId"
                                    :platforms="search.platforms"
                                    @on-select="onPlatformSelect"
                                />
                                <Tooltip class="ml-2">
                                    <template #trigger>
                                        <font-awesome-icon
                                            class="h-3.5 w-3.5 hover:text-slate-500"
                                            :icon="['fas', 'circle-info']"
                                        />
                                    </template>
                                    <template #default>
                                        <div>
                                            <p class="text-md font-semibold text-slate-200">Usernames</p>
                                            <p class="text-sm text-slate-300">
                                                Accepts usernames handles (e.g. 'ninja'), any username that contains a
                                                space is incorrect.
                                            </p>
                                        </div>
                                        <div class="mt-2">
                                            <p class="text-md font-semibold text-slate-200">Platform links</p>
                                            <div class="text-sm text-slate-300">
                                                Accepts platform links like:
                                                <ul class="list-disc px-6 text-slate-200">
                                                    <li>https://www.twitch.tv/ninja</li>
                                                    <li>https://www.youtube.com/@ninja</li>
                                                    <li>https://www.youtube.com/channel/UCAW-NpUFkMyCNrvRSSGIvDQ</li>
                                                    <li>https://www.youtube.com/c/UCAW-NpUFkMyCNrvRSSGIvDQ</li>
                                                    <li>https://www.youtube.com/user/ninja</li>
                                                    <li>https://www.tiktok.com/@ninja</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </template>
                                </Tooltip>
                            </div>

                            <div class="my-4">
                                <textarea
                                    v-model="bulkImportCreators"
                                    class="z-50 h-64 w-full whitespace-nowrap rounded-lg rounded-sm border border-slate-300 bg-white p-2 text-sm"
                                    placeholder="Enter your creators..."
                                ></textarea>
                                <div class="mt-2 flex">
                                    <div class="ml-auto text-sm leading-none">{{ itemCount }} entries</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- results -->
            <div v-else class="relative flex flex-col border-t border-slate-200">
                <div class="flex flex-col">
                    <div v-if="state.isInProgress" class="py-1 text-center text-sm">
                        Please wait, imported {{ counts.completed }} of {{ counts.total }}
                    </div>
                    <div v-else class="py-1 text-center text-sm">Import complete!</div>
                    <div class="flex h-2 w-full bg-slate-100">
                        <div
                            class="h-full bg-indigo-300"
                            :style="{
                                width: `${(counts?.completed / counts?.total) * 100}%`,
                            }"
                        />
                    </div>
                </div>

                <!-- Modal content -->
                <div
                    class="overflow-auto overflow-y-auto border-b border-t border-slate-200 scrollbar scrollbar-track-slate-200 scrollbar-thumb-slate-300 scrollbar-corner-slate-100 scrollbar-w-3 scrollbar-h-2 hover:scrollbar-thumb-slate-400 active:scrollbar-thumb-slate-500 md:max-h-96"
                >
                    <ul class="divide-y divide-slate-200">
                        <template
                            v-for="({ input_creator, staged_insertions, state }, index) in inProgressCreators"
                            :key="`${input_creator.platform_user_id}-${index}`"
                        >
                            <li class="flex justify-between overflow-hidden bg-slate-50 px-5 py-2">
                                <div class="flex w-5/6 flex-col pr-2 text-sm">
                                    <div class="overflow-hidden whitespace-normal">{{ input_creator.input }}</div>
                                    <div class="text-xs font-semibold uppercase leading-none text-slate-600">
                                        {{ input_creator.type }}
                                    </div>
                                </div>

                                <div class="flex w-1/6 items-center justify-end">
                                    <div v-if="state === 'loading'" role="status" class="flex">
                                        <svg
                                            class="my-auto inline h-6 w-6 animate-spin fill-indigo-600 text-gray-200 dark:text-gray-600"
                                            aria-hidden="true"
                                            viewBox="0 0 100 101"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                                fill="currentColor"
                                            />
                                            <path
                                                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                                fill="currentFill"
                                            />
                                        </svg>
                                        <span class="sr-only my-auto">Loading...</span>
                                    </div>
                                    <div v-else-if="state === 'idle'" class="text-sm leading-none">Waiting...</div>
                                </div>
                            </li>

                            <li
                                v-for="stagedInsertion in staged_insertions"
                                :key="stagedInsertion.platform_user_id"
                                class="flex h-10 items-center px-5 py-2 pl-10"
                            >
                                <NamePlate
                                    :name="stagedInsertion.platform_user_name"
                                    :display-name="stagedInsertion.platform_user_name"
                                    :platform-id="stagedInsertion.platform_id"
                                    :platform-user-id="stagedInsertion.platform_user_id"
                                    :is-drawer-enabled="false"
                                />

                                <div class="my-auto ml-auto flex">
                                    <div class="text-sm capitalize leading-none text-emerald-600">
                                        {{ stagedInsertion.status === 'inserted' ? 'Added' : stagedInsertion.status }}
                                    </div>
                                </div>
                            </li>

                            <li
                                v-if="state === 'loaded' && staged_insertions?.length === 0"
                                class="flex h-10 items-center px-5 py-2 pl-10"
                            >
                                <div>???</div>

                                <div class="my-auto ml-auto flex">
                                    <div class="text-sm capitalize leading-none text-rose-600">Not Found</div>
                                </div>
                            </li>
                        </template>
                    </ul>
                </div>
            </div>

            <div class="px-6 pb-3">
                <div v-if="!inProgressCreators?.length" class="flex flex-wrap justify-end space-x-2">
                    <button class="btn-sm border-slate-200 text-slate-600 hover:border-slate-300" @click="onCloseModal">
                        Cancel
                    </button>

                    <Tooltip
                        :show-tooltip="
                            currentListSize !== -1 &&
                            getLimitation('lists.size') !== null &&
                            itemCount + currentListSize > getLimitation('lists.size')
                        "
                        max-width="500"
                    >
                        <template #trigger>
                            <button
                                class="btn-sm bg-indigo-500 text-white hover:bg-indigo-600 disabled:border-slate-200 disabled:bg-slate-100 disabled:text-slate-400"
                                :disabled="
                                    currentListSize !== -1 &&
                                    getLimitation('lists.size') !== null &&
                                    itemCount + currentListSize > getLimitation('lists.size')
                                "
                                @click="onSubmit"
                            >
                                Import
                            </button>
                        </template>
                        <template #default>
                            <div class="whitespace-nowrap text-xs">
                                {{
                                    getFromDictionary(`usage.lists.size`).replace(
                                        '[LIMIT]',
                                        getLimitation('lists.size'),
                                    )
                                }}
                            </div>
                        </template>
                    </Tooltip>
                </div>

                <div v-else class="flex flex-wrap justify-between space-x-2">
                    <div class="space-x-2">
                        <button
                            v-if="!state.isInProgress"
                            class="btn-sm border-slate-200 text-slate-600 hover:border-slate-300"
                            @click="inProgressCreators = []"
                        >
                            Retry
                        </button>
                        <button
                            v-if="!state.isInProgress"
                            class="btn-sm border-slate-200 text-slate-600 hover:border-slate-300"
                            @click="resetModal()"
                        >
                            Import more
                        </button>
                    </div>

                    <button
                        v-if="!state.isInProgress"
                        class="btn-sm bg-indigo-500 text-white hover:bg-indigo-600"
                        @click="onCloseModal"
                    >
                        Done
                    </button>
                    <button v-else class="btn-sm bg-rose-500 text-white hover:bg-rose-600" @click="cancelImport">
                        Cancel
                    </button>
                </div>
            </div>
        </DialogContent>
    </DialogModal>
</template>

<script>
import _ from 'lodash'
import Promise from 'bluebird'
import {
    Dialog as DialogModal,
    DialogContent,
    DialogTitle,
    DialogHeader,
    DialogDescription,
} from '@/components/ui/dialog'
import DropdownImportPlatform from '@/components/dropdowns/DropdownImportPlatform.vue'
import Tooltip from '@/components/Tooltip.vue'
import NamePlate from '@/components/plates/NamePlate.vue'
import { mapGetters } from 'vuex'

export default {
    components: {
        DropdownImportPlatform,
        Tooltip,
        NamePlate,
        DialogModal,
        DialogContent,
        DialogTitle,
        DialogHeader,
        DialogDescription,
    },

    props: {
        modalOpen: Boolean,
        currentListId: String,
        tableCreators: Array,
        submitExternally: Boolean,
        currentListSize: {
            type: Number,
            default: -1,
        },
    },

    emits: ['submit', 'close-modal'],

    data() {
        return {
            bulkImportCreators: '',

            search: {
                platformId: 'any',

                platforms: [
                    {
                        id: 'any',
                        name: 'Any Platform',
                    },
                    {
                        id: 'twitch',
                        name: 'Twitch',
                    },
                    {
                        id: 'youtube',
                        name: 'YouTube',
                    },
                    {
                        id: 'tiktok',
                        name: 'TikTok',
                    },
                ],
            },

            state: {
                isInProgress: false,
                importCount: 0,
            },

            inProgressCreators: [],
        }
    },

    computed: {
        ...mapGetters(['getFromDictionary', 'getLimitation']),
        itemCount() {
            return _.size(
                this.bulkImportCreators
                    .split('\n')
                    .map((item) => item.trim())
                    .filter((query) => query !== ''),
            )
        },

        counts() {
            return {
                total: this.state.importCount,
                completed: _.sumBy(this.inProgressCreators, (item) => (item.state === 'loaded' ? 1 : 0)),
            }
        },
    },

    methods: {
        parseSocialMediaLinks(platformId, inputList) {
            const usernamePatterns = {
                twitch: /twitch\.tv\/([^\/\?\s]+)/i,
                youtube: /youtube\.com\/(?:@([^\/\?\s]+)|c\/([^\/\?\s]+)|user\/([^\/\?\s]+)|([^\/\?\s]+))/i,
                tiktok: /tiktok\.com\/@([^\/\?\s]+)/i,
                twitter: /twitter\.com\/([^\/\?\s]+)/i,
                instagram: /instagram\.com\/([^\/\?\s]+)/i,
            }

            const userIdPatterns = {
                youtube: /youtube\.com\/channel\/(UC[\w-]+)/i,
            }

            const usernames = []
            const userIds = []
            const unknowns = []

            inputList.forEach((input) => {
                let matched = false

                // Check for usernames
                Object.entries(usernamePatterns).forEach(([platform, pattern]) => {
                    const match = input.toLowerCase().match(pattern)

                    if (match) {
                        // Select the first non-undefined group match as the username
                        const username = match
                            .slice(1)
                            .find((m) => m !== undefined)
                            ?.toLowerCase()

                        // This regex captures channel as a username
                        if (username && !(platform === 'youtube' && username === 'channel')) {
                            usernames.push({
                                platform_id: platform,
                                platform_user_name: username.replace('@', ''), // Ensure "@" prefix is removed
                                input,
                                type: 'user name',
                            })

                            matched = true
                        }
                    }
                })

                // Check for user IDs
                Object.entries(userIdPatterns).forEach(([platform, pattern]) => {
                    const match = input.match(pattern)

                    if (match) {
                        userIds.push({
                            platform_id: platform,
                            platform_user_id: match[1],
                            input,
                            type: 'user id',
                        })

                        matched = true
                    }
                })

                // If we don't match a link, assume it's just a username
                if (!matched) {
                    unknowns.push({
                        platform_id: platformId,
                        platform_user_name: input,
                        input,
                        type: 'user name',
                    })
                }
            })

            return { usernames, userIds, unknowns }
        },

        async onSubmit() {
            const socialUrls = this.bulkImportCreators
                .split('\n')
                .map((item) => item.trim())
                .filter((query) => query !== '')

            const items = this.parseSocialMediaLinks(this.search.platformId, socialUrls)

            const creators = _.flatten(_.values(items))

            if (_.size(creators) === 0) {
                return
            }

            if (this.submitExternally) {
                this.$emit('submit', creators)
                this.onCloseModal()
                return
            }

            this.$notify({
                title: 'Importing Creators...',
                text: `Please allow up to ${this.importTimeEstimated() + 10} seconds for the data to load.`,
                type: 'info',
                duration: this.importTimeEstimated() + 10 * 1000,
            })

            this.inProgressCreators = _(creators)
                .map((creator, index) => {
                    return {
                        state: 'idle',
                        input_creator: creator,
                        order: index,
                    }
                })
                .orderBy(['order'], ['asc'])
                .value()

            const inProgressCreatorsChunks = _.chunk(this.inProgressCreators, 10)

            this.state.importCount = _.size(this.inProgressCreators)
            this.state.isInProgress = true

            const newCreators = []

            await Promise.each(inProgressCreatorsChunks, (inProgressCreatorsChunk) => {
                _.forEach(inProgressCreatorsChunk, (creator) => {
                    creator.state = 'loading'
                })

                return this.$axios
                    .post(`/lists/${this.currentListId}/creators`, {
                        creators: _.map(inProgressCreatorsChunk, 'input_creator'),
                    })
                    .then(({ data }) => {
                        _.forEach(data.payload.results, (result) => {
                            const matchingIndex = _.findIndex(this.inProgressCreators, (inProgressCreator) => {
                                const isIdSame =
                                    result.input_creator.platform_user_id &&
                                    inProgressCreator.input_creator.platform_user_id ===
                                        result.input_creator.platform_user_id
                                const isNameSame =
                                    result.input_creator.platform_user_name &&
                                    inProgressCreator.input_creator.platform_user_name ===
                                        result.input_creator.platform_user_name
                                const isPlatformSame =
                                    inProgressCreator.input_creator.platform_id === result.input_creator.platform_id

                                console.log(
                                    result.input_creator,
                                    { isPlatformSame, isIdSame, isNameSame },
                                    isPlatformSame && (isIdSame || isNameSame),
                                )

                                return isPlatformSame && (isIdSame || isNameSame)
                            })

                            // Attach the results to the original object
                            if (matchingIndex !== -1) {
                                this.inProgressCreators[matchingIndex] = {
                                    ...this.inProgressCreators[matchingIndex],
                                    ...result,
                                    state: 'loaded',
                                }
                                console.log(`Match`, this.inProgressCreators[matchingIndex], matchingIndex)
                            } else {
                                console.log(`No match`, { result })
                            }
                        })

                        _.forEach(data?.payload?.results, (result) => {
                            _.forEach(result.new_creators, (newCreator) => {
                                newCreators.push(newCreator)
                            })
                        })
                    })
                    .catch((error) => {
                        this.$errorHandler(error, {
                            title: `Adding Creators`,
                            message:
                                error.response?.status === 400
                                    ? error.response.data.message
                                    : 'Error adding creator to list.',
                        })
                    })
            })

            this.state.isInProgress = false

            console.log('newCreators', newCreators)

            this.$emit('submit', newCreators)
        },

        cancelImport() {
            this.$store.dispatch('CANCEL_PENDING_REQUESTS_BY_ENDPOINT', `/lists/${this.currentListId}/creators`)
            this.inProgressCreators = []
            this.state.isInProgress = false
            this.state.importCount = 0
        },

        onPlatformSelect(platformId) {
            this.search.platformId = platformId
        },
        onCloseModal() {
            this.$emit('close-modal')

            setTimeout(() => {
                this.resetModal()
            }, 100)
        },
        resetModal() {
            this.bulkImportCreators = ''
            this.search.platformId = 'any'
            this.inProgressCreators = []
            this.state.isInProgress = false
            this.state.importCount = 0
        },
        importTimeEstimated() {
            let timeEstimatedInSeconds = this.bulkImportCreators.split('\n').reduce((acc) => acc + 1, 1)
            if (this.platformId === 'any') {
                timeEstimatedInSeconds *= 3
            }
            return timeEstimatedInSeconds
        },
    },
}
</script>
