<script setup>
import { ref } from 'vue'
import { useMutation, useQueryClient } from '@tanstack/vue-query'
import { notify } from '@kyvg/vue3-notification'
import { axios } from '@/utils/axios'
import AddCity from '@/partials/profiles/CreatorProfile/AddCity.vue'

const props = defineProps({
    creator: {
        type: Object,
        required: true,
    },
})

const addingCustomValue = ref()

const queryClient = useQueryClient()

const setAsPrimary = useMutation({
    mutationFn: (value) =>
        axios.post(`/creators/${props.creator._id}/set-primary-meta`, {
            key: 'city',
            value,
            creator_id: props.creator._id,
        }),
    onSuccess: async () => {
        await queryClient.invalidateQueries({ queryKey: ['creators', props.creator._id] })
    },
})
const deleteCity = useMutation({
    mutationFn: (value) =>
        axios.post(`/creators/${props.creator._id}/delete-meta`, {
            key: 'city',
            value,
            creator_id: props.creator._id,
        }),
    onSuccess: async () => {
        await queryClient.invalidateQueries({ queryKey: ['creators', props.creator._id] })
    },
    onError: (error) => {
        notify({
            title: "Can't delete this city",
            text: error.response.data.error,
            type: 'warn',
        })
    },
})
</script>
<template>
    <div>
        <div class="pb-1 text-sm font-bold">City</div>
        <div class="space-y-1">
            <div
                v-for="city in props.creator.metadata.cities"
                :key="city"
                class="group flex h-12 items-center justify-between rounded-md border bg-slate-50 px-2"
            >
                <div>{{ city }}</div>
                <div class="flex items-center">
                    <button
                        class="invisible mr-1 rounded-sm p-2 group-hover:visible hover:bg-slate-100"
                        @click="deleteCity.mutate(city)"
                    >
                        <font-awesome-icon :icon="['fas', 'trash']" class="h-3 w-3 text-red-600" />
                    </button>
                    <button
                        v-if="city !== props.creator.metadata.primaryCity"
                        class="invisible rounded bg-white px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 group-hover:visible hover:bg-gray-50"
                        @click="setAsPrimary.mutate(city)"
                    >
                        Set as Primary{{ setAsPrimary.isLoading.value ? '' : '' }}
                    </button>
                    <div
                        v-if="city === props.creator.metadata.primaryCity && props.creator.metadata.cities.length > 1"
                        class="text-xs text-indigo-600"
                    >
                        Primary
                    </div>
                </div>
            </div>
        </div>
        <AddCity
            v-if="addingCustomValue"
            :creator="props.creator"
            :on-cancel="
                () => {
                    addingCustomValue = false
                }
            "
            :on-save="
                async () => {
                    addingCustomValue = false
                    // Invalidate the query
                    await queryClient.invalidateQueries({ queryKey: ['creators', props.creator._id] })
                }
            "
        />
        <template v-if="!addingCustomValue">
            <div v-if="props.creator.metadata.cities?.length > 0">
                <div
                    class="p-1 pl-2 text-sm text-indigo-600 hover:cursor-pointer hover:text-indigo-800"
                    @click="addingCustomValue = true"
                >
                    <div class="flex items-center">
                        <font-awesome-icon :icon="['fas', 'plus']" class="mr-1 h-3 w-3" />
                        <div class="pt-[1px]">Add city</div>
                    </div>
                </div>
            </div>
            <div v-else class="flex h-10 items-center justify-between rounded-md border border-dashed px-3 text-sm">
                <div class="flex items-center text-sm">
                    <div
                        class="flex items-center text-indigo-600 hover:cursor-pointer hover:text-indigo-800"
                        @click="addingCustomValue = true"
                    >
                        <font-awesome-icon :icon="['fas', 'plus']" class="mr-1 h-3 w-3" />
                        <div class="pt-[1px]">Add City</div>
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>
