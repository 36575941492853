<template>
    <ModalBasic
        :title="getFromDictionary(`list.modal.type.${modal.type}`, 'title')"
        :modal-open="modal.isOpen"
        @close-modal="modal.isOpen = false"
    >
        <div v-if="modal.type === 'access'">
            <div class="px-5 py-4">
                <ListAccessControls
                    :current-list="currentList"
                    :operators="potentialShares.operators"
                    :organizations="potentialShares.organizations"
                    @on-list-access="onShareList"
                />
            </div>
        </div>
    </ModalBasic>

    <CreatorSearchModal
        :modal-open="state.modals.addCreator.isOpen"
        :default-platform-id="filter.platformId"
        action-text="Add to List"
        :current-list-size="currentList?.totals?.total"
        @close-modal="state.modals.addCreator.isOpen = false"
        @submit="onCreatorAddSubmit"
    />

    <BulkImportCreatorsModal
        :modal-open="state.modals.bulkImportCreators.isOpen"
        :current-list-id="currentList.id"
        :table-creators="creators"
        :current-list-size="currentList?.totals?.total"
        @close-modal="state.modals.bulkImportCreators.isOpen = false"
        @submit="onBulkImportSubmit"
    >
    </BulkImportCreatorsModal>

    <div class="col-span-full">
        <header class="relative rounded-sm border border-b border-slate-100 border-slate-200 bg-white p-1 px-3 py-2">
            <div class="flex items-center justify-between">
                <div class="inline-flex rounded bg-slate-200 p-1" role="group">
                    <button
                        class="flex rounded px-5 py-2.5 text-sm font-bold leading-tight text-slate-900 transition duration-150 ease-in-out focus:outline-none focus:ring-0"
                        :class="{
                            'bg-white': filter.platformId === 'twitch',
                        }"
                        @click.prevent="onPlatformClick('twitch')"
                    >
                        <font-awesome-icon class="mr-2 h-4 w-4 text-twitch" :icon="['fab', 'twitch']" />
                        <span>Twitch</span>
                    </button>

                    <button
                        class="flex rounded px-5 py-2.5 text-sm font-bold leading-tight text-slate-900 transition duration-150 ease-in-out focus:outline-none focus:ring-0"
                        :class="{
                            'bg-white': filter.platformId === 'youtube',
                        }"
                        @click.prevent="onPlatformClick('youtube')"
                    >
                        <font-awesome-icon class="mr-2 h-4 w-4 text-youtube" :icon="['fab', 'youtube']" />
                        <span>YouTube</span>
                    </button>

                    <button
                        class="flex rounded px-5 py-2.5 text-sm font-bold leading-tight text-slate-900 transition duration-150 ease-in-out focus:outline-none focus:ring-0"
                        :class="{
                            'bg-white': filter.platformId === 'tiktok',
                        }"
                        @click.prevent="onPlatformClick('tiktok')"
                    >
                        <font-awesome-icon class="mr-2 h-4 w-4 text-tiktok" :icon="['fab', 'tiktok']" />
                        <span>TikTok</span>
                    </button>
                </div>

                <div v-if="canGenerateReports" class="inline-flex rounded bg-slate-200 p-1">
                    <button
                        class="flex rounded px-5 py-2.5 text-sm font-bold leading-tight text-slate-900 transition duration-150 ease-in-out focus:outline-none focus:ring-0"
                        :class="{
                            'bg-white': state.tab === 'creators',
                        }"
                        @click.prevent="onTabClick('creators')"
                    >
                        <span>Creators</span>
                    </button>

                    <button
                        class="flex rounded px-5 py-2.5 text-sm font-bold leading-tight text-slate-900 transition duration-150 ease-in-out focus:outline-none focus:ring-0"
                        :class="{
                            'bg-white': state.tab === 'report',
                        }"
                        @click.prevent="onTabClick('report')"
                    >
                        <span>Report</span>
                    </button>
                </div>

                <div class="hidden items-center sm:flex">
                    <div class="relative grid grid-flow-col justify-start gap-2 sm:auto-cols-max sm:justify-end">
                        <form class="relative my-auto" @submit.prevent>
                            <label for="action-search" class="sr-only">Search</label>
                            <input
                                id="action-search"
                                v-model="filter.searchText"
                                class="form-input pl-9 focus:border-slate-300"
                                type="search"
                                placeholder="Search..."
                            />
                            <div class="group absolute inset-0 right-auto" aria-label="Search">
                                <svg
                                    class="ml-3 mr-2 mt-2.5 h-4 w-4 shrink-0 fill-current text-slate-400 group-hover:text-slate-500"
                                    viewBox="0 0 16 16"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M7 14c-3.86 0-7-3.14-7-7s3.14-7 7-7 7 3.14 7 7-3.14 7-7 7zM7 2C4.243 2 2 4.243 2 7s2.243 5 5 5 5-2.243 5-5-2.243-5-5-5z"
                                    />
                                    <path
                                        d="M15.707 14.293L13.314 11.9a8.019 8.019 0 01-1.414 1.414l2.393 2.393a.997.997 0 001.414 0 .999.999 0 000-1.414z"
                                    />
                                </svg>
                            </div>
                        </form>

                        <ListsCreatorActionButtons
                            @export-creators="onExportCreators"
                            @import-creators="state.modals.bulkImportCreators.isOpen = true"
                            @share="onModalOpen('access')"
                            @add-creators="state.modals.addCreator.isOpen = true"
                        ></ListsCreatorActionButtons>
                    </div>
                </div>
            </div>

            <!-- Add to campaign options -->
            <div v-show="selectedCreators.length" class="absolute left-0 top-0 flex h-full w-full bg-white p-3">
                <div class="my-auto flex gap-2 px-5">
                    <div class="mr-2 flex cursor-pointer text-slate-500 hover:text-slate-900" @click="onModalClose">
                        <font-awesome-icon :icon="['fas', 'xmark']" class="my-auto h-4 w-4 shrink-0 fill-current" />
                    </div>

                    <button
                        class="btn btn-sm bg-indigo-500 text-white hover:bg-indigo-600"
                        @click="
                            $store.commit('modal:setData', {
                                modal_id: 'add_creator_to_modal',
                                data: {
                                    is_open: true,
                                    platform_id: filter.platformId,
                                    superviews: $lodash.map(selectedCreators, 'superview'),
                                },
                            })
                        "
                    >
                        Add selected to... ({{ selectedCreators.length }})
                    </button>

                    <button
                        class="btn btn-sm bg-indigo-500 text-white hover:bg-indigo-600"
                        @click="
                            $store.commit('modal:setData', {
                                modal_id: 'add_creator_to_modal',
                                data: {
                                    is_open: true,
                                    platform_id: filter.platformId,
                                    superviews: $lodash.map(creators, 'superview'),
                                },
                            })
                        "
                    >
                        Add all to... ({{ creators.length }})
                    </button>

                    <button class="btn btn-sm bg-red-500 text-white hover:bg-red-600" @click="onModalOpen('remove')">
                        Remove Selected from list ({{ selectedCreators.length }})
                    </button>
                </div>
            </div>
        </header>

        <ListReport
            ref="listReport"
            :list="currentList"
            :platform-id="filter.platformId"
            :search-text="filter.searchText"
            :fixed-day-range="state.tab === 'creators' ? true : false"
            @remove-from-list="
                (creatorsToRemove) => {
                    selectedCreators = creatorsToRemove
                    onRemoveFromList()
                }
            "
        >
        </ListReport>
    </div>
</template>

<script>
import _ from 'lodash'
import { mapGetters } from 'vuex'
import { nextTick } from 'vue'
import ModalBasic from '@/components/ModalBasic.vue'
import CreatorSearchModal from '@/partials/modals/CreatorSearchModal.vue'
import ListAccessControls from '@/components/ListAccessControls.vue'
import BulkImportCreatorsModal from '../../modals/BulkImportCreatorsModal.vue'
import ListsCreatorActionButtons from './ListsCreatorActionButtons.vue'
import ListReport from './ListReport.vue'

export default {
    name: 'ListsCreatorTable',

    components: {
        ModalBasic,
        CreatorSearchModal,
        BulkImportCreatorsModal,
        ListAccessControls,
        ListsCreatorActionButtons,
        ListReport,
    },

    props: {
        creators: {
            type: Object,
        },
        supercampaigns: {
            type: Object,
        },
        currentList: {
            type: Object,
        },
        potentialShares: {
            operators: Object,
            organizations: Object,
        },
        defaultPlatformId: {
            type: String,
        },
    },

    emits: ['add-creators', 'bulk-import-creators', 'edit-creators', 'on-list-share', 'fetch-shared-to'],

    data() {
        return {
            selectedCreators: [],

            filter: {
                searchText: '',
                platformId: 'twitch',
            },

            modal: {
                isOpen: false,
                type: 'remove',
            },

            state: {
                tab: 'creators',

                modals: {
                    addCreator: {
                        isOpen: false,
                    },
                    bulkImportCreators: {
                        isOpen: false,
                    },
                },
            },
        }
    },

    computed: {
        ...mapGetters(['getFromDictionary', 'isSfStaff']),

        canGenerateReports() {
            return true
            // return this.filter.platformId === 'twitch' || this.filter.platformId === 'youtube'
        },
    },

    watch: {
        defaultPlatformId(newValue, oldValue) {
            if (newValue !== oldValue) {
                nextTick(() => {
                    this.filter.platformId = newValue
                })
            }
        },
        currentList() {
            this.selectedCreators = []
            this.state.tab = 'creators'
        },
    },

    created() {
        if (this.defaultPlatformId) {
            this.filter.platformId = this.defaultPlatformId
        }
    },

    methods: {
        onPlatformClick(platform) {
            this.filter.platformId = platform
            this.selectedCreators = []
            if (this.state.tab === 'report' && !this.canGenerateReports) {
                this.state.tab = 'creators'
            }
        },

        onTabClick(tab) {
            this.state.tab = tab
        },

        onModalOpen(type) {
            this.modal.type = type
            this.modal.isOpen = true

            if (type === 'access') {
                this.$emit('fetch-shared-to')
            }
        },

        onModalClose() {
            this.modal.isOpen = false
            this.selectedCreators = []
        },

        onBulkImportSubmit(data) {
            this.$emit('bulk-import-creators', data)
        },

        onCreatorAddSubmit(creators) {
            this.state.modals.addCreator.isOpen = false

            this.$emit('add-creators', {
                creators: _.map(creators, (creator) => {
                    const platformPropertyMap = {
                        twitch: 'name',
                        youtube: 'snippet.title',
                        tiktok: 'uniqueId',
                    }

                    const platformProperty = platformPropertyMap[creator.superview.platform_id] || 'name'
                    const platformUserName = _.get(creator, `superview.channel.${platformProperty}`)

                    return {
                        platform_user_id: creator.superview._id,
                        platform_id: creator.superview.platform_id,
                        platform_user_name: platformUserName,
                    }
                }),
            })

            // Set the platform tab to the first creator that was added
            const firstCreator = _.first(creators)
            this.filter.platformId = firstCreator.superview.platform_id
        },

        onRemoveFromList() {
            this.$emit('edit-creators', {
                type: 'remove-selected',
                creators: _.map(this.selectedCreators, (creator) => {
                    return {
                        platform_user_id: creator.creator.platform_user_id,
                    }
                }),
            })
        },

        onAddSelectedToCampaign({ campaign }) {
            this.$emit('edit-creators', {
                type: 'add-selected',
                creators: _.map(this.selectedCreators, 'list_creator'),
                campaign_id: campaign.id,
                supercampaign_id: campaign.parent_id,
            })

            this.selectedCreators = []
        },

        onAddAllToCampaign({ campaign }) {
            this.$emit('edit-creators', {
                type: 'add-all',
                creators: this.dataInView.creators,
                campaign_id: campaign.id,
                supercampaign_id: campaign.parent_id,
            })

            this.selectedCreators = []
        },

        onShareList({ list, type, sharedOperator, sharedOrganization }) {
            this.$emit('on-list-share', {
                list,
                type,
                sharedOperator,
                sharedOrganization,
            })
        },

        onExportCreators() {
            this.$refs.listReport.onExportCreators()
        },
    },
}
</script>
