<script setup>
import { computed, ref } from 'vue'
import { useMutation, useQueryClient } from '@tanstack/vue-query'
import { capitalizePlatformId } from '@streamforgegg/utils'
import AddCustomValue from '@/partials/profiles/CreatorProfile/AddCustomValue.vue'
import { axios } from '@/utils/axios'

const props = defineProps({
    creator: {
        type: Object,
        required: true,
    },
    platformId: {
        type: String,
        required: true,
    },
    onCreate: {
        type: Function,
        required: true,
    },
})

const addingCustomValue = ref()

const options = computed(() => props.creator.socials[props.platformId]?.options)

const queryClient = useQueryClient()

const setAsPrimary = useMutation({
    mutationFn: (data) =>
        axios.post(`/creators/${props.creator._id}/set-primary-social`, {
            platform_id: props.platformId,
            platform_user_id: data.platform_user_id,
            creator_id: props.creator._id,
        }),
    onSuccess: async () => {
        console.log(props.creator._id)
        await queryClient.invalidateQueries({ queryKey: ['creators', props.creator._id] })
    },
})
</script>
<template>
    <div>
        <div class="pb-1 text-sm font-bold">{{ capitalizePlatformId(platformId) }}</div>
        <div class="space-y-1">
            <div
                v-for="option in options"
                :key="option.id"
                class="group flex h-10 items-center justify-between rounded-md border bg-slate-50 px-3 text-sm"
            >
                <div class="flex items-center">
                    <font-awesome-icon class="mr-2 h-4 w-4" :class="'text-' + platformId" :icon="['fab', platformId]" />
                    <div>{{ option.platform_user_name }}</div>
                </div>
                <button
                    v-if="!option.is_primary"
                    class="invisible rounded bg-white px-2 py-1 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 group-hover:visible hover:bg-gray-50"
                    :class="option.is_primary ? 'invisible' : ''"
                    @click="setAsPrimary.mutate(option)"
                >
                    Set as Primary{{ setAsPrimary.isLoading.value ? '' : '' }}
                </button>
                <div v-if="option.is_primary && options.length > 1" class="text-xs text-indigo-600">Primary</div>
            </div>
        </div>
        <AddCustomValue
            v-if="addingCustomValue"
            :creator="props.creator"
            :platform-id="platformId"
            :on-cancel="
                () => {
                    addingCustomValue = false
                }
            "
            :on-save="
                async () => {
                    addingCustomValue = false
                    // Invalidate the query
                    await queryClient.invalidateQueries({ queryKey: ['creators', props.creator._id] })
                }
            "
        />
        <template v-if="!addingCustomValue">
            <div v-if="options?.length > 0">
                <div
                    class="py-2 pl-2 text-sm text-indigo-600 hover:cursor-pointer hover:text-indigo-800"
                    @click="addingCustomValue = true"
                >
                    <div class="flex items-center">
                        <font-awesome-icon :icon="['fas', 'plus']" class="mr-1 h-3 w-3" />
                        <div class="pt-[1px]">Add social link</div>
                    </div>
                </div>
            </div>
            <div v-else class="flex h-10 items-center justify-between rounded-md border border-dashed px-3 text-sm">
                <div class="flex items-center text-sm">
                    <font-awesome-icon class="mr-2 h-4 w-4" :class="'text-' + platformId" :icon="['fab', platformId]" />
                    <div
                        class="flex items-center text-indigo-600 hover:cursor-pointer hover:text-indigo-800"
                        @click="addingCustomValue = true"
                    >
                        <font-awesome-icon :icon="['fas', 'plus']" class="mr-1 h-3 w-3" />
                        <div class="pt-[1px]">Add social link</div>
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>
