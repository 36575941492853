<template>
    <div class="relative col-span-full mb-8 overflow-hidden rounded-sm bg-indigo-200 p-4 sm:p-6">
        <!-- Background illustration -->
        <div class="pointer-events-none absolute right-0 top-0 -mt-4 mr-16 hidden xl:block" aria-hidden="true">
            <svg width="319" height="198" xmlns:xlink="http://www.w3.org/1999/xlink" class="opacity-10">
                <defs>
                    <path id="welcome-a" d="M64 0l64 128-64-20-64 20z" />
                    <path id="welcome-e" d="M40 0l40 80-40-12.5L0 80z" />
                    <path id="welcome-g" d="M40 0l40 80-40-12.5L0 80z" />
                    <linearGradient id="welcome-b" x1="50%" y1="0%" x2="50%" y2="100%">
                        <stop stop-color="#A5B4FC" offset="0%" />
                        <stop stop-color="#818CF8" offset="100%" />
                    </linearGradient>
                    <linearGradient id="welcome-c" x1="50%" y1="24.537%" x2="50%" y2="100%">
                        <stop stop-color="#4338CA" offset="0%" />
                        <stop stop-color="#6366F1" stop-opacity="0" offset="100%" />
                    </linearGradient>
                </defs>
                <g fill="none" fill-rule="evenodd">
                    <g transform="rotate(64 36.592 105.604)">
                        <mask id="welcome-d" fill="#fff">
                            <use xlink:href="#welcome-a" />
                        </mask>
                        <use fill="url(#welcome-b)" xlink:href="#welcome-a" />
                        <path fill="url(#welcome-c)" mask="url(#welcome-d)" d="M64-24h80v152H64z" />
                    </g>
                    <g transform="rotate(-51 91.324 -105.372)">
                        <mask id="welcome-f" fill="#fff">
                            <use xlink:href="#welcome-e" />
                        </mask>
                        <use fill="url(#welcome-b)" xlink:href="#welcome-e" />
                        <path fill="url(#welcome-c)" mask="url(#welcome-f)" d="M40.333-15.147h50v95h-50z" />
                    </g>
                    <g transform="rotate(44 61.546 392.623)">
                        <mask id="welcome-h" fill="#fff">
                            <use xlink:href="#welcome-g" />
                        </mask>
                        <use fill="url(#welcome-b)" xlink:href="#welcome-g" />
                        <path fill="url(#welcome-c)" mask="url(#welcome-h)" d="M40.333-15.147h50v95h-50z" />
                    </g>
                </g>
            </svg>
        </div>

        <!-- Content -->
        <div class="relative">
            <div class="grid grid-cols-12 gap-6">
                <div class="col-span-12 flex md:col-span-4">
                    <h1 class="my-auto text-2xl font-bold text-slate-800 md:text-3xl">
                        {{ getFromDictionary(`campaign.type.${campaign.type}`, 'labelPlural') }}
                    </h1>
                    <!-- <p>📅 Mon 27 Dec, 2021 @ 9:00 PM</p> -->
                </div>

                <div class="col-span-12 md:col-span-8">
                    <div class="grid grid-cols-2 xl:grid-cols-4">
                        <!-- Unique Visitors -->
                        <div v-if="platformId === 'twitch'" class="flex items-center py-2">
                            <div class="mx-auto">
                                <div class="flex items-center">
                                    <div class="mr-2 text-3xl font-bold text-slate-800">
                                        <span>
                                            {{
                                                $filters.formatWholeNumberWithUnit(
                                                    metrics.sum_seconds_watched / (60 * 60),
                                                )
                                            }}
                                        </span>
                                    </div>
                                    <!-- <div class="text-sm font-medium text-emerald-500">+49%</div> -->
                                </div>
                                <div class="text-sm text-slate-600">Total Hours Watched</div>
                            </div>
                        </div>

                        <!-- Video Views -->
                        <div v-if="platformId === 'youtube'" class="flex items-center py-2">
                            <div class="mx-auto">
                                <div class="flex items-center">
                                    <div class="mr-2 text-3xl font-bold text-slate-800">
                                        <span>
                                            {{ $filters.formatWholeNumberWithUnit(metrics.sum_views) }}
                                        </span>
                                    </div>
                                    <!-- <div class="text-sm font-medium text-emerald-500">+49%</div> -->
                                </div>
                                <div class="text-sm text-slate-600">Total Views</div>
                            </div>
                        </div>

                        <!-- Total Pageviews -->
                        <div
                            v-if="!disabledColumns.includes('data.summary.unique_clicks')"
                            class="flex items-center py-2"
                        >
                            <div class="mx-auto">
                                <div class="flex items-center">
                                    <div class="mr-2 text-3xl font-bold text-slate-800">
                                        <span v-if="metrics.sum_unique_clicks">
                                            {{ $filters.formatWholeNumberWithUnit(metrics.sum_unique_clicks) }}
                                        </span>
                                        <span v-else> - </span>
                                    </div>
                                    <!-- <div class="text-sm font-medium text-emerald-500">+7%</div> -->
                                </div>
                                <div class="text-sm text-slate-600">Unique Clicks</div>
                            </div>
                        </div>

                        <!-- Engagements -->
                        <div v-if="platformId === 'youtube'" class="flex items-center py-2">
                            <div class="mx-auto">
                                <div class="flex items-center">
                                    <div class="mr-2 text-3xl font-bold text-slate-800">
                                        <span>
                                            {{ $filters.numeral(metrics.sum_engagements).format('0,0a') }}
                                        </span>
                                    </div>
                                    <!-- <div class="text-sm font-medium text-amber-500">-7%</div> -->
                                </div>
                                <div class="text-sm text-slate-600">Engagements</div>
                            </div>
                        </div>

                        <!-- Visit Duration-->
                        <div v-if="platformId === 'twitch'" class="flex items-center py-2">
                            <div class="mx-auto">
                                <div class="flex items-center">
                                    <div class="mr-2 text-3xl font-bold text-slate-800">
                                        <span>
                                            {{ $filters.humanizeDurationShort(metrics.sum_duration_seconds * 1000) }}
                                        </span>
                                    </div>
                                    <!-- <div class="text-sm font-medium text-amber-500">+7%</div> -->
                                </div>
                                <div class="text-sm text-slate-600">Time Streamed</div>
                            </div>
                        </div>

                        <!-- Overstreamed -->
                        <template v-if="!disabledColumns.includes('data.summary.over_delivery')">
                            <div v-if="platformId === 'twitch'" class="flex items-center py-2">
                                <div class="mx-auto">
                                    <div class="flex items-center">
                                        <div class="mr-2 text-3xl font-bold text-slate-800">
                                            <span>{{
                                                $filters.numeral(metrics.avg_over_delivery + 1).format('0,0.0%')
                                            }}</span>
                                        </div>
                                        <!-- <div class="text-sm font-medium text-amber-500">-7%</div> -->
                                    </div>
                                    <div class="text-sm text-slate-600">AVG Over Delivery</div>
                                </div>
                            </div>

                            <!-- Overdelivery -->
                            <div v-if="platformId === 'youtube'" class="flex items-center py-2">
                                <div class="mx-auto">
                                    <div class="flex items-center">
                                        <div class="mr-2 text-3xl font-bold text-slate-800">
                                            <span>{{
                                                $filters.numeral(metrics.avg_over_delivery + 1).format('0.0%')
                                            }}</span>
                                        </div>
                                        <!-- <div class="text-sm font-medium text-amber-500">-7%</div> -->
                                    </div>
                                    <div class="text-sm text-slate-600">AVG Over Delivery</div>
                                </div>
                            </div>
                        </template>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    name: 'AggregateMetricsCard',

    props: {
        supercampaign: Object,
        campaign: Object,
        metrics: Object,
        platformId: {
            type: String,
            default: 'twitch',
        },
        disabledColumns: {
            type: Array,
            default: () => [],
        },
    },

    computed: {
        ...mapGetters(['getFromDictionary']),
    },
}
</script>
