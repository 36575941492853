<template>
    <div class="w-full overflow-x-auto">
        <table class="w-full table-auto">
            <thead class="rounded-sm bg-slate-100 text-xs uppercase text-slate-400">
                <tr>
                    <th class="whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5">
                        <div class="text-left font-semibold">Name</div>
                    </th>
                    <th class="whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5">
                        <div class="text-left font-semibold">Joined At</div>
                    </th>
                    <th class="whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5">
                        <div class="text-left font-semibold">Last Active At</div>
                    </th>
                    <th class="whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5">
                        <div class="text-right font-semibold">Actions</div>
                    </th>
                </tr>
            </thead>

            <tbody v-if="isLoading === true" class="divide-y divide-slate-200 text-sm">
                <SkeletonOperatorTable />
            </tbody>

            <tbody v-else class="divide-y divide-slate-200 text-sm">
                <tr v-for="operator in operators" :key="operator.id">
                    <td class="whitespace-nowrap px-2 py-3.5 first:pl-5 last:pr-5 md:w-1/2">
                        <div class="flex grow items-center">
                            <div class="flex items-center">
                                <Avatar
                                    class="bg-slate-700 text-slate-50"
                                    :username="`${operator.first_name} ${operator.last_name}`"
                                    :size="28"
                                />
                                <div class="flex flex-col pl-2">
                                    <div class="pr-2 font-semibold">
                                        <span>{{ operator.first_name }} {{ operator.last_name }}</span>
                                        <span v-if="operator.custom.is_self" class="ml-1">(You)</span>
                                    </div>
                                    <div class="text-xs">
                                        <span>{{ operator.email }}</span>
                                        <span v-if="!operator.is_email_verified" class="ml-1">(not verified)</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </td>
                    <td class="whitespace-nowrap px-2 py-3.5 first:pl-5 last:pr-5 md:w-1/4">
                        <div class="text-left">
                            {{ $filters.moment(operator.created_at).format('MMM Do, YYYY') }}
                        </div>
                    </td>
                    <td class="whitespace-nowrap px-2 py-3.5 first:pl-5 last:pr-5 md:w-1/4">
                        <div>
                            {{ $filters.moment(operator.last_active_at).format('MMM Do, YYYY') }}
                        </div>
                    </td>
                    <td
                        class="whitespace-nowrap px-2 py-3.5 first:pl-5 last:pr-5 md:w-1/4"
                        :class="{
                            'pointer-events-none opacity-10': operator.custom.is_self || !operator.custom.is_editable,
                        }"
                    >
                        <div class="flex items-center justify-end">
                            <!-- <div class="cursor-pointer text-slate-400 hover:text-slate-700 transition duration-200">
                                <font-awesome-icon 
                                    class="w-4 h-4" 
                                    :icon="['fas', 'trash']"
                                    @click="$emit('action', {
                                        operator: operator,
                                        type: 'delete',
                                    })"
                                />
                            </div> -->
                            <div class="cursor-pointer text-slate-400 transition duration-200 hover:text-slate-700">
                                <font-awesome-icon
                                    class="ml-4 h-4 w-4"
                                    :icon="['fas', 'pen-to-square']"
                                    @click="
                                        $emit('action', {
                                            operator: operator,
                                            type: 'edit',
                                        })
                                    "
                                />
                            </div>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import Avatar from '@/components/Avatar.vue'
import SkeletonOperatorTable from '@/partials/skeletons/SkeletonOperatorTable.vue'

export default {
    name: 'OperatorsTable',

    components: {
        Avatar,
        SkeletonOperatorTable,
    },

    props: {
        operators: {
            type: Object,
        },
        isLoading: {
            type: Boolean,
        },
    },

    emits: ['action'],
}
</script>
