<script setup>
import _ from 'lodash'
import { computed } from 'vue'
import Tooltip from '@/components/Tooltip.vue'

const props = defineProps({
    platformId: {
        type: String,
    },
    contentType: {
        type: String,
    },
    data: {
        type: Object,
        required: true,
    },
    row: {
        type: Object,
        required: true,
    },
})

const mappedData = computed(() => {
    if (props.platformId === 'twitch') {
        if (props.contentType === 'stream') {
            // If the object has a chapters property, we are dealing with a stream
            if (_.has(props.data, 'chapters')) {
                const gameId = _.first(props.data?.chapters)?.game_id

                return {
                    image: `https://streamforge-etl-media.nyc3.cdn.digitaloceanspaces.com/${
                        props.platformId
                    }/games/thumbnails/default/${encodeURI(gameId)}`,
                    gameId,
                    isSponsored: _.some(props.data.chapters, (chapter) => chapter.is_sponsored),
                    title: _.first(_.get(props.data, 'chapters'))?.title,
                    subTitleText: _.map(props.data.chapters, 'game_name')?.join(', '),
                }
            }

            // Otherwise it's a chapter of a stream
            return {
                image: `https://streamforge-etl-media.nyc3.cdn.digitaloceanspaces.com/${
                    props.platformId
                }/games/thumbnails/default/${encodeURI(props.data?.game_id)}`,
                gameId: props.data?.game_id,
                isSponsored: props.data.is_sponsored,
                title: props.data.title,
                subTitleText: props.data.game_name,
            }
        }
    }

    if (props.platformId === 'youtube') {
        if (['video', 'short', 'stream'].includes(props.contentType)) {
            return {
                image: props.data.snippet.thumbnails.default.url,
                gameId: props.data?._game_channel_id,
                isSponsored: props.data.is_sponsored,
                title: props.data.snippet.title,
                subTitleText: props.data._game_name
                    ? props.data._game_name
                    : _.map(props.data?.snippet?.tags, (item) => `#${item}`).join(' '),
            }
        }
    }

    if (props.platformId === 'tiktok') {
        const challenges = _.map(
            props.data?.challenges.filter((item) => item),
            (item) => `#${item}`,
        )
        const hashtags = _.map(
            props.data?.hashtags.filter((item) => item),
            (item) => `#${item}`,
        )

        return {
            image: `https://streamforge-etl-media.nyc3.cdn.digitaloceanspaces.com/${
                props.platformId
            }/videos/thumbnails/${encodeURI(props.data?._id)}`,
            isSponsored: props.data.is_sponsored,
            title: props.data.description,
            subTitleText: _.uniq([...challenges, ...hashtags]).join(' '),
        }
    }

    if (props.platformId === 'twitter') {
        const hashtags = _.map(
            props.data?.entities?.hashtags.filter((item) => item.text),
            (item) => `#${item.text}`,
        )

        const thumbnail = _.get(props.data, 'entities.media.0.media_url_https')
        const title = props.data.text

        return {
            image: thumbnail ?? null,
            isSponsored: props.data._is_sponsored,
            title: title.trim() ? title : 'No title',
            subTitleText: hashtags.join(' '),
        }
    }

    if (props.platformId === 'instagram') {
        const thumbnail = `https://streamforge-etl-media.nyc3.cdn.digitaloceanspaces.com/${props.platformId}/posts/thumbnails/${props.data._id}`
        const title = props.data.caption_text

        return {
            image: thumbnail ?? null,
            isSponsored: props.data._is_sponsored,
            title: title.trim() ? title : 'No title',
            subTitleText: props.data.hashtags?.map((item) => `#${item}`)?.join(' '),
        }
    }

    return {}
})

const isLastChapter = () => {
    return props.row.getParentRow().original.ended_at === props.row.original.ended_at
}
</script>

<template>
    <div class="relative py-2 pl-4">
        <div
            v-if="row.getIsExpanded() && $lodash.isUndefined(props.row.parentId)"
            class="absolute -bottom-0 -left-0.5 top-1/2 ml-1 h-1/2 w-0.5 divide-none border-none bg-slate-400"
        />

        <template v-else-if="!$lodash.isUndefined(props.row.parentId)">
            <div
                class="absolute -left-[0.15rem] top-1/2 h-0.5 w-2 translate-x-1/2 transform divide-none border-none bg-slate-400"
            >
                <div class="relative h-full w-full">
                    <div class="absolute left-0 top-0 h-full w-2 bg-slate-400" />
                </div>
            </div>

            <div
                class="absolute -left-0.5 top-0 ml-1 h-1/2 w-0.5 divide-none border-none bg-slate-400"
                :class="{ '!h-full': !isLastChapter() }"
            />
        </template>

        <div class="flex">
            <div
                class="relative flex h-8 w-16 shrink-0 overflow-hidden rounded bg-slate-200 bg-cover bg-center"
                :style="{
                    'background-image': `url(${mappedData.image})`,
                }"
            >
                <div v-if="!mappedData.image" class="m-auto text-xs">Text</div>
                <Tooltip v-if="mappedData.isSponsored" placement="top" max-width="400" class="h-full w-full">
                    <template #trigger>
                        <div
                            class="absolute right-0 top-0 flex h-full w-full bg-gradient-to-b from-transparent to-slate-900 pb-1"
                        >
                            <font-awesome-icon
                                :icon="['fas', 'dollar-sign']"
                                class="mx-auto my-auto h-4 w-4 text-green-300 drop-shadow-lg"
                            />
                        </div>

                        <div class="absolute bottom-0 left-0 h-1 w-full rounded-bl rounded-br bg-green-300"></div>
                    </template>
                    <template #default>
                        <div class="whitespace-nowrap text-xs">
                            Creator had a <span class="font-semibold text-white">#sponsored</span> indicator in their
                            title
                        </div>
                    </template>
                </Tooltip>
            </div>

            <div class="my-auto ml-3 max-w-32 flex-col overflow-hidden leading-none lg:min-w-48 lg:max-w-80">
                <div class="text-md line-clamp-1 grow whitespace-normal font-semibold" :title="mappedData.title">
                    {{ mappedData.title }}
                </div>
                <div class="line-clamp-1 flex grow whitespace-nowrap text-xs">
                    <span v-if="props.data.chapters">
                        {{ $lodash.size(props.data.chapters) }}
                        {{ $pluralize('chapter', $lodash.size(props.data.chapters)) }}&nbsp;-&nbsp;
                    </span>
                    <span :title="mappedData.subTitleText">
                        {{ mappedData.subTitleText }}
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>
