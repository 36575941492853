<template>
    <header class="sticky top-0 z-30 border-b border-slate-200 bg-white">
        <div class="px-4 sm:px-6 lg:px-8">
            <div class="-mb-px flex h-16 items-center">
                <!-- Header: Left side -->
                <div class="flex">
                    <!-- Hamburger button -->
                    <button
                        class="text-slate-500 hover:text-slate-600 lg:hidden"
                        aria-controls="sidebar"
                        :aria-expanded="sidebarOpen"
                        @click.stop="$emit('toggle-sidebar')"
                    >
                        <span class="sr-only">Open sidebar</span>
                        <svg class="h-6 w-6 fill-current" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <rect x="4" y="5" width="16" height="2" />
                            <rect x="4" y="11" width="16" height="2" />
                            <rect x="4" y="17" width="16" height="2" />
                        </svg>
                    </button>
                </div>

                <div class="ml-auto flex sm:w-full sm:justify-between">
                    <div class="mx-auto hidden px-2 sm:block">
                        <GlobalSearchBar v-if="checkPermission('feature.global.creator_search')" />
                    </div>

                    <div class="flex items-center space-x-3">
                        <template v-if="selfActiveOrganization && checkPermission('feature.campaigns.manage')">
                            <a
                                class="btn btn-sm bg-indigo-500 text-white hover:bg-indigo-600"
                                href="https://meetings.hubspot.com/streamforge/create-campaign"
                                target="_blank"
                                @click.prevent="$store.commit('modal:open', 'create_campaign')"
                            >
                                <svg class="h-4 w-4 shrink-0 fill-current opacity-50" viewBox="0 0 16 16">
                                    <path
                                        d="M15 7H9V1c0-.6-.4-1-1-1S7 .4 7 1v6H1c-.6 0-1 .4-1 1s.4 1 1 1h6v6c0 .6.4 1 1 1s1-.4 1-1V9h6c.6 0 1-.4 1-1s-.4-1-1-1z"
                                    />
                                </svg>
                                <span class="ml-2 hidden xs:block"
                                    ><span class="hidden lg:inline">Create </span>Campaign</span
                                >
                            </a>

                            <hr class="h-6 w-px bg-slate-200" />
                        </template>

                        <ProductUpdates />
                        <hr class="h-6 w-px bg-slate-200" />

                        <DropdownProfileSelf align="right" />
                    </div>
                </div>
            </div>
        </div>
    </header>
</template>

<script>
import { mapGetters } from 'vuex'
import ProductUpdates from '@/components/ProductUpdates.vue'
import DropdownProfileSelf from '../components/dropdowns/DropdownProfileSelf.vue'
import GlobalSearchBar from '../components/GlobalSearchBar.vue'

export default {
    name: 'AppHeader',
    components: {
        DropdownProfileSelf,
        GlobalSearchBar,
        ProductUpdates,
    },
    props: ['sidebarOpen'],
    data() {
        return {
            state: {
                modals: {
                    addCreator: {
                        isOpen: false,
                    },
                },
            },
        }
    },
    computed: {
        ...mapGetters(['selfActiveOrganization', 'isSfStaff', 'checkPermission']),
    },

    methods: {
        openCreateCampaignModal() {
            this.$store.commit('modal:open', 'create_campaign')
        },
    },
}
</script>
