<template>
    <Dialog :open="query.modal === 'creator_affinity_breakdown'" @update:open="onCreatorProfileModalClose">
        <DialogScrollContent :class="'max-w-5xl border-none p-0'">
            <CreatorProfile
                v-if="targetingGroups"
                :platform-id="query.platform_id"
                :platform-user-id="query.platform_user_id"
                :targeting-groups="targetingGroups"
            />
        </DialogScrollContent>
    </Dialog>

    <CreateSavedSearchModal
        :is-open="isOpen"
        :on-cancel="() => setIsOpen(false)"
        :on-save="() => {}"
        :on-close="() => setIsOpen(false)"
        :filtering="filtering"
        :platform="platformId"
    />
    <div class="mx-auto w-full max-w-9xl p-3 md:p-4 lg:p-8">
        <div class="grid grid-cols-[285px,1fr] gap-1" style="grid-template-columns: 285px 1fr">
            <div
                class="col-span-12 -ml-4 overflow-y-auto overflow-x-hidden px-4 scrollbar scrollbar-thumb-slate-200 scrollbar-w-2 scrollbar-h-2 hover:scrollbar-thumb-slate-300 active:scrollbar-thumb-slate-400 md:max-h-[calc(100vh-128px)] lg:col-span-1"
            >
                <div class="mb-2 flex text-left text-sm font-medium leading-5 text-slate-500">
                    <Tooltip class="my-auto mr-2 box-content">
                        <template #trigger>
                            <font-awesome-icon
                                class="h-3.5 w-3.5 text-slate-400 transition duration-200 hover:text-slate-500"
                                :icon="['fas', 'circle-info']"
                            />
                        </template>
                        <template #default>
                            <div class="font-bold">
                                <h5 class="text-lg">Discovery Platform</h5>
                                <div class="space-y-2">
                                    <p class="text-xs text-slate-300">
                                        The social media platform that the selected filters are being applied to. The
                                        number of creators shown is the total amount of creators that our system has
                                        found and continues to track.
                                    </p>
                                    <p class="mt-2 text-xs text-slate-300">
                                        It is very common for content creators to become inactive, and while our system
                                        continues to re-check frequently, these inactive creators are removed from
                                        discovery results. Creators are marked as inactive after 90 days without
                                        posting/uploading/streaming.
                                    </p>
                                </div>
                            </div>
                        </template>
                    </Tooltip>
                    <div>Platform</div>
                </div>
                <DropdownPlatform
                    :initial-selected-source-id="platformId"
                    :platforms="selectablePlatforms"
                    :counts="counts"
                    @change="onPlatformChange"
                />

                <div class="mt-4">
                    <div class="mb-2 flex text-left text-sm font-medium leading-5 text-slate-500">
                        <Tooltip class="my-auto mr-2 box-content">
                            <template #trigger>
                                <font-awesome-icon
                                    class="h-3.5 w-3.5 text-slate-400 transition duration-200 hover:text-slate-500"
                                    :icon="['fas', 'circle-info']"
                                />
                            </template>
                            <template #default>
                                <div class="font-bold">
                                    <h5 class="text-lg">Discovery Source</h5>
                                    <div class="space-y-2">
                                        <p class="text-xs text-slate-300">
                                            This determines what discovery is using as the source to apply your selected
                                            filters to. By default, your search will apply to all active creators on
                                            your selected platform. You can also apply filters to an existing list or
                                            campaign on your account.
                                        </p>
                                    </div>
                                </div>
                            </template>
                        </Tooltip>
                        <div>Source</div>
                    </div>

                    <DropdownSource
                        :platform-id="platformId"
                        :lists-by-platform-id="listsByPlatformId"
                        :campaigns-by-platform-id="campaignsByPlatformId"
                        :initial-selected-source="source"
                        :counts="counts"
                        @change="onSourceChange"
                    />
                </div>

                <div class="mb-48 mt-8">
                    <div
                        class="text-md relative mb-2 flex border-b border-slate-300 pb-2 text-left font-medium leading-5 text-slate-500"
                    >
                        <div class="flex">
                            <font-awesome-icon
                                :icon="['fad', 'filters']"
                                class="my-auto h-5 w-5"
                                :path-classes="['text-slate-400', 'text-slate-500']"
                            />
                            <span class="ml-2">Filters</span>
                        </div>

                        <div class="-mx-2 my-auto ml-auto text-xs font-semibold">
                            <span
                                class="cursor-pointer border-r border-slate-400 px-2 hover:underline"
                                :class="has0FiltersApplied ? 'pointer-events-none opacity-50' : ''"
                                @click="setIsOpen(true)"
                                >Save</span
                            >
                            <span v-if="selfActiveOrganization" class="border-r border-slate-400 px-2">
                                <DropdownTargetingGroup
                                    :targeting-groups="platformGroups"
                                    :codes="targetGroups.codes || []"
                                    :on-saved-search-selected="loadTargetGroup"
                                    :platform="platformId"
                                    @change="loadTargetGroup"
                                >
                                    <span class="cursor-pointer hover:underline">Load</span>
                                </DropdownTargetingGroup>
                            </span>
                            <span class="cursor-pointer px-2 hover:underline" @click="onReset">Reset</span>
                        </div>
                    </div>

                    <TargetingFilters
                        v-if="filtering"
                        :platform-id="platformId"
                        :filtering="filtering"
                        @filter-change-single="onSingleFilterChange"
                    />
                </div>
            </div>

            <div class="col-span-12 overflow-hidden lg:col-span-1">
                <DiscoverTable
                    :paging="paging"
                    :creators="creators"
                    :is-loading="state.creators.isLoading"
                    :is-busy="state.creators.isBusy"
                    :columns="tableColumns"
                    :enabled-columns="state.columns.enabled"
                    :platform-id="platformId"
                    :is-selectable="true"
                    @add-to-list="addListCreators"
                    @add-to-campaign="addCreatorsToCampaign"
                    @paging-change="onPagingChange"
                    @export-creators="onExportCreators"
                    @enabled-column-change="onEnabledColumnChange"
                    @filter-change="onFilterChange"
                >
                    <template #creator="{ field }">
                        <div class="flex w-full items-center justify-between">
                            <!-- Our slot transformer makes it easy for each platform -->

                            <router-link
                                class="flex"
                                :to="{
                                    path: $route.path,
                                    query: {
                                        ...query,
                                        modal: 'creator_affinity_breakdown',
                                        platform_id: platformId,
                                        platform_user_id: field.id,
                                    },
                                }"
                            >
                                <NamePlate
                                    class="my-auto cursor-pointer"
                                    :logo="field.logo"
                                    :name="field.name"
                                    :display-name="field.display_name"
                                    :platform-id="platformId"
                                    :platform-user-id="field.id"
                                    :is-drawer-enabled="false"
                                />
                            </router-link>

                            <router-link
                                :to="{
                                    path: $route.path,
                                    query: {
                                        ...query,
                                        modal: 'creator_affinity_breakdown',
                                        platform_id: platformId,
                                        platform_user_id: field.id,
                                    },
                                }"
                            >
                                <div
                                    class="invisible right-0 top-0 my-auto flex h-full cursor-pointer rounded bg-slate-100 px-2 py-0.5 text-xs font-semibold transition duration-200 group-hover:visible hover:bg-slate-200"
                                >
                                    <span class="my-auto flex uppercase">
                                        <font-awesome-icon
                                            :icon="['fas', 'rectangle-list']"
                                            class="my-auto mr-1 h-6 w-4"
                                        />
                                        <span class="my-auto leading-none">Open</span>
                                    </span>
                                </div>
                            </router-link>
                        </div>
                    </template>

                    <template #affinity="{ item, field, column }">
                        <AffinityPlate
                            :affinity-percent="field"
                            :class="{
                                'opacity-25': column.is_disabled,
                            }"
                            :metadata="metadata"
                            :affinity="item.affinity"
                            :discover-entry="item"
                        />
                    </template>

                    <template #code="{ field, column }">
                        <CodePlate
                            v-if="codes?.[column?.row?.code_type]?.[field]"
                            :code="codes?.[column?.row?.code_type]?.[field]"
                        />
                    </template>

                    <template #language="{ field }">
                        <Tooltip v-if="field" class="inline-block">
                            <template #trigger>
                                <div
                                    class="inline-block cursor-help rounded bg-slate-100 px-2 py-1 text-xs font-semibold"
                                >
                                    <span class="uppercase">{{ field }}</span>
                                </div>
                            </template>
                            <template #default>
                                <div class="whitespace-nowrap text-xs">{{ getLanguageFromCode(field) }}</div>
                            </template>
                        </Tooltip>
                    </template>

                    <template #country="{ field }">
                        <Tooltip v-if="field" class="inline-block">
                            <template #trigger>
                                <div
                                    class="inline-block cursor-help rounded bg-slate-100 px-2 py-1 text-xs font-semibold"
                                >
                                    <span class="uppercase">{{ field }}</span>
                                </div>
                            </template>
                            <template #default>
                                <div class="whitespace-nowrap text-xs">{{ getCountryFromCode(field) }}</div>
                            </template>
                        </Tooltip>
                    </template>
                </DiscoverTable>
            </div>
        </div>
    </div>
</template>

<script>
import _ from 'lodash'
import numeral from 'numeral'
import { mapGetters } from 'vuex'
import { json2csvAsync } from 'json-2-csv'

import { Dialog, DialogScrollContent } from '@/components/ui/dialog'
import CreatorProfile from '@/partials/profiles/CreatorProfile/CreatorProfile.vue'
import DropdownPlatform from '@/components/dropdowns/DropdownDiscoverPlatform.vue'
import DropdownSource from '@/components/dropdowns/DropdownDiscoverSource.vue'
import DropdownTargetingGroup from '@/components/dropdowns/DropdownTargetingGroup.vue'
import Tooltip from '@/components/Tooltip.vue'
import NamePlate from '@/components/plates/NamePlate.vue'
import AffinityPlate from '@/components/plates/AffinityPlate.vue'
import CodePlate from '@/components/plates/CodePlate.vue'
import DiscoverTable from '@/partials/tables/DiscoverTable.vue'
import TargetingFilters from '@/partials/options/TargetingFilters.vue'
import CreateSavedSearchModal from '@/partials/modals/CreateSavedSearchModal/CreateSavedSearchModal.vue'
import targetGroupTools from '../utils/searching/targetgroups'
import targetGroupAPI from '../utils/api/api.targeting'
import discoveryAPI from '../utils/api/api.discovery'
import discoveryAPIUtil from '../utils/api/api'

export default {
    name: 'DiscoverPage',

    metaInfo: {
        title: 'Discovery',
    },

    components: {
        CreateSavedSearchModal,
        DropdownPlatform,
        DropdownSource,
        DropdownTargetingGroup,
        Tooltip,
        NamePlate,
        AffinityPlate,
        CodePlate,
        DiscoverTable,
        TargetingFilters,
        CreatorProfile,
        // eslint-disable-next-line
        Dialog,
        DialogScrollContent,
    },

    data() {
        return {
            isOpen: false,
            creators: [],
            totalRecords: 0,

            counts: null,

            supercampaigns: [],
            campaigns: [],
            campaignsByPlatformId: {
                twitch: [],
                youtube: [],
                tiktok: [],
            },
            lists: [],
            listsByPlatformId: {
                twitch: [],
                youtube: [],
                tiktok: [],
            },
            targetGroups: {},

            codes: {},

            selectedTargetingGroup: '',
            platformGroups: [],

            platformId: 'youtube',

            source: {
                type: 'platform',
                input: 'youtube',
            },

            nameSearch: null,

            filtering: {
                current: null,
                default: null,
                options: null,
            },

            filteringByPlatform: {
                twitch: targetGroupTools.createTwitchOptions(),
                youtube: targetGroupTools.createYoutubeOptions(),
                tiktok: targetGroupTools.createTiktokOptions(),
                twitter: targetGroupTools.createTwitterOptions(),
                instagram: targetGroupTools.createInstagramOptions(),
            },

            metadata: {
                games: [],
                languages: [],
                demographics: {},
            },

            paging: {
                perPage: 15,
                currentPage: 1,
                totalRecords: 0,
                totalPages: 1,
                sort: [
                    {
                        field: 'avg_viewers',
                        direction: 'desc',
                        source: 'default',
                    },
                ],
            },

            state: {
                creators: {
                    isLoading: false,
                    isBusy: false,
                },
                columns: {
                    enabled: [],
                },
            },
        }
    },

    computed: {
        ...mapGetters([
            'isSfStaff',
            'getLanguageFromCode',
            'getCountryFromCode',
            'selfActiveOrganization',
            'checkPermission',
            'preferences',
        ]),

        query() {
            return this.$route.query
        },

        targetingGroups() {
            return [
                {
                    name: 'Discovery Search',
                    filters: _.assign(_.cloneDeep(this.filtering.current), {
                        platform: this.platformId,
                    }),
                },
            ]
        },

        has0FiltersApplied() {
            return _.isEqual(this.filtering.current, this.filtering.default)
        },

        tableColumns() {
            return this.getAvailableColumns()
        },

        selectablePlatforms() {
            const availablePlatforms = ['youtube', 'twitch', 'tiktok', 'twitter', 'instagram']

            return availablePlatforms

            // return _.filter(availablePlatforms, (platformId) => {
            //     const result = this.checkPermission(`feature.discovery.${platformId}`)

            //     console.log(result)

            //     return result
            // })
        },
    },

    watch: {
        // Resets the currentPage index when it's higher than totalPages
        'paging.totalPages': {
            handler() {
                if (this.paging.totalPages !== null && this.paging.currentPage > this.paging.totalPages) {
                    this.paging.currentPage = this.paging.totalPages
                    this.findCreators({
                        isExport: false,
                        isInitial: false,
                    })
                }
            },
        },

        query() {
            return this.query
        },

        'filtering.current.day_range': function (newValue, oldValue) {
            if (!oldValue || newValue !== oldValue) {
                this.setEnabledColumnDefaults()
            }
        },

        'filtering.current.content_type': function (newValue, oldValue) {
            if (!oldValue || newValue !== oldValue) {
                this.setEnabledColumnDefaults()
                this.resetSorting()
                this.checkSortingOverride()
            }
        },

        platformId: {
            handler(newValue, oldValue) {
                if (newValue !== oldValue) {
                    this.setEnabledColumnDefaults()
                }
            },
            deep: false,
            immediate: true,
        },
    },

    created() {
        if (this.preferences?.tables?.discovery?.rowsPerPage) {
            this.paging.perPage = this.preferences.tables.discovery.rowsPerPage
        }

        this.getCounts()
        this.getLists()
        this.getSupercampaigns()
        this.getTargetGroups()

        if (this.$route.query && _.size(this.$route.query) > 0) {
            try {
                const overwrites = discoveryAPIUtil.fromQueryString(
                    _.mapValues(_.clone(this.$route.query), (value, key) => {
                        // Override for games
                        const keyParts = key.split('.')
                        // if anything breaks with url query -> discover, it's probably this
                        if (_.last(keyParts) === 'id') return String(value)
                        // The games value should be a string instead of a number -- the numbers mess with the deep object diff algorithms when comparing applied filters to saved searches filters
                        if (_.last(keyParts) === 'value' && keyParts[1] === 'games') return String(value)
                        return discoveryAPIUtil.convertStringToType(value)
                    }),
                )

                // Make sure we set the platform first
                this.platformId = overwrites.platformId

                this.filtering = this.filteringByPlatform[this.platformId]

                this.filtering.current = _.merge(_.cloneDeep(this.filtering.default), overwrites.filtering)

                this.paging = overwrites.paging
                this.source = overwrites.source
            } catch (error) {
                console.error(`Failed to rehydrate state from url parameters`)

                this.onPlatformChange({
                    type: 'platform',
                    value: {
                        id: this.platformId,
                    },
                })
            }

            this.findCreators({
                isExport: false,
                isInitial: true,
            })
        } else {
            // This sets the platform and pre-populated the creator table
            this.onPlatformChange({
                type: 'platform',
                value: {
                    id: this.platformId,
                },
            })
        }
    },

    methods: {
        getCounts() {
            this.$axios.get(`/discovery/counts`).then(({ data }) => {
                this.counts = data.payload.counts
            })
        },

        setEnabledColumnDefaults() {
            if (this.preferences?.tables?.discovery[this.platformId]?.columns) {
                this.state.columns.enabled = this.preferences.tables.discovery[this.platformId].columns
            } else {
                this.state.columns.enabled = []
                this.state.columns.enabled.push('creator')

                if (this.platformId === 'twitch') {
                    this.state.columns.enabled.push('avg_viewers')
                    this.state.columns.enabled.push('followers')
                }

                if (this.platformId === 'youtube') {
                    this.state.columns.enabled.push('avg_views')
                    this.state.columns.enabled.push('subscribers')
                }

                if (this.platformId === 'tiktok') {
                    this.state.columns.enabled.push('avg_views')
                    this.state.columns.enabled.push('followers')
                }

                if (this.platformId === 'twitter') {
                    this.state.columns.enabled.push('avg_views')
                    this.state.columns.enabled.push('followers')
                }

                if (this.platformId === 'instagram') {
                    this.state.columns.enabled.push('avg_views')
                    this.state.columns.enabled.push('followers')
                }

                this.state.columns.enabled.push('language')
                this.state.columns.enabled.push('country')
            }
        },

        getAvailableColumns() {
            const fields = []

            const metricWindow = _.get(this.filtering.current, 'day_range')
            const contentType = _.get(this.filtering.current, 'content_type')

            let metricWindowName = ''
            if (metricWindow === 1095) {
                metricWindowName = '3 year'
            } else {
                metricWindowName = `${metricWindow} day`
            }

            fields.push({
                id: 'creator',
                key: 'superview',
                header: {
                    label: 'Creator',
                    is_sortable: true,
                    class: 'w-1/2',
                },
                row: {
                    class: '',
                    slot: 'creator',
                    slotTransformer: (item) => ({
                        // eslint-disable-next-line no-underscore-dangle
                        id: item?.superview?._id,
                        name: item.superview.normalized.name,
                        display_name: item.superview.normalized.display_name,
                        logo: item.superview.normalized.avatar_url,
                    }),
                },
                is_sticky: true,
                is_exportable: true,
                exportTransformer: (item) => ({
                    // eslint-disable-next-line no-underscore-dangle
                    ID: item.superview._id,
                    Handle: item?.superview?.normalized?.name,
                    'Display Name': item?.superview?.normalized?.display_name,
                }),
            })

            if (this.platformId === 'twitch') {
                fields.push({
                    id: 'avg_viewers',
                    key: `superview.stream.day_${String(metricWindow)}.aggregation.viewers.avg`,
                    header: {
                        label: `AVG Viewers`,
                        sub_label: `${metricWindowName}`,
                        is_sortable: true,
                        tooltip: {
                            title: `Average Concurrent Viewers`,
                            sub_title: `${metricWindowName} aggregation`,
                            content: [
                                {
                                    text: `The average number of viewers you would expect to see in this creator's stream at any point within the aggregation window.`,
                                },
                            ],
                        },
                    },
                    row: {
                        class: '',
                        formatter: ({ field }) => this.$filters.formatWholeNumberWithUnit(field),
                    },
                    is_exportable: true,
                })
                fields.push({
                    id: 'peak_viewers',
                    key: `superview.stream.day_${String(metricWindow)}.aggregation.viewers.peak.value`,
                    header: {
                        label: `Peak Viewers`,
                        sub_label: `${metricWindowName}`,
                        is_sortable: false,
                        tooltip: {
                            title: `Peak Concurrent Viewers`,
                            sub_title: `${metricWindowName} aggregation`,
                            content: [
                                {
                                    text: `The largest amount of concurrent viewers that were watching the creator's stream at once during the aggregation window.`,
                                },
                            ],
                        },
                    },
                    row: {
                        class: '',
                        formatter: ({ field }) => this.$filters.formatWholeNumberWithUnit(field),
                    },
                    is_exportable: true,
                })
                fields.push({
                    id: 'engagement_rate',
                    key: `superview.stream.day_${String(metricWindow)}.aggregation.engagement_rate.avg`,
                    header: {
                        label: `Engagement Rate`,
                        sub_label: `${metricWindowName}`,
                        is_sortable: false,
                        tooltip: {
                            title: `Average Engagement Rate`,
                            sub_title: `${metricWindowName} aggregation`,
                            content: [
                                {
                                    text: `The average number of chatters you would expect to see chatting in this creator's stream at any point within the aggregation window.`,
                                },
                            ],
                        },
                    },
                    row: {
                        class: '',
                        formatter: ({ field }) => numeral(field).format('0.0%'),
                    },
                    is_exportable: true,
                })
                fields.push({
                    id: 'avg_airtime',
                    key: `superview.stream.day_${String(metricWindow)}.aggregation.live_seconds.avg`,
                    header: {
                        label: `Avg Airtime`,
                        sub_label: `${metricWindowName}`,
                        is_sortable: false,
                        tooltip: {
                            title: `Average Airtime`,
                            sub_title: `${metricWindowName} aggregation`,
                            content: [
                                {
                                    text: `The average duration of this creator's streams.`,
                                },
                            ],
                        },
                    },
                    row: {
                        class: '',
                        formatter: ({ field }) => this.$filters.humanizeDurationShort(Math.round(field) * 1000),
                    },
                    is_exportable: true,
                })
                fields.push({
                    id: 'sum_airtime',
                    key: `superview.stream.day_${String(metricWindow)}.aggregation.live_seconds.sum`,
                    header: {
                        label: `Sum Airtime`,
                        sub_label: `${metricWindowName}`,
                        is_sortable: false,
                        tooltip: {
                            title: `Sum Airtime`,
                            sub_title: `${metricWindowName} aggregation`,
                            content: [
                                {
                                    text: `The total duration of this creator's streams during the aggregation window.`,
                                },
                            ],
                        },
                    },
                    row: {
                        class: '',
                        formatter: ({ field }) => this.$filters.humanizeDurationShort(Math.round(field) * 1000),
                    },
                    is_exportable: true,
                })
                fields.push({
                    id: 'peak_airtime',
                    key: `superview.stream.day_${String(metricWindow)}.aggregation.live_seconds.peak.value`,
                    header: {
                        label: `Peak Airtime`,
                        sub_label: `${metricWindowName}`,
                        is_sortable: false,
                        tooltip: {
                            title: `Peak Airtime`,
                            sub_title: `${metricWindowName} aggregation`,
                            content: [
                                {
                                    text: `The longest stream this creator has performed during the aggregation window.`,
                                },
                            ],
                        },
                    },
                    row: {
                        class: '',
                        formatter: ({ field }) => this.$filters.humanizeDurationShort(Math.round(field) * 1000),
                    },
                    is_exportable: true,
                })
                fields.push({
                    id: 'avg_impressions',
                    key: `superview.stream.day_${String(metricWindow)}.aggregation.impressions.avg`,
                    header: {
                        label: `Avg Impressions`,
                        sub_label: `${metricWindowName}`,
                        is_sortable: false,
                        tooltip: {
                            title: `Average Impressions per Stream`,
                            sub_title: `${metricWindowName} aggregation`,
                            content: [
                                {
                                    text: `The average amount of people that see the creator's stream during the aggregation window. A single person can generate multiple impressions on a stream.`,
                                },
                            ],
                        },
                    },
                    row: {
                        class: '',
                        formatter: ({ field }) => this.$filters.formatWholeNumberWithUnit(field),
                    },
                    is_exportable: true,
                })
                fields.push({
                    id: 'sum_impressions',
                    key: `superview.stream.day_${String(metricWindow)}.aggregation.impressions.sum`,
                    header: {
                        label: `Sum Impressions`,
                        sub_label: `${metricWindowName}`,
                        is_sortable: false,
                        tooltip: {
                            title: `Sum Impressions per Stream`,
                            sub_title: `${metricWindowName} aggregation`,
                            content: [
                                {
                                    text: `The total amount of times that a person saw the creator's streams during the aggregation window. A single person can generate multiple impressions on a stream.`,
                                },
                            ],
                        },
                    },
                    row: {
                        class: '',
                        formatter: ({ field }) => this.$filters.formatWholeNumberWithUnit(field),
                    },
                    is_exportable: true,
                })
                fields.push({
                    id: 'avg_minutes_watched',
                    key: `superview.stream.day_${String(metricWindow)}.aggregation.minutes_watched.avg`,
                    header: {
                        label: `Avg Minutes Watched`,
                        sub_label: `${metricWindowName}`,
                        is_sortable: false,
                        tooltip: {
                            title: `Average Minutes Watched`,
                            sub_title: `${metricWindowName} aggregation`,
                            content: [
                                {
                                    text: `The average amount of minutes that were watched by the creator's audience during live streams in the aggregation window.`,
                                },
                            ],
                        },
                    },
                    row: {
                        class: '',
                        formatter: ({ field }) => this.$filters.formatWholeNumberWithUnit(field),
                    },
                    is_exportable: true,
                })
                fields.push({
                    id: 'sum_minutes_watched',
                    key: `superview.stream.day_${String(metricWindow)}.aggregation.minutes_watched.sum`,
                    header: {
                        label: `Sum Minutes Watched`,
                        sub_label: `${metricWindowName}`,
                        is_sortable: false,
                        tooltip: {
                            title: `Sum Minutes Watched`,
                            sub_title: `${metricWindowName} aggregation`,
                            content: [
                                {
                                    text: `The total amount of minutes that were watched by the creator's audience during live streams in the aggregation window.`,
                                },
                            ],
                        },
                    },
                    row: {
                        class: '',
                        formatter: ({ field }) => this.$filters.formatWholeNumberWithUnit(field),
                    },
                    is_exportable: true,
                })
                // fields.push({
                //     id: 'days_streamed',
                //     key: `superview.stream.day_${String(metricWindow)}.accumulation.days_streamed.count`,
                //     header: {
                //         label: `Days Streamed`,
                //         sub_label: `${metricWindow} day`,
                //         is_sortable: false,
                //         tooltip: {
                //             title: `Total Unique Days Streamed`,
                //             sub_title: `${metricWindow} day aggregation`,
                //             content: [
                //                 {
                //                     text: `The total number of unique days that the creator streamed for part of, during the aggregation window.`,
                //                 },
                //             ],
                //         },
                //     },
                //     row: {
                //         class: '',
                //         formatter: ({ field }) => this.$filters.formatWholeNumberWithUnit(field),
                //     },
                //     is_exportable: true,
                // })
                fields.push({
                    id: 'total_games_played',
                    key: `superview.stream.day_${String(metricWindow)}.accumulation.games.count`,
                    header: {
                        label: `Games Played`,
                        sub_label: `${metricWindowName}`,
                        is_sortable: false,
                        tooltip: {
                            title: `Total Games Played`,
                            sub_title: `${metricWindowName} aggregation`,
                            content: [
                                {
                                    text: `The total number of games that the creator streamed during the aggregation window.`,
                                },
                            ],
                        },
                    },
                    row: {
                        class: '',
                        formatter: ({ field }) => this.$filters.formatWholeNumberWithUnit(field),
                    },
                    is_exportable: true,
                })
                fields.push({
                    id: 'total_streams',
                    key: `superview.stream.day_${String(metricWindow)}.accumulation.streams.count`,
                    header: {
                        label: `Total Streams`,
                        sub_label: `${metricWindowName}`,
                        is_sortable: true,
                        tooltip: {
                            title: `Total Streams`,
                            sub_title: `${metricWindowName} aggregation`,
                            content: [
                                {
                                    text: `The total number of streams during the aggregation window.`,
                                },
                            ],
                        },
                    },
                    row: {
                        class: '',
                        formatter: ({ field }) => this.$filters.formatWholeNumberWithUnit(field),
                    },
                    is_exportable: true,
                })
                fields.push({
                    id: 'followers',
                    key: 'superview.channel.followers',
                    header: {
                        label: 'Followers',
                        is_sortable: true,
                        tooltip: {
                            title: 'Follower Count',
                            content: [
                                {
                                    text: `The total number of users that are following this creator currently.`,
                                },
                            ],
                        },
                    },
                    row: {
                        class: '',
                        formatter: ({ field }) => this.$filters.formatWholeNumberWithUnit(field),
                    },
                    is_exportable: true,
                })
            }

            if (this.platformId === 'youtube') {
                if (contentType === 'stream') {
                    fields.push({
                        id: 'avg_viewers',
                        key: `superview.stream.day_${String(metricWindow)}.aggregation.viewers.avg`,
                        header: {
                            label: `AVG Viewers`,
                            sub_label: `${metricWindowName}`,
                            is_sortable: true,
                            tooltip: {
                                title: `Average Concurrent Viewers`,
                                sub_title: `${metricWindowName} aggregation`,
                                content: [
                                    {
                                        text: `The average number of viewers you would expect to see in this creator's stream at any point within the aggregation window.`,
                                    },
                                ],
                            },
                        },
                        row: {
                            class: '',
                            formatter: ({ field }) => this.$filters.formatWholeNumberWithUnit(field),
                        },
                        is_exportable: true,
                    })
                }

                fields.push({
                    id: 'avg_views',
                    key: `superview.${contentType}.day_${String(metricWindow)}.aggregation.views.avg`,
                    header: {
                        label: `AVG Views`,
                        sub_label: `${metricWindowName}`,
                        is_sortable: true,
                        tooltip: {
                            title: `Average Video Views`,
                            sub_title: `${metricWindowName} aggregation`,
                            content: [
                                {
                                    text: `This is how many views a creator's content is expected to accrue after 28 days.`,
                                },
                            ],
                        },
                    },
                    row: {
                        class: '',
                        formatter: ({ field }) => this.$filters.formatWholeNumberWithUnit(field),
                    },
                    is_exportable: true,
                })

                fields.push({
                    id: 'peak_views',
                    key: `superview.${contentType}.day_${String(metricWindow)}.aggregation.views.peak.value`,
                    header: {
                        label: `Peak Views`,
                        sub_label: `${metricWindowName}`,
                        is_sortable: false,
                        tooltip: {
                            title: `Peak Video Views`,
                            sub_title: `${metricWindowName} aggregation`,
                            content: [
                                {
                                    text: `This is the most views a creator has received on a piece of content of theirs after 28 days.`,
                                },
                            ],
                        },
                    },
                    row: {
                        class: '',
                        formatter: ({ field }) => this.$filters.formatWholeNumberWithUnit(field),
                    },
                    is_exportable: true,
                })
                fields.push({
                    id: 'sum_views',
                    key: `superview.${contentType}.day_${String(metricWindow)}.aggregation.views.sum`,
                    header: {
                        label: `Sum Views`,
                        sub_label: `${metricWindowName}`,
                        is_sortable: false,
                        tooltip: {
                            title: `Sum Views`,
                            sub_title: `${metricWindowName} aggregation`,
                            content: [
                                {
                                    text: `This is the total views the creator's content has accumulated after 28 days.`,
                                },
                            ],
                        },
                    },
                    row: {
                        class: '',
                        formatter: ({ field }) => this.$filters.formatWholeNumberWithUnit(field),
                    },
                    is_exportable: true,
                })
                fields.push({
                    id: 'engagement_rate',
                    key: `superview.${contentType}.day_${String(metricWindow)}.aggregation.engagement_rate.avg`,
                    header: {
                        label: `Engagement Rate`,
                        sub_label: `${metricWindowName}`,
                        is_sortable: false,
                        tooltip: {
                            title: `Average Engagement Rate`,
                            sub_title: `${metricWindowName} aggregation`,
                            content: [
                                {
                                    text: `The average percentage of viewers that decide to interactive with the creator's content.`,
                                },
                            ],
                        },
                    },
                    row: {
                        class: '',
                        formatter: ({ field }) => numeral(field).format('0.0%'),
                    },
                    is_exportable: true,
                })
                fields.push({
                    id: 'avg_comments',
                    key: `superview.${contentType}.day_${String(metricWindow)}.aggregation.comments.avg`,
                    header: {
                        label: `AVG Comments`,
                        sub_label: `${metricWindowName}`,
                        is_sortable: false,
                        tooltip: {
                            title: `Average Comments per Video`,
                            sub_title: `${metricWindowName} aggregation`,
                            content: [
                                {
                                    text: `This is how many comments a creator's content is expected to accrue after 28 days.`,
                                },
                            ],
                        },
                    },
                    row: {
                        class: '',
                        formatter: ({ field }) => this.$filters.formatWholeNumberWithUnit(field),
                    },
                    is_exportable: true,
                })
                fields.push({
                    id: 'peak_comments',
                    key: `superview.${contentType}.day_${String(metricWindow)}.aggregation.comments.peak.value`,
                    header: {
                        label: `Peak Comments`,
                        sub_label: `${metricWindowName}`,
                        is_sortable: false,
                        tooltip: {
                            title: `Peak Comments`,
                            sub_title: `${metricWindowName} aggregation`,
                            content: [
                                {
                                    text: `This is the most comments a creator has received on a piece of content of theirs after 28 days.`,
                                },
                            ],
                        },
                    },
                    row: {
                        class: '',
                        formatter: ({ field }) => this.$filters.formatWholeNumberWithUnit(field),
                    },
                    is_exportable: true,
                })
                fields.push({
                    id: 'sum_comments',
                    key: `superview.${contentType}.day_${String(metricWindow)}.aggregation.comments.sum`,
                    header: {
                        label: `Sum Comments`,
                        sub_label: `${metricWindowName}`,
                        is_sortable: false,
                        tooltip: {
                            title: `Sum Comments`,
                            sub_title: `${metricWindowName} aggregation`,
                            content: [
                                {
                                    text: `This is the total comments the creator's content has accumulated after 28 days.`,
                                },
                            ],
                        },
                    },
                    row: {
                        class: '',
                        formatter: ({ field }) => this.$filters.formatWholeNumberWithUnit(field),
                    },
                    is_exportable: true,
                })
                fields.push({
                    id: 'content_count',
                    key: `superview.${contentType}.day_${String(metricWindow)}.accumulation.videos.count`,
                    header: {
                        label: `Total ${contentType}s`,
                        sub_label: `${metricWindowName}`,
                        is_sortable: true,
                        tooltip: {
                            title: `Total ${contentType.charAt(0).toUpperCase() + contentType.slice(1)}s`,
                            sub_title: `${metricWindowName} aggregation`,
                            content: [
                                {
                                    text: `The total number of ${contentType}s during the aggregation window.`,
                                },
                            ],
                        },
                    },
                    row: {
                        class: '',
                        formatter: ({ field }) => this.$filters.formatWholeNumberWithUnit(field),
                    },
                    is_exportable: true,
                })
                fields.push({
                    id: 'avg_likes',
                    key: `superview.${contentType}.day_${String(metricWindow)}.aggregation.likes.avg`,
                    header: {
                        label: `AVG Likes`,
                        sub_label: `${metricWindowName}`,
                        is_sortable: false,
                        tooltip: {
                            title: `Average Likes per Video`,
                            sub_title: `${metricWindowName} aggregation`,
                            content: [
                                {
                                    text: `This is how many likes a creator's content is expected to accrue after 28 days.`,
                                },
                            ],
                        },
                    },
                    row: {
                        class: '',
                        formatter: ({ field }) => this.$filters.formatWholeNumberWithUnit(field),
                    },
                    is_exportable: true,
                })
                fields.push({
                    id: 'peak_likes',
                    key: `superview.${contentType}.day_${String(metricWindow)}.aggregation.likes.peak.value`,
                    header: {
                        label: `Peak Likes`,
                        sub_label: `${metricWindowName}`,
                        is_sortable: false,
                        tooltip: {
                            title: `Peak Likes`,
                            sub_title: `${metricWindowName} aggregation`,
                            content: [
                                {
                                    text: `This is the most likes a creator has received on a piece of content of theirs after 28 days.`,
                                },
                            ],
                        },
                    },
                    row: {
                        class: '',
                        formatter: ({ field }) => this.$filters.formatWholeNumberWithUnit(field),
                    },
                    is_exportable: true,
                })
                fields.push({
                    id: 'sum_likes',
                    key: `superview.${contentType}.day_${String(metricWindow)}.aggregation.likes.sum`,
                    header: {
                        label: `Sum Likes`,
                        sub_label: `${metricWindowName}`,
                        is_sortable: false,
                        tooltip: {
                            title: `Sum Likes`,
                            sub_title: `${metricWindowName} aggregation`,
                            content: [
                                {
                                    text: `This is the total likes the creator's content has accumulated after 28 days.`,
                                },
                            ],
                        },
                    },
                    row: {
                        class: '',
                        formatter: ({ field }) => this.$filters.formatWholeNumberWithUnit(field),
                    },
                    is_exportable: true,
                })
                fields.push({
                    id: 'subscribers',
                    key: 'superview.channel.statistics.subscriberCount',
                    header: {
                        label: 'Subscribers',
                        is_sortable: true,
                        tooltip: {
                            title: 'Subscribers',
                            content: [
                                {
                                    text: `The total number of users on YouTube that are subscribed to this creator currently.`,
                                },
                            ],
                        },
                    },
                    row: {
                        class: '',
                        formatter: ({ field }) => this.$filters.formatWholeNumberWithUnit(field),
                    },
                    is_exportable: true,
                })
                fields.push({
                    id: 'total_games_played',
                    key: `superview.${contentType}.day_${String(metricWindow)}.accumulation.games.count`,
                    header: {
                        label: `Games Played`,
                        sub_label: `${metricWindowName}`,
                        is_sortable: false,
                        tooltip: {
                            title: `Total Games Played`,
                            sub_title: `${metricWindowName} aggregation`,
                            content: [
                                {
                                    text: `The total number of games that the creator streamed during the aggregation window.`,
                                },
                            ],
                        },
                    },
                    row: {
                        class: '',
                        formatter: ({ field }) => this.$filters.formatWholeNumberWithUnit(field),
                    },
                    is_exportable: true,
                })
            }

            if (this.platformId === 'tiktok') {
                fields.push({
                    id: 'avg_views',
                    key: `superview.${contentType}.day_${String(metricWindow)}.aggregation.views.avg`,
                    header: {
                        label: `AVG Views`,
                        sub_label: `${metricWindowName}`,
                        is_sortable: true,
                        tooltip: {
                            title: `Average Video Views`,
                            sub_title: `${metricWindowName} aggregation`,
                            content: [
                                {
                                    text: `This is how many views a creator's content is expected to accrue after 28 days.`,
                                },
                            ],
                        },
                    },
                    row: {
                        class: '',
                        formatter: ({ field }) => this.$filters.formatWholeNumberWithUnit(field),
                    },
                    is_exportable: true,
                })
                fields.push({
                    id: 'peak_views',
                    key: `superview.${contentType}.day_${String(metricWindow)}.aggregation.views.peak.value`,
                    header: {
                        label: `Peak Views`,
                        sub_label: `${metricWindowName}`,
                        is_sortable: false,
                        tooltip: {
                            title: `Peak Video Views`,
                            sub_title: `${metricWindowName} aggregation`,
                            content: [
                                {
                                    text: `This is the most views a creator has received on a piece of content of theirs after 28 days.`,
                                },
                            ],
                        },
                    },
                    row: {
                        class: '',
                        formatter: ({ field }) => this.$filters.formatWholeNumberWithUnit(field),
                    },
                    is_exportable: true,
                })
                fields.push({
                    id: 'sum_views',
                    key: `superview.${contentType}.day_${String(metricWindow)}.aggregation.views.sum`,
                    header: {
                        label: `Sum Views`,
                        sub_label: `${metricWindowName}`,
                        is_sortable: false,
                        tooltip: {
                            title: `Sum Views`,
                            sub_title: `${metricWindowName} aggregation`,
                            content: [
                                {
                                    text: `This is the total views the creator's content has accumulated after 28 days.`,
                                },
                            ],
                        },
                    },
                    row: {
                        class: '',
                        formatter: ({ field }) => this.$filters.formatWholeNumberWithUnit(field),
                    },
                    is_exportable: true,
                })
                fields.push({
                    id: 'engagement_rate',
                    key: `discovery.avg_engagement_rate`,
                    header: {
                        label: `Engagement Rate`,
                        sub_label: `${metricWindowName}`,
                        is_sortable: false,
                        tooltip: {
                            title: `Average Engagement Rate`,
                            sub_title: `${metricWindowName} aggregation`,
                            content: [
                                {
                                    text: `The average percentage of viewers that decide to interact with the creator's content.`,
                                },
                            ],
                        },
                    },
                    row: {
                        class: '',
                        formatter: ({ field }) => numeral(field).format('0.0%'),
                    },
                    is_exportable: true,
                })
                fields.push({
                    id: 'avg_comments',
                    key: `superview.${contentType}.day_${String(metricWindow)}.aggregation.comments.avg`,
                    header: {
                        label: `AVG Comments`,
                        sub_label: `${metricWindowName}`,
                        is_sortable: false,
                        tooltip: {
                            title: `Average Comments per Video`,
                            sub_title: `${metricWindowName} aggregation`,
                            content: [
                                {
                                    text: `This is how many comments a creator's content is expected to accrue after 28 days.`,
                                },
                            ],
                        },
                    },
                    row: {
                        class: '',
                        formatter: ({ field }) => this.$filters.formatWholeNumberWithUnit(field),
                    },
                    is_exportable: true,
                })
                fields.push({
                    id: 'peak_comments',
                    key: `superview.${contentType}.day_${String(metricWindow)}.aggregation.comments.peak.value`,
                    header: {
                        label: `Peak Comments`,
                        sub_label: `${metricWindowName}`,
                        is_sortable: false,
                        tooltip: {
                            title: `Peak Comments`,
                            sub_title: `${metricWindowName} aggregation`,
                            content: [
                                {
                                    text: `This is the most comments a creator has received on a piece of content of theirs after 28 days.`,
                                },
                            ],
                        },
                    },
                    row: {
                        class: '',
                        formatter: ({ field }) => this.$filters.formatWholeNumberWithUnit(field),
                    },
                    is_exportable: true,
                })
                fields.push({
                    id: 'sum_comments',
                    key: `superview.${contentType}.day_${String(metricWindow)}.aggregation.comments.sum`,
                    header: {
                        label: `Sum Comments`,
                        sub_label: `${metricWindowName}`,
                        is_sortable: false,
                        tooltip: {
                            title: `Sum Comments`,
                            sub_title: `${metricWindowName} aggregation`,
                            content: [
                                {
                                    text: `This is the total comments the creator's content has accumulated after 28 days.`,
                                },
                            ],
                        },
                    },
                    row: {
                        class: '',
                        formatter: ({ field }) => this.$filters.formatWholeNumberWithUnit(field),
                    },
                    is_exportable: true,
                })
                fields.push({
                    id: 'content_count',
                    key: `superview.video.day_${String(metricWindow)}.accumulation.videos.count`,
                    header: {
                        label: `Total Videos`,
                        sub_label: `${metricWindowName}`,
                        is_sortable: true,
                        tooltip: {
                            title: `Total Videos`,
                            sub_title: `${metricWindowName} aggregation`,
                            content: [
                                {
                                    text: `The total number of videos during the aggregation window.`,
                                },
                            ],
                        },
                    },
                    row: {
                        class: '',
                        formatter: ({ field }) => this.$filters.formatWholeNumberWithUnit(field),
                    },
                    is_exportable: true,
                })
                fields.push({
                    id: 'avg_likes',
                    key: `superview.${contentType}.day_${String(metricWindow)}.aggregation.likes.avg`,
                    header: {
                        label: `AVG Likes`,
                        sub_label: `${metricWindowName}`,
                        is_sortable: false,
                        tooltip: {
                            title: `Average Likes per Video`,
                            sub_title: `${metricWindowName} aggregation`,
                            content: [
                                {
                                    text: `This is how many likes a creator's content is expected to accrue after 28 days.`,
                                },
                            ],
                        },
                    },
                    row: {
                        class: '',
                        formatter: ({ field }) => this.$filters.formatWholeNumberWithUnit(field),
                    },
                    is_exportable: true,
                })
                fields.push({
                    id: 'peak_likes',
                    key: `superview.${contentType}.day_${String(metricWindow)}.aggregation.likes.peak.value`,
                    header: {
                        label: `Peak Likes`,
                        sub_label: `${metricWindowName}`,
                        is_sortable: false,
                        tooltip: {
                            title: `Peak Likes`,
                            sub_title: `${metricWindowName} aggregation`,
                            content: [
                                {
                                    text: `This is the most likes a creator has received on a piece of content of theirs after 28 days.`,
                                },
                            ],
                        },
                    },
                    row: {
                        class: '',
                        formatter: ({ field }) => this.$filters.formatWholeNumberWithUnit(field),
                    },
                    is_exportable: true,
                })
                fields.push({
                    id: 'sum_likes',
                    key: `superview.${contentType}.day_${String(metricWindow)}.aggregation.likes.sum`,
                    header: {
                        label: `Sum Likes`,
                        sub_label: `${metricWindowName}`,
                        is_sortable: false,
                        tooltip: {
                            title: `Sum Likes`,
                            sub_title: `${metricWindowName} aggregation`,
                            content: [
                                {
                                    text: `This is the total likes the creator's content has accumulated after 28 days.`,
                                },
                            ],
                        },
                    },
                    row: {
                        class: '',
                        formatter: ({ field }) => this.$filters.formatWholeNumberWithUnit(field),
                    },
                    is_exportable: true,
                })
                fields.push({
                    id: 'followers',
                    key: 'superview.channel.statistics.followers',
                    header: {
                        label: 'Followers',
                        is_sortable: true,
                        tooltip: {
                            title: 'Followers',
                            content: [
                                {
                                    text: `The total number of users that are following this creator currently.`,
                                },
                            ],
                        },
                    },
                    row: {
                        class: '',
                        formatter: ({ field }) => this.$filters.formatWholeNumberWithUnit(field),
                    },
                    is_exportable: true,
                })
            }

            if (this.platformId === 'twitter') {
                fields.push({
                    id: 'avg_views',
                    key: `superview.${contentType}.day_${String(metricWindow)}.aggregation.views.avg`,
                    header: {
                        label: `AVG Views`,
                        sub_label: `${metricWindowName}`,
                        is_sortable: true,
                        tooltip: {
                            title: `Average Video Views`,
                            sub_title: `${metricWindowName} aggregation`,
                            content: [
                                {
                                    text: `This is how many views a creator's content is expected to accrue after 28 days.`,
                                },
                            ],
                        },
                    },
                    row: {
                        class: '',
                        formatter: ({ field }) => this.$filters.formatWholeNumberWithUnit(field),
                    },
                    is_exportable: true,
                })
                fields.push({
                    id: 'peak_views',
                    key: `superview.${contentType}.day_${String(metricWindow)}.aggregation.views.peak.value`,
                    header: {
                        label: `Peak Views`,
                        sub_label: `${metricWindowName}`,
                        is_sortable: false,
                        tooltip: {
                            title: `Peak Video Views`,
                            sub_title: `${metricWindowName} aggregation`,
                            content: [
                                {
                                    text: `This is the most views a creator has received on a piece of content of theirs after 28 days.`,
                                },
                            ],
                        },
                    },
                    row: {
                        class: '',
                        formatter: ({ field }) => this.$filters.formatWholeNumberWithUnit(field),
                    },
                    is_exportable: true,
                })
                fields.push({
                    id: 'sum_views',
                    key: `superview.${contentType}.day_${String(metricWindow)}.aggregation.views.sum`,
                    header: {
                        label: `Sum Views`,
                        sub_label: `${metricWindowName}`,
                        is_sortable: false,
                        tooltip: {
                            title: `Sum Views`,
                            sub_title: `${metricWindowName} aggregation`,
                            content: [
                                {
                                    text: `This is the total views the creator's content has accumulated after 28 days.`,
                                },
                            ],
                        },
                    },
                    row: {
                        class: '',
                        formatter: ({ field }) => this.$filters.formatWholeNumberWithUnit(field),
                    },
                    is_exportable: true,
                })
                fields.push({
                    id: 'engagement_rate',
                    key: `discovery.avg_engagement_rate`,
                    header: {
                        label: `Engagement Rate`,
                        sub_label: `${metricWindowName}`,
                        is_sortable: false,
                        tooltip: {
                            title: `Average Engagement Rate`,
                            sub_title: `${metricWindowName} aggregation`,
                            content: [
                                {
                                    text: `The average percentage of viewers that decide to interactive with the creator's content.`,
                                },
                            ],
                        },
                    },
                    row: {
                        class: '',
                        formatter: ({ field }) => numeral(field).format('0.0%'),
                    },
                    is_exportable: true,
                })
                fields.push({
                    id: 'avg_comments',
                    key: `superview.${contentType}.day_${String(metricWindow)}.aggregation.replies.avg`,
                    header: {
                        label: `AVG Comments`,
                        sub_label: `${metricWindowName}`,
                        is_sortable: false,
                        tooltip: {
                            title: `Average Comments per Video`,
                            sub_title: `${metricWindowName} aggregation`,
                            content: [
                                {
                                    text: `This is how many comments a creator's content is expected to accrue after 28 days.`,
                                },
                            ],
                        },
                    },
                    row: {
                        class: '',
                        formatter: ({ field }) => this.$filters.formatWholeNumberWithUnit(field),
                    },
                    is_exportable: true,
                })
                fields.push({
                    id: 'peak_comments',
                    key: `superview.${contentType}.day_${String(metricWindow)}.aggregation.replies.peak.value`,
                    header: {
                        label: `Peak Comments`,
                        sub_label: `${metricWindowName}`,
                        is_sortable: false,
                        tooltip: {
                            title: `Peak Comments`,
                            sub_title: `${metricWindowName} aggregation`,
                            content: [
                                {
                                    text: `This is the most comments a creator has received on a piece of content of theirs after 28 days.`,
                                },
                            ],
                        },
                    },
                    row: {
                        class: '',
                        formatter: ({ field }) => this.$filters.formatWholeNumberWithUnit(field),
                    },
                    is_exportable: true,
                })
                fields.push({
                    id: 'sum_comments',
                    key: `superview.${contentType}.day_${String(metricWindow)}.aggregation.replies.sum`,
                    header: {
                        label: `Sum Comments`,
                        sub_label: `${metricWindowName}`,
                        is_sortable: false,
                        tooltip: {
                            title: `Sum Comments`,
                            sub_title: `${metricWindowName} aggregation`,
                            content: [
                                {
                                    text: `This is the total comments the creator's content has accumulated after 28 days.`,
                                },
                            ],
                        },
                    },
                    row: {
                        class: '',
                        formatter: ({ field }) => this.$filters.formatWholeNumberWithUnit(field),
                    },
                    is_exportable: true,
                })
                fields.push({
                    id: 'content_count',
                    key: `superview.post.day_${String(metricWindow)}.accumulation.posts.count`,
                    header: {
                        label: `Total Posts`,
                        sub_label: `${metricWindowName}`,
                        is_sortable: true,
                        tooltip: {
                            title: `Total Posts`,
                            sub_title: `${metricWindowName} aggregation`,
                            content: [
                                {
                                    text: `The total number of posts during the aggregation window.`,
                                },
                            ],
                        },
                    },
                    row: {
                        class: '',
                        formatter: ({ field }) => this.$filters.formatWholeNumberWithUnit(field),
                    },
                    is_exportable: true,
                })
                fields.push({
                    id: 'avg_likes',
                    key: `superview.${contentType}.day_${String(metricWindow)}.aggregation.likes.avg`,
                    header: {
                        label: `AVG Likes`,
                        sub_label: `${metricWindowName}`,
                        is_sortable: false,
                        tooltip: {
                            title: `Average Likes per Video`,
                            sub_title: `${metricWindowName} aggregation`,
                            content: [
                                {
                                    text: `This is how many likes a creator's content is expected to accrue after 28 days.`,
                                },
                            ],
                        },
                    },
                    row: {
                        class: '',
                        formatter: ({ field }) => this.$filters.formatWholeNumberWithUnit(field),
                    },
                    is_exportable: true,
                })
                fields.push({
                    id: 'peak_likes',
                    key: `superview.${contentType}.day_${String(metricWindow)}.aggregation.likes.peak.value`,
                    header: {
                        label: `Peak Likes`,
                        sub_label: `${metricWindowName}`,
                        is_sortable: false,
                        tooltip: {
                            title: `Peak Likes`,
                            sub_title: `${metricWindowName} aggregation`,
                            content: [
                                {
                                    text: `This is the most likes a creator has received on a piece of content of theirs after 28 days.`,
                                },
                            ],
                        },
                    },
                    row: {
                        class: '',
                        formatter: ({ field }) => this.$filters.formatWholeNumberWithUnit(field),
                    },
                    is_exportable: true,
                })
                fields.push({
                    id: 'sum_likes',
                    key: `superview.${contentType}.day_${String(metricWindow)}.aggregation.likes.sum`,
                    header: {
                        label: `Sum Likes`,
                        sub_label: `${metricWindowName}`,
                        is_sortable: false,
                        tooltip: {
                            title: `Sum Likes`,
                            sub_title: `${metricWindowName} aggregation`,
                            content: [
                                {
                                    text: `This is the total likes the creator's content has accumulated after 28 days.`,
                                },
                            ],
                        },
                    },
                    row: {
                        class: '',
                        formatter: ({ field }) => this.$filters.formatWholeNumberWithUnit(field),
                    },
                    is_exportable: true,
                })
                fields.push({
                    id: 'followers',
                    key: 'superview.channel.followers_count',
                    header: {
                        label: 'Followers',
                        is_sortable: true,
                        tooltip: {
                            title: 'Followers',
                            content: [
                                {
                                    text: `The total number of users that are following this creator currently.`,
                                },
                            ],
                        },
                    },
                    row: {
                        class: '',
                        formatter: ({ field }) => this.$filters.formatWholeNumberWithUnit(field),
                    },
                    is_exportable: true,
                })
            }

            if (this.platformId === 'instagram') {
                fields.push({
                    id: 'avg_views',
                    key: `superview.${contentType}.day_${String(metricWindow)}.aggregation.views.avg`,
                    header: {
                        label: `AVG Views`,
                        sub_label: `${metricWindowName}`,
                        is_sortable: true,
                        tooltip: {
                            title: `Average Views per Video`,
                            sub_title: `${metricWindowName} aggregation`,
                            content: [
                                {
                                    text: `This is how many views a creator's content is expected to accrue after 28 days.`,
                                },
                            ],
                        },
                    },
                    row: {
                        class: '',
                        formatter: ({ field }) => this.$filters.formatWholeNumberWithUnit(field),
                    },
                    is_exportable: true,
                })
                fields.push({
                    id: 'avg_likes',
                    key: `superview.${contentType}.day_${String(metricWindow)}.aggregation.likes.avg`,
                    header: {
                        label: `AVG Likes`,
                        sub_label: `${metricWindowName}`,
                        is_sortable: true,
                        tooltip: {
                            title: `Average Likes per Video`,
                            sub_title: `${metricWindowName} aggregation`,
                            content: [
                                {
                                    text: `This is how many likes a creator's content is expected to accrue after 28 days.`,
                                },
                            ],
                        },
                    },
                    row: {
                        class: '',
                        formatter: ({ field }) => this.$filters.formatWholeNumberWithUnit(field),
                    },
                    is_exportable: true,
                })
                fields.push({
                    id: 'peak_likes',
                    key: `superview.${contentType}.day_${String(metricWindow)}.aggregation.likes.peak.value`,
                    header: {
                        label: `Peak Likes`,
                        sub_label: `${metricWindowName}`,
                        is_sortable: false,
                        tooltip: {
                            title: `Peak Likes`,
                            sub_title: `${metricWindowName} aggregation`,
                            content: [
                                {
                                    text: `This is the most likes a creator has received on a piece of content of theirs after 28 days.`,
                                },
                            ],
                        },
                    },
                    row: {
                        class: '',
                        formatter: ({ field }) => this.$filters.formatWholeNumberWithUnit(field),
                    },
                    is_exportable: true,
                })
                fields.push({
                    id: 'sum_likes',
                    key: `superview.${contentType}.day_${String(metricWindow)}.aggregation.likes.sum`,
                    header: {
                        label: `Sum Likes`,
                        sub_label: `${metricWindowName}`,
                        is_sortable: false,
                        tooltip: {
                            title: `Sum Likes`,
                            sub_title: `${metricWindowName} aggregation`,
                            content: [
                                {
                                    text: `This is the total likes the creator's content has accumulated after 28 days.`,
                                },
                            ],
                        },
                    },
                    row: {
                        class: '',
                        formatter: ({ field }) => this.$filters.formatWholeNumberWithUnit(field),
                    },
                    is_exportable: true,
                })
                fields.push({
                    id: 'engagement_rate',
                    key: `discovery.avg_engagement_rate`,
                    header: {
                        label: `Engagement Rate`,
                        sub_label: `${metricWindowName}`,
                        is_sortable: false,
                        tooltip: {
                            title: `Average Engagement Rate`,
                            sub_title: `${metricWindowName} aggregation`,
                            content: [
                                {
                                    text: `The average percentage of viewers that decide to interactive with the creator's content.`,
                                },
                            ],
                        },
                    },
                    row: {
                        class: '',
                        formatter: ({ field }) => numeral(field).format('0.0%'),
                    },
                    is_exportable: true,
                })
                fields.push({
                    id: 'avg_comments',
                    key: `superview.${contentType}.day_${String(metricWindow)}.aggregation.comments.avg`,
                    header: {
                        label: `AVG Comments`,
                        sub_label: `${metricWindowName}`,
                        is_sortable: false,
                        tooltip: {
                            title: `Average Comments per Video`,
                            sub_title: `${metricWindowName} aggregation`,
                            content: [
                                {
                                    text: `This is how many comments a creator's content is expected to accrue after 28 days.`,
                                },
                            ],
                        },
                    },
                    row: {
                        class: '',
                        formatter: ({ field }) => this.$filters.formatWholeNumberWithUnit(field),
                    },
                    is_exportable: true,
                })
                fields.push({
                    id: 'peak_comments',
                    key: `superview.${contentType}.day_${String(metricWindow)}.aggregation.comments.peak.value`,
                    header: {
                        label: `Peak Comments`,
                        sub_label: `${metricWindowName}`,
                        is_sortable: false,
                        tooltip: {
                            title: `Peak Comments`,
                            sub_title: `${metricWindowName} aggregation`,
                            content: [
                                {
                                    text: `This is the most comments a creator has received on a piece of content of theirs after 28 days.`,
                                },
                            ],
                        },
                    },
                    row: {
                        class: '',
                        formatter: ({ field }) => this.$filters.formatWholeNumberWithUnit(field),
                    },
                    is_exportable: true,
                })
                fields.push({
                    id: 'sum_comments',
                    key: `superview.${contentType}.day_${String(metricWindow)}.aggregation.comments.sum`,
                    header: {
                        label: `Sum Comments`,
                        sub_label: `${metricWindowName}`,
                        is_sortable: false,
                        tooltip: {
                            title: `Sum Comments`,
                            sub_title: `${metricWindowName} aggregation`,
                            content: [
                                {
                                    text: `This is the total comments the creator's content has accumulated after 28 days.`,
                                },
                            ],
                        },
                    },
                    row: {
                        class: '',
                        formatter: ({ field }) => this.$filters.formatWholeNumberWithUnit(field),
                    },
                    is_exportable: true,
                })
                fields.push({
                    id: 'content_count',
                    key: `superview.post.day_${String(metricWindow)}.accumulation.posts.count`,
                    header: {
                        label: `Total Posts`,
                        sub_label: `${metricWindowName}`,
                        is_sortable: true,
                        tooltip: {
                            title: `Total Posts`,
                            sub_title: `${metricWindowName} aggregation`,
                            content: [
                                {
                                    text: `The total number of posts during the aggregation window.`,
                                },
                            ],
                        },
                    },
                    row: {
                        class: '',
                        formatter: ({ field }) => this.$filters.formatWholeNumberWithUnit(field),
                    },
                    is_exportable: true,
                })
                fields.push({
                    id: 'followers',
                    key: 'superview.channel.statistics.followers',
                    header: {
                        label: 'Followers',
                        is_sortable: true,
                        tooltip: {
                            title: 'Followers',
                            content: [
                                {
                                    text: `The total number of users that are following this creator currently.`,
                                },
                            ],
                        },
                    },
                    row: {
                        class: '',
                        formatter: ({ field }) => this.$filters.formatWholeNumberWithUnit(field),
                    },
                    is_exportable: true,
                })
            }

            fields.push({
                id: 'language',
                key: 'discovery.language',
                header: {
                    label: 'Language',
                    is_sortable: true,
                },
                row: {
                    slot: 'code',
                    code_type: 'language',
                },
                is_exportable: true,
            })
            fields.push({
                id: 'country',
                key: 'discovery.country',
                header: {
                    label: 'Country',
                    is_sortable: true,
                },
                row: {
                    slot: 'code',
                    code_type: 'country',
                },
                is_exportable: true,
            })
            fields.push({
                id: 'gender',
                key: 'discovery.gender',
                header: {
                    label: 'Gender',
                    is_sortable: false,
                    tooltip: {
                        title: 'Gender',
                        content: [
                            {
                                text: `This is an estimate made by AI to determine the gender of the creator.`,
                            },
                        ],
                    },
                },
                row: {
                    slot: 'code',
                    code_type: 'gender',
                },
                is_exportable: true,
            })
            fields.push({
                id: 'age_range',
                key: 'discovery.age_range',
                header: {
                    label: 'Age Range',
                    is_sortable: false,
                    tooltip: {
                        title: 'Age Range',
                        content: [
                            {
                                text: `This is an estimate made by AI to determine the age range of the creator.`,
                            },
                        ],
                    },
                },
                row: {
                    slot: 'code',
                    code_type: 'age-range',
                },
                is_exportable: true,
            })
            fields.push({
                id: 'main_industry',
                key: 'discovery.industry',
                header: {
                    label: 'Main Industry',
                    is_sortable: false,
                    tooltip: {
                        title: 'Main Industry',
                        content: [
                            {
                                text: `This is an estimate made by AI to determine the main industry of the creator.`,
                            },
                        ],
                    },
                },
                row: {
                    slot: 'code',
                    code_type: 'industry',
                },
                is_exportable: true,
            })
            fields.push({
                id: 'main_industry_category',
                key: 'discovery.industry_category',
                header: {
                    label: 'Main Industry Category',
                    is_sortable: false,
                    tooltip: {
                        title: 'Main Industry Category',
                        content: [
                            {
                                text: `This is an estimate made by AI to determine the main industry category of the creator.`,
                            },
                        ],
                    },
                },
                row: {
                    slot: 'code',
                    code_type: 'industry_category',
                },
                is_exportable: true,
            })
            fields.push({
                id: 'content_rating',
                key: 'discovery.content_rating',
                header: {
                    label: 'Content Rating',
                    is_sortable: false,
                    tooltip: {
                        title: 'Content Rating',
                        content: [
                            {
                                text: `This is an estimate made by AI to determine the content rating of the creator.`,
                            },
                        ],
                    },
                },
                row: {
                    slot: 'code',
                    code_type: 'content_rating',
                },
                is_exportable: true,
            })

            if (_.size(this?.filtering?.current?.games?.values) > 0) {
                fields.push({
                    id: 'affinity',
                    key: 'affinity.game.percent',
                    header: {
                        label: 'Affinity',
                        is_sortable: true,
                        tooltip: {
                            title: 'Affinity',
                            content: [
                                {
                                    text: `Affinity represents how well a creator and their audience match a given targeting group.`,
                                },
                            ],
                        },
                    },
                    row: {
                        class: '',
                        slot: 'affinity',
                    },
                    is_exportable: true,
                    is_disabled:
                        !this.filtering?.current?.games?.values || _.size(this.filtering.current?.games?.values) === 0,
                })
            }

            if (this.platformId === 'twitch') {
                this.appendDemographicColumns(fields, 'country')
                this.appendDemographicColumns(fields, 'language')
                this.appendDemographicColumns(fields, 'device')
                this.appendDemographicColumns(fields, 'os')
            }

            return fields
        },

        setIsOpen(value) {
            this.isOpen = value
        },

        onReset() {
            // We need to make sure to preserve campaign or list filters when resetting
            const preservedFields = ['campaign_id', 'list_id']
            const preservedFilters = {}

            _.forEach(preservedFields, (fieldName) => {
                if (_.has(this.filtering.current, fieldName)) {
                    preservedFilters[fieldName] = this.filtering.current[fieldName]
                }
            })

            this.filtering.current = _.assign(_.cloneDeep(this.filtering.default), preservedFilters)
            this.resetSorting()

            this.paging.currentPage = 1

            this.findCreators({
                isExport: false,
                isInitial: false,
            })
        },

        /**
         * We have isInitial because there are a number of things we don't want to do if this
         * is the initial page load
         */
        onPlatformChange(newPlatform) {
            this.source.type = 'platform'
            this.source.input = null

            // If we have swapped to a new platform, we need to reset all the filters
            if (newPlatform.type === 'platform') {
                this.platformId = newPlatform.value.id

                if (this.filteringByPlatform[this.platformId]) {
                    this.filtering = this.filteringByPlatform[this.platformId]

                    if (!this.filtering.current) {
                        this.filtering.current = _.cloneDeep(this.filtering.default)
                    }

                    this.resetSorting()
                } else {
                    console.error(`Error, platform set has no filtering configuration`)
                }
            }

            // Reset paging settings
            this.clear()

            this.setTargetGroups()

            this.findCreators({
                isExport: false,
                isInitial: true,
            })
        },

        onSourceChange(newSource) {
            this.source.type = newSource.type
            this.source.input = newSource.value.id

            // If we have swapped to a new platform, we need to reset all the filters
            if (newSource.type === 'platform') {
                // this.platformId = newSource.value.id
                this.filtering = this.filteringByPlatform[this.platformId]

                this.filtering.current = _.cloneDeep(this.filtering.default)
            }

            // Reset paging settings
            this.clear()

            this.findCreators({
                isExport: false,
                isInitial: true,
            })
        },

        clear() {
            this.creators = []
            this.paging.totalRecords = 0
            this.paging.totalPages = 1
            this.paging.currentPage = 1
        },

        checkSortingOverride() {
            const newQueryHasSetGame = _.size(this.filtering?.current?.games?.values) >= 1
            // const userHasManuallySorted = _.some(this.paging.sort, (item) => item.source !== 'default')

            // If games have been provided to search, and user has not manually sorted the table, let's
            // make sure to sort by affinity descending + avg viewers descending
            if (newQueryHasSetGame) {
                // if (!userHasManuallySorted) {
                // this.resetSorting()

                const existingAffinitySort = _.find(this.paging.sort, (item) => item.field === 'affinity')

                if (!existingAffinitySort) {
                    this.paging.sort.unshift({
                        field: 'affinity',
                        direction: 'desc',
                        source: 'auto',
                    })
                }
                // }

                // Make sure the affinity column shows
                if (!this.state.columns.enabled.includes('affinity')) {
                    this.state.columns.enabled.push('affinity')
                }
            } else {
                this.paging.sort = _.filter(this.paging.sort, (item) => item.field !== 'affinity')
                this.state.columns.enabled = _.filter(this.state.columns.enabled, (item) => item !== 'affinity')
            }
        },

        onPagingChange(updatedPaging) {
            _.assign(this.paging, updatedPaging)
            this.onFilterChange()

            if (updatedPaging.perPage) {
                this.$store.commit('preferences:set', {
                    key: `tables.discovery.rowsPerPage`,
                    value: this.paging.perPage,
                })
            }
        },

        onFilterChange() {
            this.checkSortingOverride()

            this.findCreators({
                isExport: false,
                isInitial: false,
            })
        },

        onSingleFilterChange(field) {
            // this.filtering.current[field.key] = field.value

            _.set(this.filtering.current, field.key, field.value)

            this.checkSortingOverride()

            this.findCreators({
                isExport: false,
                isInitial: false,
            })
        },

        resetSorting() {
            const currentContentType = this.filtering?.current?.content_type

            // console.log(`resetSorting`, currentContentType)

            if (currentContentType === 'stream') {
                this.paging.sort = [
                    {
                        field: 'avg_viewers',
                        direction: 'desc',
                        source: 'default',
                    },
                ]
            } else if (currentContentType === 'video') {
                this.paging.sort = [
                    {
                        field: 'avg_views',
                        direction: 'desc',
                        source: 'default',
                    },
                ]
            }
        },

        async findCreators({ isExport = false, isInitial = true }) {
            const filters = this.filtering.current
            const { paging } = this

            this.appendSourceFilter(filters)

            // eslint-disable-next-line camelcase
            const { platform_user_id } = this.query

            // This is to keep the state of an opened creator modal on the discovery page
            // eslint-disable-next-line camelcase
            const updatedQuery = platform_user_id
                ? { ...this.query }
                : {
                      ...discoveryAPIUtil.toQueryString({
                          filtering: this.filtering.current,
                          paging: this.paging,
                          platformId: this.platformId,
                          source: this.source,
                      }),
                  }

            this.$router.replace({
                query: updatedQuery,
            })

            if (isInitial === true) {
                this.state.creators.isLoading = true
            } else {
                this.state.creators.isBusy = true
            }

            // Clear all pending requests to API of this type
            this.$store.dispatch('CANCEL_PENDING_REQUESTS_BY_ENDPOINT', '/discovery/twitch')
            this.$store.dispatch('CANCEL_PENDING_REQUESTS_BY_ENDPOINT', '/discovery/youtube')
            this.$store.dispatch('CANCEL_PENDING_REQUESTS_BY_ENDPOINT', '/discovery/tiktok')
            this.$store.dispatch('CANCEL_PENDING_REQUESTS_BY_ENDPOINT', '/discovery/twitter')
            this.$store.dispatch('CANCEL_PENDING_REQUESTS_BY_ENDPOINT', '/discovery/instagram')

            // If we are exporting results w/ out a game filter, set page to 1
            if (isExport === true && _.size(filters?.games?.values) === 0) {
                paging.currentPage = 1
            }

            let findCreatorsResponse

            try {
                // Make sure to provide the cancel token to the request
                findCreatorsResponse = await discoveryAPI.findCreators(
                    this.$axios,
                    this.platformId,
                    filters,
                    paging,
                    isExport,
                )
            } catch (error) {
                console.error(`Error with findCreatorsResponse`, error)
            }

            if (findCreatorsResponse?.success) {
                this.state.creators.isLoading = false
                this.state.creators.isBusy = false

                // Set the metadata for use in affinity plate
                this.metadata.games = _.keyBy(findCreatorsResponse.value.codes.games, 'id')
                this.metadata.languages = _.keyBy(findCreatorsResponse.value.codes.languages, 'id')

                this.metadata.demographics = {
                    country: _.keyBy(findCreatorsResponse.value.codes.demographicCountryTypes, 'id'),
                    language: _.keyBy(findCreatorsResponse.value.codes.demographicLanguageTypes, 'id'),
                    device: _.keyBy(findCreatorsResponse.value.codes.demographicDeviceTypes, 'id'),
                    os: _.keyBy(findCreatorsResponse.value.codes.demographicOSTypes, 'id'),
                }

                if (findCreatorsResponse.value.creatorCodes) {
                    this.codes = _.mapValues(findCreatorsResponse.value.creatorCodes, (value) => {
                        return _.keyBy(value, 'code')
                    })
                }

                if (isExport) {
                    this.export(findCreatorsResponse.value)
                } else {
                    this.creators = findCreatorsResponse.value.creators

                    this.paging.perPage = findCreatorsResponse.value.paging.perPage
                    this.paging.totalRecords = findCreatorsResponse.value.paging.totalRecords

                    if (this.paging.totalRecords) {
                        this.paging.totalPages = Math.ceil(this.paging.totalRecords / this.paging.perPage)
                    } else {
                        this.paging.totalPages = null
                    }

                    if (this.paging.totalPages === 0) {
                        this.paging.totalPages = 0
                    }
                }
            }
        },

        appendSourceFilter(filters) {
            if (this.source.type === 'platform') {
                return
            }

            const sourceTypes = [
                { name: 'list', field: 'list_id' },
                { name: 'campaign', field: 'campaign_id' },
            ]

            const sourceField = _.find(sourceTypes, (item) => item.name === this.source.type)

            if (sourceField) {
                // eslint-disable-next-line no-param-reassign
                filters[sourceField.field] = this.source.input
            }
        },

        getLists() {
            return this.$axios
                .get('/lists')
                .then(({ data }) => {
                    this.lists = _.map(data.payload.myLists, (list) => {
                        // eslint-disable-next-line no-param-reassign
                        list.type = 'user'
                        return list
                    })

                    const allLists = [...data.payload.myLists, ...data.payload.sharedLists]

                    // We have to do some intense platform splitting because lists can contain creators from multiple platforms
                    // and our search algorithm can & should only work on one platform at a time

                    this.listsByPlatformId = this.filterItemsIntoPlatforms(allLists)
                })
                .catch((error) => {
                    console.log(error)
                })
        },

        filterItemsIntoPlatforms(items) {
            const platformsToGroupBy = ['twitch', 'youtube', 'tiktok', 'twitter', 'instagram']
            const groupedByPlatform = {}

            _.forEach(platformsToGroupBy, (platformId) => {
                groupedByPlatform[platformId] = []
            })

            _.forEach(items, (item) => {
                _.forEach(platformsToGroupBy, (platformId) => {
                    if (_.get(item, `totals.${platformId}`, 0) > 0) {
                        groupedByPlatform[platformId].push(item)
                    }
                })
            })

            return groupedByPlatform
        },

        getSupercampaigns() {
            this.$axios
                .get(`/campaigns/summary`, {
                    params: {},
                })
                .then(({ data }) => {
                    this.campaigns = _(data.payload).orderBy(['created_at'], ['desc']).value()
                    this.campaignsByPlatformId = this.filterItemsIntoPlatforms(this.campaigns)
                })
                .catch((error) => {
                    this.$errorHandler(error, {
                        title: `Supercampaigns`,
                        message: `Error fetching supercampaigns.`,
                    })
                })
        },

        addListCreators({ creators, list }) {
            this.$axios
                .post(`/lists/${list.id}/creators`, {
                    creators,
                })
                .then(({ data }) => {
                    // update list creator count on sidebar
                    const listToUpdate = _.find(this.lists, { id: list.id })
                    listToUpdate.creators.push(data.payload.creators)
                })
                .catch((error) => {
                    console.log(error)
                })
        },

        addCreatorsToCampaign({ creators, supercampaignId, campaignId, type }) {
            if (type === 'add-selected') {
                // console.log('add selected creators')
                const body = {
                    creators,
                }

                this.$axios
                    .post(`/supercampaigns/${supercampaignId}/campaigns/${campaignId}/participants`, body)
                    .then(() => {
                        // console.log('Added selected creators')
                    })
                    .catch((error) => {
                        console.log(error)
                    })
            }

            if (type === 'add-all') {
                const body = {
                    creators,
                }

                this.$axios
                    .post(`/supercampaigns/${supercampaignId}/campaigns/${campaignId}/participants`, body)
                    .then(() => {
                        // console.log('Added selected creators')
                    })
                    .catch((error) => {
                        console.log(error)
                    })
            }
        },

        setTargetGroups() {
            let groups = _.filter(
                this.targetGroups.groups,
                (targetingGroup) => targetingGroup.platform === this.platformId,
            )

            groups = _.sortBy(groups, (targetingGroup) => targetingGroup.name)
            this.platformGroups = groups
        },

        async getTargetGroups() {
            const targetingGroupsResponse = await targetGroupAPI.getAvailableTargetGroups(this.$axios)
            if (targetingGroupsResponse.success) {
                this.targetGroups = targetingGroupsResponse.value
                this.setTargetGroups()
            }
        },

        loadTargetGroup(group, codes) {
            let codesToUse = codes

            if (
                _.find(this.targetGroups?.groups, (existingGroup) => {
                    return group.id === existingGroup.id
                })
            ) {
                codesToUse = this.targetGroups.codes
            }

            const loadedGroup = targetGroupTools.mapTargetGroupFromFilters(group, codesToUse, {
                twitch: this.filteringByPlatform.twitch.default,
                youtube: this.filteringByPlatform.youtube.default,
                tiktok: this.filteringByPlatform.tiktok.default,
                twitter: this.filteringByPlatform.twitter.default,
                instagram: this.filteringByPlatform.instagram.default,
            })

            this.setGroup(group.platform, loadedGroup)
        },

        setGroup(platformId, group) {
            // eslint-disable-next-line no-multi-assign
            this.filtering.current = this.filteringByPlatform[platformId].current = group
            this.checkSortingOverride()
        },

        getDemographicCode(demographicType, id) {
            const code = _.find(this.metadata.demographics[demographicType], (item) => item.id === id)

            if (code) {
                return code.platforms.web.code
            }

            return ''
        },

        appendDemographicColumns(fields, demographicType) {
            if (this.filtering.current.demographics) {
                if (this.filtering.current.demographics[demographicType]) {
                    _.forEach(this.filtering.current.demographics[demographicType].values, (demographic) => {
                        const keyCode = this.getDemographicCode(demographicType, demographic.id)
                        fields.push({
                            id: `demographics.${demographicType}.${keyCode}`,
                            key: `demographics.${demographicType}.${keyCode}`,
                            header: {
                                label: demographic.label,
                                is_sortable: true,
                            },
                            row: {
                                class: '',
                                formatter: ({ field }) => {
                                    if (field) {
                                        return `${this.$filters.formatWholeNumber(field)}%`
                                    }
                                    return ''
                                },
                            },
                            is_exportable: true,
                        })
                    })
                }
            }
        },

        onEnabledColumnChange(newEnabledColumns) {
            this.state.columns.enabled = newEnabledColumns

            this.$store.commit('preferences:set', {
                key: `tables.discovery.${this.platformId}.columns`,
                value: this.state.columns.enabled,
            })
        },

        onExportCreators() {
            this.$notify({
                type: 'info',
                text: 'Exporting list of creators... Please wait a moment.',
                duration: 10000,
                speed: 1000,
            })
            this.findCreators({
                isExport: true,
                isInitial: false,
            })
        },

        async export(data) {
            try {
                const { data: creatorData } = await this.$axios.post(`/creators/${this.platformId}`, {
                    platform_user_ids: data.creators.map((creator) => creator._id),
                })

                const link = document.createElement('a')

                const mappedFields = _.map(data.creators, (creator) => {
                    const result = {}

                    _.forEach(this.tableColumns, (column) => {
                        if (!column.is_exportable) return

                        if (_.has(column, 'exportTransformer')) {
                            _.assign(result, column.exportTransformer(creator))
                        } else {
                            const value = _.get(creator, column.key)
                            result[column.header.label] = value !== undefined && value !== null ? value : ''
                        }
                    })

                    // Overriding with values from creator linking
                    const linkedCreator = creatorData.find((item) =>
                        item.platforms.find(
                            (platform) =>
                                platform.platform_user_id === creator._id && platform.platform_id === this.platformId,
                        ),
                    )

                    if (this.isSfStaff) {
                        if (_.has(linkedCreator, 'metadata.primaryEmail')) {
                            result.Email = _.get(linkedCreator, 'metadata.primaryEmail')
                        } else if (_.has(creator.superview, 'normalized.email')) {
                            result.Email = _.get(creator.superview, 'normalized.email')
                        } else {
                            result.Email = ''
                        }
                    }

                    return result
                })

                const csvResults = await json2csvAsync(mappedFields)

                link.setAttribute('href', `data:text/csv;charset=utf-8,${encodeURIComponent(csvResults)}`)
                link.setAttribute('download', 'creators.csv')
                link.style.visibility = 'hidden'
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
            } catch (error) {
                console.error('Error exporting creators', error)
                this.$notify.error({
                    title: 'Error exporting creators',
                    text: 'There was an error exporting the list of creators. Please try again.',
                })
            }
        },

        onCreatorProfileModalClose() {
            this.$router.replace({
                query: {
                    ...this.$route.query,
                    modal: undefined,
                    platform_id: undefined,
                    platform_user_id: undefined,
                },
            })
        },
    },
}
</script>
