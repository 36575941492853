<template>
    <EditSavedSearchModal
        v-if="Boolean(selectedSavedSearch)"
        :is-open="true"
        :on-cancel="() => (selectedSavedSearch = undefined)"
        :on-save="() => {}"
        :on-close="() => (selectedSavedSearch = undefined)"
        :saved-search="selectedSavedSearch"
        :codes="props.codes"
    />
    <Popover class="relative inline-block text-left">
        <PopoverButton ref="trigger">
            <slot />
        </PopoverButton>

        <PopoverPanel
            ref="container"
            class="absolute z-[100] mt-2 divide-y divide-gray-100 rounded-md border bg-white shadow-lg lg:w-[260px]"
        >
            <TabGroup>
                <TabList
                    class="grid w-full divide-x border-b text-sm"
                    :class="checkPermission('module.campaigns') ? 'grid-cols-2' : 'grid-cols-1'"
                >
                    <Tab v-if="checkPermission('module.campaigns')" v-slot="{ selected }" as="template"
                        ><button
                            class="px-1 py-1 font-semibold hover:underline"
                            :class="{ 'bg-slate-100 ': selected, 'bg-white': !selected }"
                        >
                            Targeting
                        </button></Tab
                    >
                    <Tab v-slot="{ selected }" as="template"
                        ><button
                            class="px-2 py-2 font-semibold hover:underline"
                            :class="{ 'bg-slate-100 ': selected, 'bg-white': !selected }"
                        >
                            Saved Searches
                        </button></Tab
                    >
                </TabList>
                <TabPanels>
                    <TabPanel
                        v-if="selectedOrganization.type === 'admin'"
                        class="max-h-96 divide-y overflow-y-auto scrollbar scrollbar-track-slate-200 scrollbar-thumb-slate-400 scrollbar-w-2 scrollbar-h-2 hover:scrollbar-thumb-slate-500 active:scrollbar-thumb-slate-600"
                    >
                        <div class="bg-slate-100 px-3 py-1">Campaign Targeting Groups</div>
                        <PopoverButton
                            v-for="group in targetingGroupsAlphabetically"
                            :key="group.id"
                            as="div"
                            class="cursor-pointer border-slate-200 px-3 py-1.5 hover:bg-slate-50"
                            @click="onSelect(group)"
                        >
                            <span class="block text-sm font-medium" :title="group.title">{{ group.title }}</span>
                            <span class="block text-xs font-medium"></span></PopoverButton
                    ></TabPanel>
                    <TabPanel
                        class="max-h-96 divide-y overflow-y-auto scrollbar scrollbar-track-slate-200 scrollbar-thumb-slate-400 scrollbar-w-2 scrollbar-h-2 hover:scrollbar-thumb-slate-500 active:scrollbar-thumb-slate-600"
                    >
                        <div class="bg-slate-100 px-3 py-1">{{ selectedOrganization.name }} Organization</div>
                        <div
                            v-if="savedSearches.data.value?.platformGroups?.length === 0"
                            class="flex flex-col items-center justify-center space-y-2 px-2 py-8 text-center"
                        >
                            <font-awesome-icon :icon="['fa', 'search']" class="h-8 w-8 text-slate-300" />
                            <div>No saved searches</div>
                        </div>
                        <PopoverButton
                            v-for="savedSearch in savedSearches.data.value?.platformGroups"
                            :key="savedSearch.id"
                            as="div"
                            class="group cursor-pointer border-slate-200 px-3 py-1.5 hover:bg-slate-50"
                            @click="onSavedSearchSelected(savedSearch, savedSearches.data.value?.codes)"
                        >
                            <div class="flex w-full items-center justify-between">
                                <span class="block text-sm font-medium" :title="savedSearch.title">{{
                                    savedSearch.title
                                }}</span>
                                <PopoverButton
                                    type="button"
                                    class="invisible rounded bg-white px-2 py-1 text-xs font-semibold text-gray-800 shadow-sm ring-1 ring-inset ring-gray-300 group-hover:visible hover:bg-gray-50"
                                    @click.stop="selectedSavedSearch = savedSearch"
                                >
                                    Edit
                                </PopoverButton>
                            </div>
                        </PopoverButton></TabPanel
                    >
                </TabPanels>
            </TabGroup>
        </PopoverPanel>
    </Popover>
</template>

<script setup>
import { computed, ref } from 'vue'
import { orderBy } from 'lodash'
import { Popover, PopoverButton, PopoverPanel, Tab, TabGroup, TabList, TabPanel, TabPanels } from '@headlessui/vue'
import { useQuery } from '@tanstack/vue-query'
import { useStore } from 'vuex'
import EditSavedSearchModal from '@/partials/modals/EditSavedSearchModal.vue'
import { usePopper } from '@/hooks/usePopper'
import { useOrganization } from '@/hooks/useOrganization'
import { axios } from '@/utils/axios'

const props = defineProps({
    targetingGroups: {
        type: Array,
        default: () => [],
    },
    platform: {
        type: String,
        required: true,
    },
    onSavedSearchSelected: {
        type: Function,
        required: true,
    },
    codes: {
        type: Array,
        required: true,
    },
})

const store = useStore()

const checkPermission = computed(() => store.getters.checkPermission)

const platformId = computed(() => props.platform)

const [trigger, container] = usePopper({
    placement: 'bottom-start',
    strategy: 'absolute',
    modifiers: [
        {
            name: 'preventOverflow',
            options: {
                padding: 16,
            },
        },
    ],
})
const selectedSavedSearch = ref(undefined)

const selectedOrganization = useOrganization()

const savedSearches = useQuery({
    queryKey: ['target-group-by-organization-platform', selectedOrganization.value.id, platformId],
    queryFn: async () => {
        const search = await axios.get(`/targeting/organization/${selectedOrganization.value.id}`)
        return {
            platformGroups: search.data.payload.groups.filter((group) => group.platform === platformId.value),
            codes: search.data.payload.codes,
        }
    },
    enabled: Boolean(selectedOrganization.value),
})

const emit = defineEmits(['change'])

const targetingGroupsAlphabetically = computed(() => orderBy(props.targetingGroups, ['title'], ['asc']))

const onSelect = (group) => {
    emit('change', group, savedSearches.data.value?.codes)
}
</script>
