<template>
    <div>
        <ModalBlankWide
            id="clicks-modal"
            :modal-open="state.modals.clicksList.is_open"
            @close-modal="state.modals.clicksList.is_open = false"
        >
            <div class="relative">
                <div v-if="platformId === 'twitch'">
                    <!-- Clickthru chart -->
                </div>

                <table class="w-full table-auto">
                    <!-- Table header -->
                    <thead class="rounded-sm bg-slate-50 text-xs uppercase text-slate-400">
                        <tr>
                            <!-- <th class="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-1/5">
                            <div class="font-semibold text-left">Identifier</div>
                        </th> -->
                            <th class="w-1/3 whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5">
                                <div class="flex text-left">
                                    <span class="mr-2 font-semibold">Referrer</span>
                                </div>
                            </th>
                            <th class="w-1/3 whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5">
                                <div class="flex text-left">
                                    <span class="mr-2 font-semibold">Location</span>
                                </div>
                            </th>

                            <th class="w-1/4 whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5">
                                <div class="text-left font-semibold">Hits</div>
                            </th>
                            <th class="w-1/4 whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5">
                                <div class="flex text-left">
                                    <span class="mr-2 font-semibold">Time</span>
                                </div>
                            </th>
                        </tr>
                    </thead>

                    <!-- Table body -->
                    <tbody class="divide-y divide-slate-200 border-b border-slate-200 text-sm">
                        <tr v-if="!state.modals.clicksList.clicks || !state.modals.clicksList.clicks.length">
                            <td class="px-5 py-3 text-center" colspan="10">
                                <p>There are no clicks to display</p>
                            </td>
                        </tr>

                        <!-- Real table -->
                        <tr v-for="clickthrough in state.modals.clicksList.clicks" v-else :key="clickthrough.referrer">
                            <!-- <td class="px-2 first:pl-5 last:pr-5 py-3.5 whitespace-nowrap md:w-1/3 flex">
                            {{ clickthrough.fingerprint }}
                        </td> -->

                            <td class="whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5 md:w-1/6">
                                <div class="text-left">
                                    {{ clickthrough.referrer }}
                                </div>
                            </td>

                            <td class="whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5 md:w-1/6">
                                <div class="text-left">
                                    {{ getLocation(clickthrough.geolocation) }}
                                </div>
                            </td>

                            <td class="whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5 md:w-1/6">
                                <div class="text-left">
                                    {{ clickthrough.hits }}
                                </div>
                            </td>

                            <td class="whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5 md:w-1/6">
                                <div class="text-left">
                                    {{ $moment.utc(clickthrough.created_at).format('MM/DD/YYYY hh:mm A') }}
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <div class="flex justify-between px-5 py-3">
                    <div>
                        <button
                            :disabled="state.modals.clicksList.paging.hasPrevious === false"
                            class="btn text-white enabled:bg-indigo-500 enabled:hover:bg-indigo-600 disabled:border-slate-200 disabled:bg-slate-100 disabled:text-slate-400"
                            @click="changePage('clicksList', 'previous')"
                        >
                            <font-awesome-icon :icon="['fas', 'arrow-left']" class="mr-2 h-3 w-3" />
                            <span>Previous</span>
                        </button>
                    </div>
                    <div>
                        <button
                            :disabled="state.modals.clicksList.paging.hasNext === false"
                            class="btn text-white enabled:bg-indigo-500 enabled:hover:bg-indigo-600 disabled:border-slate-200 disabled:bg-slate-100 disabled:text-slate-400"
                            @click="changePage('clicksList', 'next')"
                        >
                            <span>Next</span>
                            <font-awesome-icon :icon="['fas', 'arrow-right']" class="ml-2 h-3 w-3" />
                        </button>
                    </div>
                </div>
            </div>
        </ModalBlankWide>

        <ModalBlankWide
            id="mentions-modal"
            :modal-open="state.modals.mentionsList.is_open"
            @close-modal="state.modals.mentionsList.is_open = false"
        >
            <div class="relative">
                <table class="w-full table-auto">
                    <!-- Table header -->
                    <thead class="rounded-sm bg-slate-50 text-xs uppercase text-slate-400">
                        <tr>
                            <th class="w-1/6 whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5">
                                <div class="flex text-left">
                                    <span class="mr-2 font-semibold">Time Offset</span>
                                </div>
                            </th>
                            <th class="w-1/3 whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5">
                                <div class="flex text-left">
                                    <span class="mr-2 font-semibold">What they said</span>
                                </div>
                            </th>
                            <th class="w-1/4 whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5">
                                <div class="text-left font-semibold">Link</div>
                            </th>
                        </tr>
                    </thead>

                    <!-- Table body -->
                    <tbody class="divide-y divide-slate-200 border-b border-slate-200 text-sm">
                        <tr v-if="!state.modals.mentionsList.mentions || !state.modals.mentionsList.mentions.length">
                            <td class="px-5 py-3 text-center" colspan="10">
                                <p>There are no mentions to display</p>
                            </td>
                        </tr>

                        <!-- Real table -->
                        <tr v-for="mention in filteredMentions" v-else :key="mention.link">
                            <td class="w-1/6 whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5">
                                <div class="text-left">
                                    {{
                                        $filters.humanizeDurationShort(Math.round(mention.captions.start / 1000) * 1000)
                                    }}
                                </div>
                            </td>
                            <td class="whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5">
                                <div class="text-left">
                                    {{ mention.captions.text }}
                                </div>
                            </td>
                            <td class="whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5 md:w-1/6">
                                <div class="text-left">
                                    <a class="font-medium text-indigo-500" :href="mention.link" target="blank">{{
                                        mention.link
                                    }}</a>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <div class="flex justify-between px-5 py-3">
                    <div>
                        <button
                            :disabled="state.modals.mentionsList.paging.currentPage === 0"
                            class="btn text-white enabled:bg-indigo-500 enabled:hover:bg-indigo-600 disabled:border-slate-200 disabled:bg-slate-100 disabled:text-slate-400"
                            @click="changePage('mentionsList', 'previous')"
                        >
                            <font-awesome-icon :icon="['fas', 'arrow-left']" class="mr-2 h-3 w-3" />
                            <span>Previous</span>
                        </button>
                    </div>
                    <div>
                        <button
                            :disabled="
                                state.modals.mentionsList.paging.currentPage + 1 >=
                                state.modals.mentionsList.paging.totalPages
                            "
                            class="btn text-white enabled:bg-indigo-500 enabled:hover:bg-indigo-600 disabled:border-slate-200 disabled:bg-slate-100 disabled:text-slate-400"
                            @click="changePage('mentionsList', 'next')"
                        >
                            <span>Next</span>
                            <font-awesome-icon :icon="['fas', 'arrow-right']" class="ml-2 h-3 w-3" />
                        </button>
                    </div>
                </div>
            </div>
        </ModalBlankWide>

        <div>
            <AggregateMetricsCard
                v-if="state.isLoaded && metrics"
                :campaign="campaign"
                :metrics="metrics"
                :supercampaign="supercampaign"
                :platform-id="platformId"
                :disabled-columns="state?.tables?.creators?.columns?.disabledColumns"
            />
            <div
                v-else
                class="relative col-span-full mb-8 h-[126px] overflow-hidden rounded-sm bg-slate-200 p-4 sm:p-6"
            />
        </div>

        <div class="col-span-full rounded-sm border border-slate-200 bg-white">
            <header class="border-b border-slate-100 px-5 py-4">
                <div class="sm:flex sm:items-center sm:justify-between">
                    <!-- Left: Title -->
                    <div class="mb-4 sm:mb-0">
                        <!-- <h2 class="font-semibold text-slate-800">Campaign Report</h2> -->

                        <div class="flex items-center justify-center">
                            <div
                                class="inline-flex rounded bg-slate-200 p-1 shadow-sm hover:shadow-sm focus:shadow-sm"
                                role="group"
                            >
                                <button
                                    class="flex rounded px-5 py-2.5 text-sm font-bold leading-tight text-slate-900 transition duration-150 ease-in-out focus:outline-none focus:ring-0"
                                    :class="{
                                        'bg-white': platformId === 'twitch',
                                    }"
                                    @click.prevent="onPlatformClick('twitch')"
                                >
                                    <font-awesome-icon class="mr-2 h-4 w-4 text-twitch" :icon="['fab', 'twitch']" />
                                    <span>Twitch</span>
                                </button>
                                <button
                                    class="flex rounded px-5 py-2.5 text-sm font-bold leading-tight text-slate-900 transition duration-150 ease-in-out focus:outline-none focus:ring-0"
                                    :class="{
                                        'bg-white': platformId === 'youtube',
                                    }"
                                    @click.prevent="onPlatformClick('youtube')"
                                >
                                    <font-awesome-icon class="mr-2 h-4 w-4 text-youtube" :icon="['fab', 'youtube']" />
                                    <span>YouTube</span>
                                </button>
                            </div>
                        </div>
                    </div>

                    <!-- Right: Actions  -->
                    <div class="grid grid-flow-col justify-start gap-2 sm:auto-cols-max sm:justify-end">
                        <!-- Search form -->
                        <div class="hidden sm:block">
                            <form class="relative" @submit.prevent>
                                <label for="action-search" class="sr-only">Search</label>
                                <input
                                    id="action-search"
                                    v-model="state.tables.creators.searchText"
                                    class="form-input pl-9 focus:border-slate-300"
                                    type="search"
                                    placeholder="Search..."
                                    @input="onSearch"
                                />
                                <div class="group absolute inset-0 right-auto" aria-label="Search">
                                    <svg
                                        class="ml-3 mr-2 mt-2.5 h-4 w-4 shrink-0 fill-current text-slate-400 group-hover:text-slate-500"
                                        viewBox="0 0 16 16"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M7 14c-3.86 0-7-3.14-7-7s3.14-7 7-7 7 3.14 7 7-3.14 7-7 7zM7 2C4.243 2 2 4.243 2 7s2.243 5 5 5 5-2.243 5-5-2.243-5-5-5z"
                                        />
                                        <path
                                            d="M15.707 14.293L13.314 11.9a8.019 8.019 0 01-1.414 1.414l2.393 2.393a.997.997 0 001.414 0 .999.999 0 000-1.414z"
                                        />
                                    </svg>
                                </div>
                            </form>
                        </div>

                        <!-- Export button -->
                        <button class="btn bg-indigo-500 text-white hover:bg-indigo-600" @click="exportReport">
                            Export Report
                        </button>
                    </div>
                </div>
            </header>

            <div class="p-3">
                <!-- Table -->
                <div class="overflow-x-auto">
                    <table class="w-full table-auto">
                        <!-- Table header -->
                        <thead class="rounded-sm bg-slate-50 text-xs uppercase text-slate-400">
                            <tr v-if="platformId === 'twitch'">
                                <th
                                    v-if="isColumnEnabled('data.creator.platform_user_name')"
                                    class="w-1/4 cursor-pointer whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5"
                                    @click="setTableSort('data.creator.platform_user_name')"
                                >
                                    <div class="text-left font-semibold">Creator</div>
                                </th>
                                <th
                                    v-if="isColumnEnabled('data.summary.avg_viewers')"
                                    class="last:pr-5cursor-pointer whitespace-nowrap px-2 py-3 first:pl-5"
                                    @click="setTableSort('data.summary.avg_viewers')"
                                >
                                    <div class="text-left font-semibold">Stream<br />ACV</div>
                                </th>
                                <th
                                    v-if="isColumnEnabled('data.summary.acv_performance')"
                                    class="cursor-pointer whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5"
                                    @click="setTableSort('data.summary.acv_performance')"
                                >
                                    <div class="flex text-left">
                                        <span class="mr-2 font-semibold">ACV Perf</span>

                                        <Tooltip>
                                            <template #trigger>
                                                <font-awesome-icon
                                                    class="h-3.5 w-3.5 hover:text-slate-500"
                                                    :icon="['fas', 'circle-info']"
                                                />
                                            </template>
                                            <template #default>
                                                <div class="p-3 font-bold">
                                                    <h5 class="text-xl text-slate-200">ACV Performance</h5>
                                                    <p class="mb-3 text-xs text-slate-300">
                                                        How the creator performed vs. how we expected them to perform
                                                        based on historical stats.
                                                    </p>
                                                    <p class="text-xs italic text-slate-300">
                                                        We compare their stream ACV against the average viewers a
                                                        creator gets when they switch away from their main game, not
                                                        their raw 90 day average.
                                                    </p>
                                                </div>
                                            </template>
                                        </Tooltip>
                                    </div>
                                </th>
                                <th
                                    v-if="isColumnEnabled('data.summary.unique_clicks')"
                                    class="cursor-pointer whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5"
                                    @click="setTableSort('data.summary.unique_clicks')"
                                >
                                    <div class="text-left font-semibold">Unique<br />Clicks</div>
                                </th>

                                <th
                                    v-if="state.has_utm_data && isColumnEnabled('data.utm_data.wishlists')"
                                    class="cursor-pointer whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5"
                                    @click="setTableSort('data.utm_data.wishlists')"
                                >
                                    <div class="flex items-center text-left">
                                        <span class="mr-2 font-semibold">Tracked<br />Wishlists</span>

                                        <Tooltip>
                                            <template #trigger>
                                                <font-awesome-icon
                                                    class="h-3.5 w-3.5 hover:text-slate-500"
                                                    :icon="['fas', 'circle-info']"
                                                />
                                            </template>
                                            <template #default>
                                                <div class="p-3 font-bold">
                                                    <h5 class="text-xl text-slate-200">Steam UTM Wishlists</h5>
                                                    <p class="mb-3 text-xs text-slate-300">
                                                        The amount of viewers that entered your Steam page, logged into
                                                        their account, and wishlisted your game.
                                                    </p>
                                                    <p class="mb-3 text-xs italic text-slate-300">
                                                        Steam UTM tracking is a beta feature and does not currently
                                                        present a full picture of conversion.
                                                    </p>
                                                    <p class="text-xs italic text-slate-300">
                                                        To learn more about how it works see
                                                        <a
                                                            class="text-indigo-400 hover:text-indigo-300"
                                                            href="https://partner.steamgames.com/doc/marketing/utm_analytics#tracked_visits"
                                                            target="_BLANK"
                                                            >Steam's page on the subject</a
                                                        >.
                                                    </p>
                                                </div>
                                            </template>
                                        </Tooltip>
                                    </div>
                                </th>

                                <th
                                    v-if="state.has_utm_data && isColumnEnabled('data.utm_data.purchases')"
                                    class="cursor-pointer whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5"
                                    @click="setTableSort('data.utm_data.purchases')"
                                >
                                    <div class="flex items-center text-left">
                                        <span class="mr-2 font-semibold">Tracked<br />Purchases</span>

                                        <Tooltip>
                                            <template #trigger>
                                                <font-awesome-icon
                                                    class="h-3.5 w-3.5 hover:text-slate-500"
                                                    :icon="['fas', 'circle-info']"
                                                />
                                            </template>
                                            <template #default>
                                                <div class="p-3 font-bold">
                                                    <h5 class="text-xl text-slate-200">Steam UTM Purchases</h5>
                                                    <p class="mb-3 text-xs text-slate-300">
                                                        The amount of viewers that entered your Steam page, logged into
                                                        their account, and purchased your game.
                                                    </p>
                                                    <p class="mb-3 text-xs italic text-slate-300">
                                                        Steam UTM tracking is a beta feature and does not currently
                                                        present a full picture of conversion.
                                                    </p>
                                                    <p class="text-xs italic text-slate-300">
                                                        To learn more about how it works see
                                                        <a
                                                            class="text-indigo-400 hover:text-indigo-300"
                                                            href="https://partner.steamgames.com/doc/marketing/utm_analytics#tracked_visits"
                                                            target="_BLANK"
                                                            >Steam's page on the subject</a
                                                        >.
                                                    </p>
                                                </div>
                                            </template>
                                        </Tooltip>
                                    </div>
                                </th>

                                <th
                                    v-if="isColumnEnabled('data.summary.cpc')"
                                    class="cursor-pointer whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5"
                                    @click="setTableSort('data.summary.cpc')"
                                >
                                    <div class="text-left font-semibold">CPC</div>
                                </th>

                                <th
                                    v-if="isColumnEnabled('data.summary.cost')"
                                    class="cursor-pointer whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5"
                                    @click="setTableSort('data.summary.cost')"
                                >
                                    <div class="text-left font-semibold">
                                        {{ campaign.type === 'key-distribution' ? 'Est. Market Rate' : 'Spend' }}
                                    </div>
                                </th>

                                <th
                                    v-if="isColumnEnabled('data.summary.content_value')"
                                    class="cursor-pointer whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5"
                                    @click="setTableSort('data.summary.content_value')"
                                >
                                    <div class="text-left font-semibold">Content<br />Value</div>
                                </th>

                                <th
                                    v-if="isColumnEnabled('data.summary.chapters_count')"
                                    class="cursor-pointer whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5"
                                    @click="setTableSort('data.summary.chapters_count')"
                                >
                                    <div class="text-left font-semibold">Stream<br />Count</div>
                                </th>

                                <th
                                    v-if="isColumnEnabled('data.summary.target_duration_seconds')"
                                    class="cursor-pointer whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5"
                                    @click="setTableSort('data.summary.target_duration_seconds')"
                                >
                                    <div v-if="campaign.type === 'game-sponsorship'" class="text-left font-semibold">
                                        Hours<br />Hired
                                    </div>
                                    <div v-if="campaign.type === 'key-distribution'" class="text-left font-semibold">
                                        Expected<br />Hours
                                    </div>
                                </th>
                                <th
                                    v-if="isColumnEnabled('data.summary.sum_duration_seconds')"
                                    class="cursor-pointer whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5"
                                    @click="setTableSort('data.summary.sum_duration_seconds')"
                                >
                                    <div class="text-left font-semibold">Hours<br />Streamed</div>
                                </th>
                                <th
                                    v-if="isColumnEnabled('data.summary.over_delivery')"
                                    class="cursor-pointer whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5"
                                    @click="setTableSort('data.summary.over_delivery')"
                                >
                                    <div class="flex text-left">
                                        <span class="mr-2 font-semibold">Over<br />Delivery</span>

                                        <Tooltip>
                                            <template #trigger>
                                                <font-awesome-icon
                                                    class="h-3.5 w-3.5 hover:text-slate-500"
                                                    :icon="['fas', 'circle-info']"
                                                />
                                            </template>
                                            <template #default>
                                                <div class="p-3 font-bold">
                                                    <h5 class="text-xl text-slate-200">Over Delivery</h5>
                                                    <p class="text-xs text-slate-300">
                                                        This is how much more the creator streamed your game than they
                                                        were hired for.
                                                    </p>
                                                </div>
                                            </template>
                                        </Tooltip>
                                    </div>
                                </th>
                                <th
                                    v-if="isColumnEnabled('data.mentions.count')"
                                    class="whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5"
                                >
                                    <div class="flex text-left">
                                        <span class="mr-2 font-semibold">Mentions</span>
                                    </div>
                                </th>
                                <template v-for="keyword in keywords" :key="keyword">
                                    <th
                                        v-if="isColumnEnabled('data.mentions.count')"
                                        class="whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5"
                                    >
                                        <div class="flex text-left">
                                            <span class="mr-2 font-semibold">Mention:<br />"{{ keyword }}"</span>
                                        </div>
                                    </th>
                                </template>
                            </tr>

                            <tr v-else-if="platformId === 'youtube'">
                                <th
                                    v-if="isColumnEnabled('data.creator.platform_user_name')"
                                    class="cursor-pointer whitespace-nowrap px-2 py-3.5 first:pl-5 last:pr-5"
                                    @click="setTableSort('data.creator.platform_user_name')"
                                >
                                    <div class="text-left font-semibold">Creator</div>
                                </th>
                                <th
                                    v-if="isColumnEnabled('data.summary.sum_views')"
                                    class="cursor-pointer whitespace-nowrap px-2 py-3.5 first:pl-5 last:pr-5"
                                    @click="setTableSort('data.summary.sum_views')"
                                >
                                    <div class="text-left font-semibold">Video<br />Views</div>
                                </th>
                                <th
                                    v-if="isColumnEnabled('data.summary.acv_performance')"
                                    class="cursor-pointer whitespace-nowrap px-2 py-3.5 first:pl-5 last:pr-5"
                                    @click="setTableSort('data.summary.acv_performance')"
                                >
                                    <div class="text-left font-semibold">Views Performance</div>
                                </th>
                                <th
                                    v-if="isColumnEnabled('data.summary.sum_engagements')"
                                    class="cursor-pointer whitespace-nowrap px-2 py-3.5 first:pl-5 last:pr-5"
                                    @click="setTableSort('data.summary.sum_engagements')"
                                >
                                    <div class="text-left font-semibold">Engagements</div>
                                </th>

                                <th
                                    v-if="isColumnEnabled('data.summary.unique_clicks')"
                                    class="cursor-pointer whitespace-nowrap px-2 py-3.5 first:pl-5 last:pr-5"
                                    @click="setTableSort('data.summary.unique_clicks')"
                                >
                                    <div class="text-left font-semibold">Unique<br />Clicks</div>
                                </th>

                                <th
                                    v-if="isColumnEnabled('data.utm_data.wishlists')"
                                    class="cursor-pointer whitespace-nowrap px-2 py-3.5 first:pl-5 last:pr-5"
                                    @click="setTableSort('data.utm_data.wishlists')"
                                >
                                    <div class="flex items-center text-left">
                                        <span class="mr-2 font-semibold">Tracked<br />Wishlists</span>

                                        <Tooltip>
                                            <template #trigger>
                                                <font-awesome-icon
                                                    class="h-3.5 w-3.5 hover:text-slate-500"
                                                    :icon="['fas', 'circle-info']"
                                                />
                                            </template>
                                            <template #default>
                                                <div class="p-3 font-bold">
                                                    <h5 class="text-xl text-slate-200">Tracked Steam UTM Wishlists</h5>
                                                    <p class="mb-3 text-xs text-slate-300">
                                                        The amount of viewers that entered your Steam page, logged into
                                                        their account, and wishlisted your game.
                                                    </p>
                                                    <p class="mb-3 text-xs italic text-slate-300">
                                                        Steam UTM tracking is a beta feature and does not currently
                                                        present a full picture of conversion.
                                                    </p>
                                                    <p class="text-xs italic text-slate-300">
                                                        To learn more about how it works see
                                                        <a
                                                            class="text-indigo-400 hover:text-indigo-300"
                                                            href="https://partner.steamgames.com/doc/marketing/utm_analytics#tracked_visits"
                                                            target="_BLANK"
                                                            >Steam's page on the subject</a
                                                        >.
                                                    </p>
                                                </div>
                                            </template>
                                        </Tooltip>
                                    </div>
                                </th>

                                <th
                                    v-if="isColumnEnabled('data.utm_data.purchases')"
                                    class="cursor-pointer whitespace-nowrap px-2 py-3.5 first:pl-5 last:pr-5"
                                    @click="setTableSort('data.utm_data.purchases')"
                                >
                                    <div class="flex items-center text-left">
                                        <span class="mr-2 font-semibold">Tracked<br />Purchases</span>

                                        <Tooltip>
                                            <template #trigger>
                                                <font-awesome-icon
                                                    class="h-3.5 w-3.5 hover:text-slate-500"
                                                    :icon="['fas', 'circle-info']"
                                                />
                                            </template>
                                            <template #default>
                                                <div class="p-3 font-bold">
                                                    <h5 class="text-xl text-slate-200">Steam UTM Purchases</h5>
                                                    <p class="mb-3 text-xs text-slate-300">
                                                        The amount of viewers that entered your Steam page, logged into
                                                        their account, and purchased your game.
                                                    </p>
                                                    <p class="mb-3 text-xs italic text-slate-300">
                                                        Steam UTM tracking is a beta feature and does not currently
                                                        present a full picture of conversion.
                                                    </p>
                                                    <p class="text-xs italic text-slate-300">
                                                        To learn more about how it works see
                                                        <a
                                                            class="text-indigo-400 hover:text-indigo-300"
                                                            href="https://partner.steamgames.com/doc/marketing/utm_analytics#tracked_visits"
                                                            target="_BLANK"
                                                            >Steam's page on the subject</a
                                                        >.
                                                    </p>
                                                </div>
                                            </template>
                                        </Tooltip>
                                    </div>
                                </th>

                                <th
                                    v-if="isColumnEnabled('data.summary.cpc')"
                                    class="cursor-pointer whitespace-nowrap px-2 py-3.5 first:pl-5 last:pr-5"
                                    @click="setTableSort('data.summary.cpc')"
                                >
                                    <div class="text-left font-semibold">CPC</div>
                                </th>

                                <th
                                    v-if="isColumnEnabled('data.summary.cpm')"
                                    class="cursor-pointer whitespace-nowrap px-2 py-3.5 first:pl-5 last:pr-5"
                                    @click="setTableSort('data.summary.cpm')"
                                >
                                    <div class="text-left font-semibold">CPM</div>
                                </th>

                                <th
                                    v-if="isColumnEnabled('data.summary.cost')"
                                    class="cursor-pointer whitespace-nowrap px-2 py-3.5 first:pl-5 last:pr-5"
                                    @click="setTableSort('data.summary.cost')"
                                >
                                    <div class="text-left font-semibold">
                                        {{ campaign.type === 'key-distribution' ? 'Est. Market Rate' : 'Spend' }}
                                    </div>
                                </th>

                                <th
                                    v-if="isColumnEnabled('data.summary.over_delivery')"
                                    class="cursor-pointer whitespace-nowrap px-2 py-3.5 first:pl-5 last:pr-5"
                                    @click="setTableSort('data.summary.over_delivery')"
                                >
                                    <div class="text-left font-semibold">Over<br />Delivery</div>
                                </th>
                            </tr>
                        </thead>

                        <!-- Table body -->
                        <tbody class="divide-y divide-slate-100 text-sm">
                            <SkeletonReportSubpage v-if="!state.isLoaded" />

                            <!-- vue/no-use-v-if-with-v-for -->
                            <tr
                                v-for="reportCreator in filteredCreators"
                                v-else-if="platformId === 'twitch'"
                                :key="reportCreator.creator.platform_user_id"
                                class="group"
                            >
                                <td
                                    v-if="isColumnEnabled('data.creator.platform_user_name')"
                                    class="whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5"
                                >
                                    <div class="relative flex w-full items-center justify-between">
                                        <NamePlate
                                            class="my-auto"
                                            :logo="reportCreator.creator.platform_user_logo"
                                            :name="reportCreator.creator.platform_user_name"
                                            :display-name="reportCreator.creator.platform_user_name"
                                            :platform-id="reportCreator.creator.platform_id"
                                            :platform-user-id="reportCreator.creator.platform_user_id"
                                            :is-drawer-enabled="true"
                                        />

                                        <div class="h-[26px] w-20">
                                            <button
                                                class="right-0 top-0 my-auto hidden h-[26px] cursor-pointer rounded bg-slate-100 px-2 text-xs font-semibold transition duration-200 group-hover:flex hover:bg-slate-200"
                                                :class="
                                                    !reportCreator.chapters?.some(
                                                        (chapter) => chapter?.archivedVideos?.length > 0,
                                                    )
                                                        ? 'pointer-events-none bg-slate-50 text-slate-400'
                                                        : ''
                                                "
                                                @click="
                                                    $store.commit('modal:setData', {
                                                        modal_id: 'creator_ask_ai',
                                                        data: {
                                                            is_open: true,
                                                            supercampaign_id: supercampaign.id,
                                                            campaign_id: campaign.id,
                                                            creator: creator,
                                                        },
                                                    })
                                                "
                                            >
                                                <span class="my-auto flex uppercase">
                                                    <font-awesome-icon
                                                        :icon="['fas', 'wand-magic-sparkles']"
                                                        class="my-auto mr-1 h-4 w-4"
                                                    />
                                                    <span class="my-auto leading-none">Ask AI</span>
                                                </span>
                                            </button>
                                        </div>
                                    </div>
                                </td>
                                <td
                                    v-if="isColumnEnabled('data.summary.avg_viewers')"
                                    class="whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5"
                                >
                                    <div v-if="reportCreator.summary.avg_viewers" class="text-left">
                                        {{ $filters.numeral(reportCreator.summary.avg_viewers).format('0,0') }}
                                    </div>
                                    <div v-else class="text-left">-</div>
                                </td>
                                <td
                                    v-if="isColumnEnabled('data.summary.acv_performance')"
                                    class="whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5"
                                >
                                    <div v-if="reportCreator.summary.acv_performance" class="flex flex-col pr-5">
                                        <span>{{
                                            $filters.numeral(reportCreator.summary.acv_performance).format('+0,0%')
                                        }}</span>

                                        <div
                                            class="mt-1 flex h-1 w-full rounded-full bg-slate-100"
                                            :class="{
                                                'justify-end': reportCreator.summary.acv_performance < 0,
                                            }"
                                        >
                                            <div
                                                class="h-1 rounded-full"
                                                :style="{
                                                    width: `${Math.abs(reportCreator.summary.acv_performance * 100)}%`,
                                                }"
                                                :class="{
                                                    'bg-emerald-500': reportCreator.summary.acv_performance >= 0,
                                                    'bg-rose-500': reportCreator.summary.acv_performance < 0,
                                                }"
                                            />
                                        </div>
                                    </div>
                                    <div v-else class="text-left">-</div>
                                </td>
                                <td
                                    v-if="isColumnEnabled('data.summary.unique_clicks')"
                                    class="whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5"
                                >
                                    <div
                                        v-if="isSfStaff && reportCreator.summary.unique_clicks"
                                        class="inline-block text-left"
                                    >
                                        <div
                                            class="cursor-pointer rounded bg-slate-100 px-2 py-1 text-slate-500 hover:bg-slate-200 hover:text-slate-600"
                                            @click="openModal('clicksList', creator)"
                                        >
                                            {{ $filters.numeral(reportCreator.summary.unique_clicks).format('0,0') }}
                                        </div>
                                    </div>
                                    <div v-else-if="reportCreator.summary.unique_clicks" class="inline-block text-left">
                                        {{ $filters.numeral(reportCreator.summary.unique_clicks).format('0,0') }}
                                    </div>
                                    <div v-else class="text-left">-</div>
                                </td>

                                <td
                                    v-if="isColumnEnabled('data.utm_data.wishlists')"
                                    class="whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5"
                                >
                                    <span v-if="reportCreator?.utm_data?.wishlists">
                                        {{ $filters.numeral(reportCreator.utm_data.wishlists).format('0,0') }}
                                    </span>
                                    <span v-else>-</span>
                                </td>

                                <td
                                    v-if="isColumnEnabled('data.utm_data.purchases')"
                                    class="whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5"
                                >
                                    <span v-if="reportCreator?.utm_data?.purchases">
                                        {{ $filters.numeral(reportCreator.utm_data.purchases).format('0,0') }}
                                    </span>
                                    <span v-else>-</span>
                                </td>

                                <td
                                    v-if="isColumnEnabled('data.summary.cpc')"
                                    class="whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5"
                                >
                                    <div v-if="reportCreator.summary.cpc" class="text-left">
                                        ${{ $filters.numeral(reportCreator.summary.cpc).format('0,0.00') }}
                                    </div>
                                    <div v-else class="text-left">-</div>
                                </td>

                                <td
                                    v-if="isColumnEnabled('data.summary.cost')"
                                    class="whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5"
                                >
                                    <div v-if="reportCreator.summary.cost" class="text-left">
                                        ${{ $filters.numeral(reportCreator.summary.cost).format('0,0') }}
                                    </div>
                                    <div v-else class="text-left">-</div>
                                </td>

                                <td
                                    v-if="isColumnEnabled('data.summary.content_value')"
                                    class="whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5"
                                >
                                    <div v-if="reportCreator.summary.content_value" class="text-left">
                                        ${{ $filters.numeral(reportCreator.summary.content_value).format('0,0') }}
                                    </div>
                                    <div v-else class="text-left">-</div>
                                </td>

                                <td
                                    v-if="isColumnEnabled('data.summary.chapters_count')"
                                    class="whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5"
                                >
                                    <div v-if="reportCreator.summary.chapters_count" class="text-left">
                                        {{ $filters.numeral(reportCreator.summary.chapters_count).format('0,0') }}
                                    </div>
                                    <div v-else class="text-left">-</div>
                                </td>

                                <td
                                    v-if="isColumnEnabled('data.summary.target_duration_seconds')"
                                    class="whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5"
                                >
                                    <div v-if="reportCreator.summary.target_duration_seconds" class="text-left">
                                        {{
                                            $filters.humanizeDurationShort(
                                                reportCreator.summary.target_duration_seconds * 1000,
                                            )
                                        }}
                                    </div>
                                    <div v-else class="text-left">-</div>
                                </td>
                                <td
                                    v-if="isColumnEnabled('data.summary.sum_duration_seconds')"
                                    class="whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5"
                                >
                                    <div v-if="reportCreator.summary.sum_duration_seconds" class="text-left">
                                        {{
                                            $filters.humanizeDurationShort(
                                                reportCreator.summary.sum_duration_seconds * 1000,
                                            )
                                        }}
                                    </div>
                                    <div v-else class="text-left">-</div>
                                </td>
                                <td
                                    v-if="isColumnEnabled('data.summary.over_delivery')"
                                    class="whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5"
                                >
                                    <div
                                        v-if="
                                            reportCreator.summary.over_delivery &&
                                            reportCreator.summary.over_delivery > 0
                                        "
                                        class="flex flex-col"
                                    >
                                        <span>{{
                                            $filters.numeral(reportCreator.summary.over_delivery + 1).format('0,0%')
                                        }}</span>

                                        <div class="mt-1 flex h-1 w-1/2 rounded-full bg-slate-100">
                                            <div
                                                class="h-1 rounded-full bg-emerald-500"
                                                :style="{
                                                    width: `${Math.abs(reportCreator.summary.over_delivery * 100)}%`,
                                                }"
                                            />
                                        </div>
                                    </div>
                                    <div v-else class="text-left">-</div>
                                </td>
                                <td
                                    v-if="isColumnEnabled('data.mentions.count')"
                                    class="whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5"
                                >
                                    <div
                                        v-if="reportCreator.mentions.count && reportCreator.mentions.count > 0"
                                        class="inline-block text-left"
                                    >
                                        <div
                                            class="cursor-pointer rounded bg-slate-100 px-2 py-1 text-slate-500 hover:bg-slate-200 hover:text-slate-600"
                                            @click="openModal('mentionsList', creator)"
                                        >
                                            {{ $filters.numeral(reportCreator.mentions.count).format('0,0') }}
                                        </div>
                                    </div>
                                    <div v-else class="text-left">-</div>
                                </td>
                                <template v-for="keyword in keywords" :key="keyword">
                                    <td
                                        v-if="isColumnEnabled('data.mentions.count')"
                                        class="whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5"
                                    >
                                        <div class="text-left">
                                            {{ reportCreator.mentions.by_keyword[keyword] }}
                                        </div>
                                    </td>
                                </template>
                            </tr>

                            <!-- eslint-disable vue/no-use-v-if-with-v-for -->
                            <tr
                                v-for="reportCreator in filteredCreators"
                                v-else-if="platformId === 'youtube'"
                                :key="reportCreator.platform_user_id"
                                class="group"
                            >
                                <!-- eslint-enable vue/no-use-v-if-with-v-for -->
                                <td
                                    v-if="isColumnEnabled('data.creator.platform_user_name')"
                                    class="whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5"
                                >
                                    <div class="relative flex w-full items-center justify-between">
                                        <NamePlate
                                            class="my-auto"
                                            :logo="reportCreator.creator.platform_user_logo"
                                            :display-name="reportCreator.creator.platform_user_name"
                                            :platform-id="reportCreator.creator.platform_id"
                                            :platform-user-id="reportCreator.creator.platform_user_id"
                                            :is-drawer-enabled="true"
                                        />

                                        <div class="h-[26px] w-20">
                                            <button
                                                class="right-0 top-0 my-auto hidden h-[26px] cursor-pointer rounded bg-slate-100 px-2 text-xs font-semibold transition duration-200 group-hover:flex hover:bg-slate-200"
                                                :class="
                                                    !reportCreator.deliverables?.some(
                                                        (deliverable) => deliverable?.archivedVideos?.length > 0,
                                                    )
                                                        ? 'pointer-events-none bg-slate-50 text-slate-400'
                                                        : ''
                                                "
                                                @click="
                                                    $store.commit('modal:setData', {
                                                        modal_id: 'creator_ask_ai',
                                                        data: {
                                                            is_open: true,
                                                            supercampaign_id: supercampaign.id,
                                                            campaign_id: campaign.id,
                                                            creator: creator,
                                                        },
                                                    })
                                                "
                                            >
                                                <span class="my-auto flex uppercase">
                                                    <font-awesome-icon
                                                        :icon="['fas', 'wand-magic-sparkles']"
                                                        class="my-auto mr-1 h-4 w-4"
                                                    />
                                                    <span class="my-auto leading-none">Ask AI</span>
                                                </span>
                                            </button>
                                        </div>
                                    </div>
                                </td>
                                <td
                                    v-if="isColumnEnabled('data.summary.sum_views')"
                                    class="whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5"
                                >
                                    <div v-if="reportCreator.summary.sum_views" class="text-left">
                                        {{ $filters.numeral(reportCreator.summary.sum_views).format('0,0') }}
                                    </div>
                                    <div v-else class="text-left">-</div>
                                </td>
                                <td
                                    v-if="isColumnEnabled('data.summary.acv_performance')"
                                    class="whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5"
                                >
                                    <div v-if="reportCreator.summary.acv_performance" class="flex flex-col">
                                        <span>{{
                                            $filters.numeral(reportCreator.summary.acv_performance).format('+0,0%')
                                        }}</span>

                                        <div
                                            class="mt-1 flex h-1 w-1/2 rounded-full bg-slate-100"
                                            :class="{
                                                'justify-end': reportCreator.summary.acv_performance < 0,
                                            }"
                                        >
                                            <div
                                                class="h-1 rounded-full"
                                                :style="{
                                                    width: `${Math.abs(reportCreator.summary.acv_performance * 100)}%`,
                                                }"
                                                :class="{
                                                    'bg-emerald-500': reportCreator.summary.acv_performance >= 0,
                                                    'bg-rose-500': reportCreator.summary.acv_performance < 0,
                                                }"
                                            />
                                        </div>
                                    </div>
                                    <div v-else class="text-left">-</div>
                                </td>
                                <td
                                    v-if="isColumnEnabled('data.summary.sum_engagements')"
                                    class="whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5"
                                >
                                    <div v-if="reportCreator.summary.sum_engagements" class="text-left">
                                        {{ $filters.numeral(reportCreator.summary.sum_engagements).format('0,0') }}
                                    </div>
                                    <div v-else class="text-left">-</div>
                                </td>
                                <td
                                    v-if="isColumnEnabled('data.summary.unique_clicks')"
                                    class="whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5"
                                >
                                    <div
                                        v-if="isSfStaff && reportCreator.summary.unique_clicks"
                                        class="inline-block text-left"
                                    >
                                        <div
                                            class="cursor-pointer rounded bg-slate-100 px-2 py-1 text-slate-500 hover:bg-slate-200 hover:text-slate-600"
                                            @click="openModal(creator)"
                                        >
                                            {{ $filters.numeral(reportCreator.summary.unique_clicks).format('0,0') }}
                                        </div>
                                    </div>
                                    <div v-else-if="reportCreator.summary.unique_clicks" class="inline-block text-left">
                                        {{ $filters.numeral(reportCreator.summary.unique_clicks).format('0,0') }}
                                    </div>
                                    <div v-else class="text-left">-</div>
                                </td>

                                <td
                                    v-if="isColumnEnabled('data.utm_data.wishlists')"
                                    class="whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5"
                                >
                                    <span v-if="reportCreator?.utm_data?.wishlists">
                                        {{ $filters.numeral(reportCreator.utm_data.wishlists).format('0,0') }}
                                    </span>
                                    <span v-else>-</span>
                                </td>

                                <td
                                    v-if="isColumnEnabled('data.utm_data.purchases')"
                                    class="whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5"
                                >
                                    <span v-if="reportCreator?.utm_data?.purchases">
                                        {{ $filters.numeral(reportCreator.utm_data.purchases).format('0,0') }}
                                    </span>
                                    <span v-else>-</span>
                                </td>

                                <td
                                    v-if="isColumnEnabled('data.summary.cpc')"
                                    class="whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5"
                                >
                                    <div v-if="reportCreator.summary.cpc" class="text-left">
                                        ${{ $filters.numeral(reportCreator.summary.cpc).format('0,0.00') }}
                                    </div>
                                    <div v-else class="text-left">-</div>
                                </td>
                                <td
                                    v-if="isColumnEnabled('data.summary.cpm')"
                                    class="whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5"
                                >
                                    <div v-if="reportCreator.summary.cpm" class="text-left">
                                        ${{ $filters.numeral(reportCreator.summary.cpm).format('0,0.00') }}
                                    </div>
                                    <div v-else class="text-left">-</div>
                                </td>
                                <td
                                    v-if="isColumnEnabled('data.summary.cost')"
                                    class="whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5"
                                >
                                    <div v-if="reportCreator.summary.cost" class="text-left">
                                        ${{ $filters.numeral(reportCreator.summary.cost).format('0,0') }}
                                    </div>
                                    <div v-else class="text-left">-</div>
                                </td>
                                <td
                                    v-if="isColumnEnabled('data.summary.over_delivery')"
                                    class="whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5"
                                >
                                    <div
                                        v-if="
                                            reportCreator.summary.over_delivery &&
                                            reportCreator.summary.over_delivery > 0
                                        "
                                        class="flex flex-col"
                                    >
                                        <span>{{
                                            $filters.numeral(reportCreator.summary.over_delivery + 1).format('0,0%')
                                        }}</span>

                                        <div class="mt-1 flex h-1 w-1/2 rounded-full bg-slate-100">
                                            <div
                                                class="h-1 rounded-full bg-emerald-500"
                                                :style="{
                                                    width: `${Math.abs(reportCreator.summary.over_delivery * 100)}%`,
                                                }"
                                            />
                                        </div>
                                    </div>
                                    <div v-else class="text-left">-</div>
                                </td>
                            </tr>

                            <tr v-if="!creators || !filteredCreators.length">
                                <td class="px-5 py-3 text-center" colspan="10">
                                    <p v-if="state.tables.creators.searchText">
                                        No creators found matching "{{ state.tables.creators.searchText }}"
                                    </p>
                                    <p v-else-if="campaign.type === 'game-sponsorship' && state.isLoaded">
                                        There are no approved creators for this platform
                                    </p>
                                    <p v-else-if="!state.tables.creators.searchText && state.isLoaded">
                                        There are no creators for this platform
                                    </p>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="my-4 ml-4 mr-4">
                    <DiscoveryPagination :paging="state.tables.creators.paging" @paging-changed="onPagingChange" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import _ from 'lodash'
import moment from 'moment'
import numeral from 'numeral'
import voca from 'voca'
import { json2csvAsync } from 'json-2-csv'
import { mapGetters } from 'vuex'
import AggregateMetricsCard from '@/partials/campaigns/AggregateMetricsCard.vue'
import SkeletonReportSubpage from '@/partials/skeletons/SkeletonReportSubpage.vue'
import NamePlate from '@/components/plates/NamePlate.vue'
import Tooltip from '@/components/Tooltip.vue'
import ModalBlankWide from '@/components/ModalBlankWide.vue'
import DiscoveryPagination from '@/partials/tables/discovery/DiscoveryPagination.vue'

import reportsAPI from '@/utils/api/api.reports'

export default {
    name: 'CampaignReportSubpage',

    components: {
        AggregateMetricsCard,
        SkeletonReportSubpage,
        NamePlate,
        Tooltip,
        ModalBlankWide,
        DiscoveryPagination,
    },

    props: {
        supercampaign: {
            type: Object,
            default: null,
        },
        campaignsById: {
            type: Object,
            default: null,
        },
    },

    data() {
        return {
            creators: null,
            metrics: null,

            keywords: null,

            platformId: 'twitch',

            state: {
                isLoaded: false,
                has_utm_data: false,
                has_mention_data: false,

                modals: {
                    clicksList: {
                        is_open: false,
                        participant: null,
                        participant_id: null,
                        campaign_id: null,
                        supercampaign_id: null,

                        paging: {
                            perPage: 10,
                            totalPages: 0,
                            currentPage: 1,
                            hasNext: false,
                            hasPrevious: false,
                        },

                        clicks: null,
                    },

                    mentionsList: {
                        is_open: false,
                        participant: null,
                        participant_id: null,
                        campaign_id: null,
                        supercampaign_id: null,

                        paging: {
                            perPage: 10,
                            totalPages: 0,
                            currentPage: 1,
                            hasNext: false,
                            hasPrevious: false,
                        },

                        mentions: null,
                    },
                },

                tables: {
                    creators: {
                        sort: {},
                        paging: {},
                        searchText: '',

                        columns: {
                            disabledColumns: [],

                            staffColumns: ['data.summary.acv_performance'],
                        },
                    },
                },
            },
        }
    },

    computed: {
        ...mapGetters(['getFromDictionary', 'isDevModeEnabled', 'getCountryFromCode', 'isSfStaff']),

        campaign() {
            return this.campaignsById[this.$route.params.campaign_id]
        },

        filteredCreators() {
            if (this?.state?.tables?.creators?.searchText) {
                return _.filter(
                    this.creators,
                    ({ superview }) =>
                        !!superview?.channel?.name?.includes(voca.lowerCase(this.state.tables.creators.searchText)),
                )
            }

            return this.creators
        },

        filteredMentions() {
            const perPage = this.state.modals.mentionsList.paging.perPage
            const offset = this.state.modals.mentionsList.paging.currentPage * perPage

            console.log(offset, perPage, this.state.modals.mentionsList.mentions.slice(offset, perPage))

            return this.state.modals.mentionsList.mentions.slice(offset, offset + perPage)
        },

        isSpendShown() {
            if (this.isDevModeEnabled) return true

            const startedAt = moment.utc(this.campaign.started_at)
            const cutoffDate = moment.utc('2022-10-15')

            if (startedAt.isValid() && startedAt.isAfter(cutoffDate)) {
                return true
            }

            if (this.supercampaign.payload.is_demo === true) {
                return true
            }

            return false
        },
    },

    created() {
        this.getReport(true)

        const payload = this.campaignsById?.[this.$route.params.campaign_id]?.payload

        if (payload.report_disabled_columns) {
            this.state.tables.creators.columns.disabledColumns = payload.report_disabled_columns
        }
    },

    methods: {
        isColumnEnabled(fieldName) {
            let isEnabled = true

            if (this.state.tables.creators.columns.disabledColumns.includes(fieldName)) {
                isEnabled = false
            }

            if (fieldName?.includes('utm_data') && !this.state.has_utm_data) {
                isEnabled = false
            }

            if (fieldName?.includes('mentions') && !this.state.has_mention_data) {
                isEnabled = false
            }

            if (this.state.tables.creators.columns.staffColumns.includes(fieldName) && !this.isSfStaff) {
                isEnabled = false
            }

            return isEnabled
        },

        setTableSort(field) {
            if (field === this.state.tables.creators.sort.field) {
                this.state.tables.creators.sort.direction =
                    this.state.tables.creators.sort.direction === 'asc' ? 'desc' : 'asc'
            } else {
                this.state.tables.creators.sort.field = field
                this.state.tables.creators.sort.direction = 'desc'
            }
            this.getReport(false)
        },

        changePage(modalId, direction) {
            if (direction === 'next') {
                this.state.modals[modalId].paging.currentPage += 1
            } else if (direction === 'previous') {
                // Don't go previous if at 0
                if (this.state.modals[modalId].paging.currentPage === 0) return

                this.state.modals[modalId].paging.currentPage -= 1
            }

            if (modalId === 'clicksList') {
                this.getCreatorClicks()
            }
        },

        getLocation(input) {
            return this.getCountryFromCode(input.country_code)
        },

        getCreatorClicks() {
            const modalState = this.state.modals.clicksList

            return this.$axios
                .get(
                    `/supercampaigns/${modalState.supercampaign_id}/campaigns/${modalState.campaign_id}/clickthroughs/${modalState.participant_id}`,
                    {
                        params: {
                            offset: modalState.paging.currentPage * modalState.paging.perPage,
                            limit: modalState.paging.perPage,
                            order_field: 'created_at',
                            order_direction: 'desc',
                        },
                    },
                )
                .then(({ data }) => {
                    modalState.clicks = data.payload.clickthroughs
                    modalState.paging.hasNext = data.payload.paging.hasNext
                    modalState.paging.hasPrevious = data.payload.paging.hasPrevious
                })
                .catch((error) => {
                    console.error(`Error in clicks modal`, error)
                    modalState.clicks = []
                })
        },

        onPlatformClick(platformId) {
            this.platformId = platformId
            this.getReport(true)
        },

        openModal(modalId, creator) {
            this.state.modals[modalId].is_open = true
            this.state.modals[modalId].supercampaign_id = this.$route.params.supercampaign_id
            this.state.modals[modalId].campaign_id = this.$route.params.campaign_id
            this.state.modals[modalId].participant_id = creator.participant.id
            this.state.modals[modalId].participant = creator.participant

            this.state.modals[modalId].paging.currentPage = 0

            if (modalId === 'clicksList') {
                this.getCreatorClicks()
            } else if (modalId === 'mentionsList') {
                this.state.modals[modalId].mentions = creator.mentions.items

                if (creator?.mentions?.items?.length) {
                    this.state.modals.mentionsList.paging.totalPages = Math.ceil(
                        creator.mentions.items.length / this.state.modals.mentionsList.paging.perPage,
                    )
                }
            }
        },

        onPagingChange(updatedPaging) {
            _.assign(this.state.tables.creators.paging, updatedPaging)
            this.getReport(false)
        },

        onSearch: _.debounce(() => {
            this.state.tables.creators.paging.currentPage = 1
            this.getReport(false)
        }, 100),

        resetPagingAndSorting() {
            this.state.tables.creators.sort.field = ''
            this.state.tables.creators.sort.direction = ''
            this.state.tables.creators.paging.perPage = 100
            this.state.tables.creators.paging.currentPage = 1
            this.state.tables.creators.searchText = ''
        },

        async getReport(isReset) {
            if (isReset) {
                this.resetPagingAndSorting()
            }

            const campaignId = this.$route.params.campaign_id
            this.state.isLoaded = !isReset
            this.state.has_utm_data = false
            this.state.has_mention_data = false

            const paging = {
                sortby: this.state.tables.creators.sort.field,
                sortdir: this.state.tables.creators.sort.direction,
                perPage: this.state.tables.creators.paging.perPage,
                currentPage: this.state.tables.creators.paging.currentPage,
                filters: [{ id: 'name', value: this.state.tables.creators.searchText }],
            }

            if (this.supercampaign.payload.is_demo !== true) {
                if (this.campaign.type === 'key-distribution' && this.platformId === 'twitch') {
                    paging.filters.push({ id: 'min-duration', value: 60 * 60 })
                }

                if (this.campaign.type === 'key-distribution' && this.platformId === 'youtube') {
                    paging.filters.push({ id: 'min-views', value: 1 })
                }
            }

            const reportResult = await reportsAPI.getCampaignReport(
                this.$axios,
                this.supercampaign.id,
                campaignId,
                this.platformId,
                paging,
            )

            if (reportResult.success) {
                const report = reportResult.value
                this.metrics = report.data.aggregates

                if (this.supercampaign.payload.is_demo) {
                    this.creators = _.map(report.items, ({ data }) => {
                        const result = _.cloneDeep(data)

                        if (this.platformId === 'twitch') {
                            result.summary.avg_viewers = 250 + Math.random() * 4750

                            const hourlyRate = Math.round(result.summary.avg_viewers / 10) * 10

                            result.summary.chapters_count = 1 + Math.random() * 2
                            result.summary.cost = (Math.round((hourlyRate || result.summary.avg_viewers) * 2) / 10) * 10
                            result.summary.cpc = 1 + Math.random() * 9
                            result.summary.unique_clicks = Math.round(result.summary.cost / result.summary.cpc)
                            result.summary.total_clicks = result.summary.unique_clicks
                            result.summary.target_duration_seconds = 60 * 120 // 2 hours
                            result.summary.sum_duration_seconds = Math.round(
                                result.summary.target_duration_seconds * (1 + Math.random() * 2.5),
                            )
                            result.summary.sum_seconds_watched =
                                result.summary.sum_duration_seconds * result.summary.avg_viewers
                            result.summary.over_delivery =
                                result.summary.sum_duration_seconds / result.summary.target_duration_seconds - 1
                            result.summary.acv_performance = Math.random() * 0.75
                            result.summary.content_value = result.summary.cost * (result.summary.over_delivery + 1)

                            this.metrics.sum_seconds_watched += result.summary.sum_seconds_watched
                            this.metrics.sum_unique_clicks += result.summary.unique_clicks
                            this.metrics.sum_duration_seconds += result.summary.sum_duration_seconds
                        }

                        if (this.platformId === 'youtube') {
                            // result.summary.sum_views = _.get(result.superview, 'video.day_90.aggregation.views.avg')
                            result.summary.sum_views = 10000 + Math.random() * 90000

                            result.summary.cpm = 25 + Math.random() * 25

                            result.summary.cost =
                                Math.round(((result.summary.sum_views / 1000) * result.summary.cpm) / 10) * 10
                            result.summary.cpc = 1 + Math.random() * 9
                            result.summary.unique_clicks = Math.round(result.summary.cost / result.summary.cpc)
                            result.summary.total_clicks = result.summary.unique_clicks
                            result.summary.sum_engagements = result.summary.sum_views / 50
                            result.summary.acv_performance = Math.random() * 0.75

                            this.metrics.sum_engagements += result.summary.sum_engagements
                            this.metrics.sum_unique_clicks += result.summary.unique_clicks
                            this.metrics.sum_views += result.summary.sum_views
                        }

                        result.utm_data = {
                            purchases: Math.round(result.summary.unique_clicks * 0.1),
                            wishlists: Math.round(result.summary.unique_clicks * 0.25),
                        }

                        return result
                    })

                    this.metrics.avg_over_delivery = 1.5
                    this.state.has_utm_data = true
                } else {
                    this.creators = _.map(report.items, 'data')
                    this.state.has_utm_data = report.data.has_utm_data
                    this.state.has_mention_data = report.data.has_mention_data

                    this.keywords = report.data.keywords
                }
                this.state.tables.creators.paging.totalRecords = report.pager.totalRecords
                this.state.tables.creators.paging.totalPages = Math.ceil(
                    report.pager.totalRecords / this.state.tables.creators.paging.perPage,
                )

                if (this.campaign.type === 'game-sponsorship') {
                    if (this.platformId === 'twitch') {
                        _.forEach(this.creators, (creator) => {
                            if (creator.summary.over_delivery < 0 && creator.summary.over_delivery > -0.1) {
                                // eslint-disable-next-line no-param-reassign
                                creator.summary.sum_duration_seconds = creator.summary.target_duration_seconds
                            }
                        })
                    }
                }

                this.state.isLoaded = true
            } else {
                this.$errorHandler(null, {
                    title: `Campaign Report`,
                    message: `Error fetching summary for campaign.`,
                })
            }
        },

        async exportReport() {
            const campaignId = this.$route.params.campaign_id
            const exportReportResult = await reportsAPI.getCampaignReport(
                this.$axios,
                this.supercampaign.id,
                campaignId,
                this.platformId,
                {},
                true,
            )

            if (!exportReportResult.success) {
                return
            }

            const exportReport = exportReportResult.value
            const link = document.createElement('a')

            const fileName = `${this.supercampaign.title} - ${this.getFromDictionary(
                `campaign.type.${this.campaign.type}`,
                'labelPlural',
            )} - ${this.platformId} - Report.csv`

            const platformCreators = _.map(exportReport.items, (creator) => {
                let exportedRow = {
                    Creator: _.get(creator, 'data.creator.platform_user_name'),
                    'Connect Page': `https://streamforge.com/${this.platformId}/${_.get(
                        creator,
                        'data.creator.platform_user_name',
                    )}`,
                }

                /*
                    if (this.isSfStaff) {
                        exportedRow['Email'] = _.has(creator, 'data.superview.normalized.email')
                            ? _.get(creator, 'data.superview.normalized.email')
                            : ''
                    }
                */

                if (this.platformId === 'twitch') {
                    const streamedTime = _.get(creator, 'data.summary.sum_duration_seconds')
                    const hiredTime = _.get(creator, 'data.summary.target_duration_seconds')
                    const contentValue = _.get(creator, 'data.summary.content_value')

                    exportedRow = {
                        ...exportedRow,
                        'Stream ACV': numeral(_.get(creator, 'data.summary.avg_viewers')).format('0,0'),
                        'ACV Performance': numeral(_.get(creator, 'data.summary.acv_performance')).format('+0,0.0%'),
                        'Unique Clicks': numeral(_.get(creator, 'data.summary.unique_clicks')).format('0,0'),
                        CPC: this.$filters.formatCurrency(_.get(creator, 'data.summary.cpc')),
                        [this.campaign.type === 'key-distribution' ? 'Est. Market Rate' : 'Spend']:
                            this.$filters.formatCurrency(_.get(creator, 'data.summary.cost')),
                        'Content Value': contentValue || '',
                        'Minutes Hired': hiredTime ? Math.round(hiredTime / 60) : '',
                        'Minutes Streamed': streamedTime ? Math.round(streamedTime / 60) : '',
                        Overdelivery: numeral(_.get(creator, 'data.summary.over_delivery')).format('+0,0.0%'),
                        // 'Creator 90 Day ACV': _.get(creator, 'data.superview.stream.day_90.aggregation.viewers.avg').format('0,0'),
                        // Followers: _.get(creator, 'data.superview.channel.followers'),
                    }
                } else if (this.platformId === 'youtube') {
                    const cpm = _.get(creator, 'data.summary.cpm')
                    const cpc = _.get(creator, 'data.summary.cpc')

                    exportedRow = {
                        ...exportedRow,
                        'Video Views': numeral(_.get(creator, 'data.summary.sum_views')).format('0,0'),
                        'Views Performance': numeral(_.get(creator, 'data.summary.acv_performance')).format('+0,0.0%'),
                        Engagements: numeral(_.get(creator, 'data.summary.sum_engagements')).format('0,0'),
                        'Unique Clicks': numeral(_.get(creator, 'data.summary.unique_clicks')).format('0,0'),
                        CPC: cpm ? this.$filters.formatCurrency(cpm) : '',
                        CPM: cpc ? this.$filters.formatCurrency(cpc) : '',
                        [this.campaign.type === 'key-distribution' ? 'Est. Market Rate' : 'Spend']:
                            this.$filters.formatCurrency(_.get(creator, 'data.summary.cost')),
                        Overdelivery: numeral(_.get(creator, 'data.summary.over_delivery')).format('+0,0.0%'),
                        // 'Creator 90 Day Average Views': numeral(_.get(creator, 'data.superview.video.day_90.aggregation.views.avg')).format('0,0'),
                        // Subscribers: numeral(_.get(creator, 'data.superview.channel.statistics.subscriberCount')).format('0,0'),
                    }
                }

                return exportedRow
            })

            const csvResults = await json2csvAsync(platformCreators)

            link.setAttribute('href', `data:text/csv;charset=utf-8,${encodeURIComponent(csvResults)}`)
            link.setAttribute('download', fileName)
            link.style.visibility = 'hidden'
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
        },

        onRowClick(item) {
            this.$store.commit('drawer:set', {
                enabled: true,
                // participant: item.participant,
                platform_id: item.creator.platform_id,
                platform_user_id: item.creator.platform_user_id,
            })
        },
    },
}
</script>
