import { library, config } from '@fortawesome/fontawesome-svg-core'

import {
    faTwitch as fabTwitch,
    faYoutube as fabYoutube,
    faTwitter as fabTwitter,
    faXTwitter as fabXTwitter,
    faInstagram as fabInstagram,
    faTiktok as fabTiktok,
    faFacebook as fabFacebook,
    faSteamSymbol as fabSteamSymbol,
    faSteam as fabSteam,
    faReddit as fabReddit,
    faPatreon as fabPatreon,
    faPaypal as fabPaypal,
    faDiscord as fabDiscord,
    faSnapchat as fabSnapchat,
    faThreads as fabThreads,
    faTelegram as fabTelegram,
    faWhatsapp as fabWhatsapp,
} from '@fortawesome/free-brands-svg-icons'

import {
    faFileContract as fasFileContract,
    faUserGroup as fasUsergroup,
    faCalendarCircleUser as fasCalendarCircleUser,
    faHome as fasHome,
    faCalendar as fasCalendar,
    faArrowUpRightFromSquare as fasArrowUpRightFromSquare,
    faSquareUser as fasSquareUser,
    faCheck as fasCheck,
    faQuestion as fasQuestion,
    faXmark as fasXmark,
    faBallotCheck as fasBallotCheck,
    faLanguage as fasLanguage,
    faPlus as fasPlus,
    faUserCheck as fasUserCheck,
    faUserPlus as fasUserPlus,
    faChevronUp as fasChevronUp,
    faChevronDown as fasChevronDown,
    faChevronRight as fasChevronRight,
    faEllipsis as fasEllipsis,
    faPenToSquare as fasPenToSquare,
    faArrowUpRight as fasArrowUpRight,
    faArrowDownRight as fasArrowDownRight,
    faCircleInfo as fasCircleInfo,
    faCircleArrowUp as fasCircleArrowUp,
    faArrowUp as fasArrowUp,
    faList as fasList,
    faCog as fasCog,
    faTrash as fasTrash,
    faDollarSign as fasDollarSign,
    faKey as fasKey,
    faShare as fasShare,
    faRectangleList as fasRectangleList,
    faFilter as fasFilter,
    faArrowDownWideShort as fasArrowDownWideShort,
    faArrowUpShortWide as fasArrowUpShortWide,
    faTrashCan as fasTrashCan,
    faArrowUpArrowDown as fasArrowUpArrowDown,
    faFloppyDiskCircleArrowRight as fasFloppyDiskCircleArrowRight,
    faGear as fasGear,
    faBullseyePointer as fasBullseyePointer,
    faUsers as fasUsers,
    faServer as fasServer,
    faSparkles as fasSparkles,
    faEnvelope as fasEnvelope,
    faGamepadModern as fasGamepadModern,
    faSignalStream as fasSignalStream,
    faFamily as fasFamily,
    faArrowRight as fasArrowRight,
    faArrowLeft as fasArrowLeft,
    faRepeat as fasRepeat,
    faUser as fasUser,
    faStar as fasStar,
    faMicrochipAi as fasMicrochipAi,
    faMagicWandSparkles as fasMagicWandSparkles,
    faSpinner as fasSpinner,
    faArrowsRotate as fasArrowsRotate,
    faBoltLightning as fasBoltLightning,
    faFaceLaughSquint as fasFaceLaughSquint,
    faMasksTheater as fasMasksTheater,
    faFire as fasFire,
    faGamepad as fasGamepad,
    faStopwatch as fasStopwatch,
    faChalkboardTeacher as fasChalkboardTeacher,
    faStarHalfAlt as fasStarHalfAlt,
    faBookOpen as fasBookOpen,
    faMedal as fasMedal,
    faChartBar as fasChartBar,
    faEgg as fasEgg,
    faFilm as fasFilm,
    faCogs as fasCogs,
    faClipboardList as fasClipboardList,
    faBullhorn as fasBullhorn,
    faCalendarAlt as fasCalendarAlt,
    faFireAlt as fasFireAlt,
    faSkullCrossbones as fasSkullCrossbones,
    faBoxOpen as fasBoxOpen,
    faMicroscope as fasMicroscope,
    faStreetView as fasStreetView,
    faArchive as fasArchive,
    faCommentAlt as fasCommentAlt,
    faLightbulb as fasLightbulb,
    faMicrophoneAlt as fasMicrophoneAlt,
    faVideo as fasVideo,
    faChild as fasChild,
    faGraduationCap as fasGraduationCap,
    faTools as fasTools,
    faUserAlt as fasUserAlt,
    faTheaterMasks as fasTheaterMasks,
    faWheelchair as fasWheelchair,
    faChess as fasChess,
    faLaughBeam as fasLaughBeam,
    faHandPointer as fasHandPointer,
    faCameraRetro as fasCameraRetro,
    faMobileAlt as fasMobileAlt,
    faBalanceScale as fasBalanceScale,
    faStream as fasStream,
    faMusic as fasMusic,
    faBook as fasBook,
    faVrCardboard as fasVrCardboard,
    faLaptopCode as fasLaptopCode,
    faPuzzlePiece as fasPuzzlePiece,
    faTractor as fasTractor,
    faRobot as fasRobot,
    faHeart as fasHeart,
    faExchangeAlt as fasExchangeAlt,
    faVideoSlash as fasVideoSlash,
    faClock as fasClock,
    faEngineWarning as fasEngineWarning,
    faFilmAlt as fasFilmAlt,
    faGlasses as fasGlasses,
    faBasketballBall as fasBasketballBall,
    faMousePointer as fasMousePointer,
    faMask as fasMask,
    faComments as fasComments,
    faSmileBeam as fasSmileBeam,
    faGrinStars as fasGrinStars,
    faChartLine as fasChartLine,
    faLaugh as fasLaugh,
    faBolt as fasBolt,
    faSpa as fasSpa,
    faHandshake as fasHandshake,
    faSun as fasSun,
    faCloud as fasCloud,
    faHatWizard as fasHatWizard,
    faMeh as fasMeh,
    faSearch as fasSearch,
    faHandHeart as fasHandHeart,
    faSmileWink as fasSmileWink,
    faUserSecret as fasUserSecret,
    faUsers as fasUsers2,
    faPaintBrush as fasPaintBrush,
    faDice as fasDice,
    faAward as fasAward,
    faUserLock as fasUserLock,
    faAngry as fasAngry,
    faGrinTongue as fasGrinTongue,
    faHandsHelping as fasHandsHelping,
    faTrophy as fasTrophy,
    faBriefcase as fasBriefcase,
    faUserTie as fasUserTie,
    faTshirt as fasTshirt,
    faJournalWhills as fasJournalWhills,
    faLeaf as fasLeaf,
    faFistRaised as fasFistRaised,
    faGift as fasGift,
    faQuestion as fasQuestion2,
    faHatCowboy as fasHatCowboy,
    faMemory as fasMemory,
    faShieldAlt as fasShieldAlt,
    faEye as fasEye,
    faBrain as fasBrain,
    faCar as fasCar,
    faMagic as fasMagic,
    faFrown as fasFrown,
    faCircleQuestion as fasCircleQuestion,
    faCaretDown as fasCaretDown,
    faUnlock as fasUnlock,
    faShoppingCart as fasShoppingCart,
    faTriangleExclamation as fasTriangleExclamation,
    faArrowsUpDown as fasArrowsUpDown,
    faVenusMars as fasVenusMars,
    faLineColumns as fasLineColumns,
    faHashtag as fasHashtag,
    faTags as fasTags,
    faComputer as fasComputer,
    faEarthAmericas as fasEarthAmericas,
    faSquareInfo as fasSquareInfo,
    faEyeSlash as fasEyeSlash,
    faSpinnerThird as fasSpinnerThird,
    faCopy as fasCopy,
    faCamera as fasCamera,
    faShield as fasShieldHalved,
    faBirthdayCake as fasBirthdayCake,
    faUsersViewfinder as fasUsersViewfinder,
} from '@fortawesome/pro-solid-svg-icons'

import {
    faDollarSign as fadDollarSign,
    faCalendar as fadCalendar,
    faGamepadModern as fadGamepadModern,
    faMagnifyingGlass as fadMagnifyingGlass,
    faBuilding as fadBuilding,
    faUser as fadUser,
    faUsers as fadUsers,
    faRocketLaunch as fadRocketLaunch,
    faFilters as fadFilters,
    faSidebarFlip as fadSidebarFlip,
    faMessagesDollar as fadMessagesDollar,
    faCoins as fadCoins,
    faThumbsUp as fadThumbsUp,
    faRectangleVerticalHistory as fadRectangleVerticalHistory,
} from '@fortawesome/pro-duotone-svg-icons'

// import fontawesome icon component
import FontAwesomeIcon from './FontAwesomeIcon.vue'

// add the icon style(s) you have installed to the library
library.add(
    // Brand
    fabTwitch,
    fabYoutube,
    fabTwitter,
    fabXTwitter,
    fabInstagram,
    fabTiktok,
    fabFacebook,
    fabSteamSymbol,
    fabSteam,
    fabReddit,
    fabPatreon,
    fabPaypal,
    fabDiscord,
    fabSnapchat,
    fabThreads,
    fabTelegram,
    fabWhatsapp,

    // Solid
    fasFileContract,
    fasUsergroup,
    fasCalendarCircleUser,
    fasHome,
    fasArrowUpRightFromSquare,
    fasSquareUser,
    fasCheck,
    fasQuestion,
    fasXmark,
    fasBallotCheck,
    fasLanguage,
    fasPlus,
    fasUserCheck,
    fasUserPlus,
    fasCalendar,
    fasChevronUp,
    fasChevronDown,
    fasChevronRight,
    fasEllipsis,
    fasPenToSquare,
    fasArrowUpRight,
    fasArrowDownRight,
    fasCircleInfo,
    fasCircleArrowUp,
    fasArrowUp,
    fasList,
    fasCog,
    fasTrash,
    fasDollarSign,
    fasKey,
    fasShare,
    fasRectangleList,
    fasFilter,
    fasArrowDownWideShort,
    fasArrowUpShortWide,
    fasTrashCan,
    fasArrowUpArrowDown,
    fasFloppyDiskCircleArrowRight,
    fasGear,
    fasBullseyePointer,
    fasUsers,
    fasServer,
    fasSparkles,
    fasEnvelope,
    fasGamepadModern,
    fasSignalStream,
    fasFamily,
    fasArrowRight,
    fasArrowLeft,
    fasRepeat,
    fasUser,
    fasStar,
    fasMicrochipAi,
    fasMagicWandSparkles,
    fasSpinner,
    fasArrowsRotate,
    fasBoltLightning,
    fasFaceLaughSquint,
    fasMasksTheater,
    fasFire,
    fasTriangleExclamation,
    fasArrowsUpDown,
    fasGrinStars,
    fasChartLine,
    fasLaugh,
    fasBolt,
    fasSpa,
    fasComments,
    fasHandshake,
    fasSun,
    fasCloud,
    fasHatWizard,
    fasMeh,
    fasSearch,
    fasMask,
    fasHeart,
    fasHandHeart,
    fasSmileWink,
    fasBook,
    fasUserSecret,
    fasUsers2,
    fasPaintBrush,
    fasDice,
    fasAward,
    fasUserLock,
    fasAngry,
    fasBalanceScale,
    fasGrinTongue,
    fasHandsHelping,
    fasTrophy,
    fasBriefcase,
    fasTshirt,
    fasJournalWhills,
    fasLeaf,
    fasFistRaised,
    fasGift,
    fasQuestion2,
    fasClock,
    fasHatCowboy,
    fasMemory,
    fasShieldAlt,
    fasEye,
    fasBrain,
    fasCar,
    fasMagic,
    fasTheaterMasks,
    fasFrown,
    fasUserTie,
    fasGamepad,
    fasStopwatch,
    fasChalkboardTeacher,
    fasStarHalfAlt,
    fasBookOpen,
    fasMedal,
    fasChartBar,
    fasEgg,
    fasFilm,
    fasCogs,
    fasClipboardList,
    fasBullhorn,
    fasCalendarAlt,
    fasFireAlt,
    fasSkullCrossbones,
    fasBoxOpen,
    fasMicroscope,
    fasStreetView,
    fasArchive,
    fasCommentAlt,
    fasLightbulb,
    fasMicrophoneAlt,
    fasVideo,
    fasChild,
    fasGraduationCap,
    fasHome,
    fasTools,
    fasUserAlt,
    fasWheelchair,
    fasChess,
    fasLaughBeam,
    fasHandPointer,
    fasCameraRetro,
    fasMobileAlt,
    fasStream,
    fasMusic,
    fasBook,
    fasVrCardboard,
    fasLaptopCode,
    fasPuzzlePiece,
    fasTractor,
    fasRobot,
    fasHeart,
    fasExchangeAlt,
    fasVideoSlash,
    fasEngineWarning,
    fasFilmAlt,
    fasGlasses,
    fasBasketballBall,
    fasLanguage,
    fasMousePointer,
    fasSmileBeam,
    fasCircleQuestion,
    fasCaretDown,
    fasUnlock,
    fasShoppingCart,
    fasVenusMars,
    fasLineColumns,
    fasHashtag,
    fasTags,
    fasComputer,
    fasEarthAmericas,
    fasSquareInfo,
    fasEyeSlash,
    fasSpinnerThird,
    fasCopy,
    fasCamera,
    fasShieldHalved,
    fasBirthdayCake,
    fasUsersViewfinder,

    // Duotone
    fadDollarSign,
    fadCalendar,
    fadGamepadModern,
    fadMagnifyingGlass,
    fadBuilding,
    fadUser,
    fadUsers,
    fadRocketLaunch,
    fadFilters,
    fadSidebarFlip,
    fadMessagesDollar,
    fadCoins,
    fadThumbsUp,
    fadRectangleVerticalHistory,
)

// set the default style
config.styleDefault = 'solid'

export default {
    install(app) {
        app.component('FontAwesomeIcon', FontAwesomeIcon)
    },
}
