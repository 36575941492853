<template>
    <div class="relative">
        <div :id="tooltipId" class="transition duration-200 hover:text-slate-500">
            <slot name="trigger">
                <svg class="h-4 w-4 fill-current text-slate-400" viewBox="0 0 16 16">
                    <path
                        d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zm0 12c-.6 0-1-.4-1-1s.4-1 1-1 1 .4 1 1-.4 1-1 1zm1-3H7V4h2v5z"
                    />
                </svg>
            </slot>
        </div>
        <div :id="`${tooltipId}Content`" class="hidden">
            <div class="whitespace-wrap p-1.5 text-slate-200">
                <transition
                    enter-active-class="transition ease-out duration-200 transform"
                    enter-from-class="opacity-0 -translate-y-2"
                    enter-to-class="opacity-100 translate-y-0"
                    leave-active-class="transition ease-out duration-200"
                    leave-from-class="opacity-100"
                    leave-to-class="opacity-0"
                >
                    <!-- eslint-disable-next-line vue/require-toggle-inside-transition -->
                    <div>
                        <!-- eslint-disable-next-line vue/require-toggle-inside-transition -->
                        <slot />
                    </div>
                </transition>
            </div>
        </div>
    </div>
</template>

<script>
let tooltipCounter = 0

export default {
    props: {
        maxWidth: {
            type: String,
            required: false,
        },
        bg: {
            type: String,
            required: false,
        },
        placement: {
            type: String,
            required: false,
            default: () => 'bottom',
        },
        showTooltip: {
            type: Boolean,
            required: false,
            default: () => true,
        },
    },

    data() {
        // eslint-disable-next-line no-return-assign
        return {
            tooltipId: `tooltip-${(tooltipCounter += 1)}`,
            tippyInstance: null,
        }
    },

    mounted() {
        this.initializeTippyInstance()
    },
    beforeUnmount() {
        this.destroyTippyInstance()
    },
    methods: {
        initializeTippyInstance() {
            const { tooltipId } = this
            // How does this even work?
            // eslint-disable-next-line no-undef
            const instance = tippy(`#${tooltipId}`, {
                content() {
                    const template = document.getElementById(`${tooltipId}Content`)
                    return template.innerHTML
                },
                placement: this.placement,
                allowHTML: true,
                ...(this.maxWidth && { maxWidth: Number(this.maxWidth) }),
                theme: 'tooltip-theme',
                onShow: () => {
                    return this.showTooltip
                },
            })
            this.tippyInstance = instance
        },
        destroyTippyInstance() {
            if (this?.tippyInstance?.length > 0) {
                this.tippyInstance.forEach((tippy) => {
                    tippy.destroy()
                })
            }
            this.tippyInstance = null
        },
    },
}
</script>
