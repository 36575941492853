<template>
    <div>
        <div class="flex-col divide-y rounded-sm border border-slate-100 border-slate-200 bg-white px-6 py-6">
            <!-- Page Header -->
            <div class="flex justify-between pb-12 pt-6">
                <div>
                    <h2 class="text-1xl font-bold text-slate-800 md:text-2xl">Permissions Management</h2>
                    <div class="text-sm">
                        <p>Manage permissions assigned to the organization.</p>
                    </div>
                </div>
            </div>

            <div class="grid grid-cols-12 gap-x-12 py-12">
                <div class="col-span-12 md:col-span-4">
                    <p class="font-semibold text-slate-800">
                        {{ getFromDictionary(`permissions`, 'label') }}
                    </p>
                    <div class="space-y-2 text-sm">
                        <p>
                            {{ getFromDictionary(`permissions`, 'description') }}
                        </p>
                    </div>

                    <div class="mt-3">
                        <DropdownSelect v-model="state.preset" @update:model-value="onPresetChange">
                            <SelectTrigger class="w-[180px]">
                                <SelectValue placeholder="Select a preset">{{
                                    $lodash.find(options.presets, (item) => item.key === state.preset)?.label
                                }}</SelectValue>
                            </SelectTrigger>
                            <SelectContent>
                                <SelectGroup>
                                    <SelectItem v-for="preset in options.presets" :key="preset.key" :value="preset.key">
                                        {{ preset.label }}
                                    </SelectItem>
                                </SelectGroup>
                            </SelectContent>
                        </DropdownSelect>
                    </div>
                </div>

                <div class="col-span-12 flex md:col-span-8">
                    <PermissionsTable
                        :is-loading="!state.permissions.isLoaded"
                        :available-permissions="availableNonLimitationPermissions"
                        :granted-permissions="grantedNonLimitationPermissions"
                        @change-permission="onChangePermission"
                    />
                </div>
            </div>

            <div class="grid grid-cols-12 gap-x-12 py-12">
                <div class="col-span-12 md:col-span-4">
                    <p class="font-semibold text-slate-800">
                        {{ getFromDictionary(`permissions.limitations`, 'label') }}
                    </p>
                    <div class="space-y-2 text-sm">
                        <p>
                            {{ getFromDictionary(`permissions.limitations`, 'description') }}
                        </p>
                    </div>
                </div>

                <div class="col-span-12 flex flex-col md:col-span-8">
                    <DropdownSelect
                        v-model="state.permissions.limitations.role"
                        @update:model-value="onLimitPresetChange"
                    >
                        <SelectTrigger class="w-[240px]">
                            <SelectValue placeholder="Select a limit preset">{{
                                $lodash.find(
                                    availableLimitationPermissions,
                                    (item) => item.key === state.permissions.limitations.role,
                                )?.label
                            }}</SelectValue>
                        </SelectTrigger>
                        <SelectContent>
                            <SelectGroup>
                                <SelectItem
                                    v-for="permission in availableLimitationPermissions"
                                    :key="permission.key"
                                    :value="permission.key"
                                >
                                    {{ permission.label }}
                                </SelectItem>
                            </SelectGroup>
                        </SelectContent>
                    </DropdownSelect>
                    <div class="mt-5">
                        <div v-for="limit in options.limits" :key="limit.key" class="flex h-10 items-center">
                            <div class="w-72 text-sm">
                                {{ limit.label }}
                            </div>
                            <div class="form-switch">
                                <input
                                    :id="limit.key"
                                    v-model="state.permissions.limitations.limits[limit.key].enabled"
                                    type="checkbox"
                                    class="sr-only"
                                    :onchange="onLimitChange"
                                />
                                <label class="bg-slate-400" :for="limit.key">
                                    <span class="bg-white shadow-sm" aria-hidden="true"></span>
                                    <span class="sr-only">Toggle</span>
                                </label>
                            </div>
                            <div class="ml-5">
                                <input
                                    v-if="state.permissions.limitations.limits[limit.key].enabled"
                                    :id="limit.key"
                                    v-model="state.permissions.limitations.limits[limit.key].value"
                                    class="flex h-8 w-24 rounded-md border border-input bg-background px-3 py-2 text-sm placeholder:text-muted-foreground disabled:cursor-not-allowed disabled:opacity-50"
                                    type="number"
                                    :onchange="onLimitChange"
                                />
                            </div>
                            <div v-if="!state.permissions.limitations.limits[limit.key].enabled" class="ml-5">
                                Unlimited
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import _ from 'lodash'
import { mapGetters } from 'vuex'
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select'

import PermissionsTable from '@/partials/tables/PermissionsTable.vue'
import permissionsAPI from '@/utils/api/api.permissions'

export default {
    name: 'OrganizationPermissionsSubpage',

    components: {
        DropdownSelect: Select,
        SelectContent,
        SelectGroup,
        SelectItem,
        SelectTrigger,
        SelectValue,
        PermissionsTable,
    },

    props: {
        organization: {
            type: Object,
        },
    },

    data() {
        return {
            permissions: {
                availablePermissions: [],
                grantedPermissions: [],
            },

            state: {
                permissions: {
                    isLoaded: false,
                    limitations: {
                        role: 'none',
                        limits: {
                            'lists.total': {
                                key: 'lists.total',
                                value: null,
                                enabled: false,
                            },
                            'lists.size': {
                                key: 'lists.size',
                                value: null,
                                enabled: false,
                            },
                        },
                    },
                },

                preset: 'custom',
            },

            options: {
                presets: [
                    {
                        key: 'custom',
                        label: 'Custom',
                    },
                    {
                        key: 'nothing',
                        label: 'Nothing',
                        permissions: [],
                    },
                    {
                        key: 'everything',
                        label: 'Everything',
                    },
                    {
                        key: 'enterprise-paid',
                        label: 'Enterprise (Paid)',
                        permissions: ['module.campaigns', 'feature.ai.bio'],
                    },
                    {
                        key: 'discovery-paid',
                        label: 'Discovery (Paid)',
                        permissions: [
                            'module.discovery',
                            'module.lists',
                            'feature.global.emails',
                            'feature.global.creator_search',
                            'feature.discovery.twitch',
                            'feature.discovery.youtube',
                            'feature.discovery.tiktok',
                            'feature.discovery.twitter',
                            'feature.discovery.instagram',
                            'feature.lists.export',
                            'feature.ai.profiles',
                            'feature.ai.bio',
                        ],
                    },
                    {
                        key: 'discovery-trial',
                        label: 'Discovery (Trial)',
                        permissions: [
                            'module.discovery',
                            'module.lists',
                            // 'feature.global.emails',
                            'feature.global.creator_search',
                            'feature.discovery.twitch',
                            'feature.discovery.youtube',
                            'feature.discovery.tiktok',
                            'feature.discovery.twitter',
                            'feature.discovery.instagram',
                            // 'feature.lists.export',
                            // 'feature.ai.profiles',
                            'feature.ai.bio',
                        ],
                    },
                ],
                limits: [
                    { key: 'lists.total', label: 'Maximum number of lists' },
                    { key: 'lists.size', label: 'Maximum creators per list' },
                ],
            },
        }
    },

    computed: {
        ...mapGetters([
            'getFromDictionary',
            'isSfStaff',
            'allPermissions',
            'selfActiveOrganizationId',
            'defaultDataLimits',
        ]),
        grantedNonLimitationPermissions() {
            return _.filter(this.permissions.grantedPermissions, (permission) => {
                return !permission.startsWith('usage.')
            })
        },
        availableNonLimitationPermissions() {
            return _.filter(this.permissions.availablePermissions, (permission) => {
                return !permission.startsWith('usage.')
            })
        },
        grantedLimitationPermissions() {
            return _.filter(this.permissions.grantedPermissions, (permission) => {
                return permission.startsWith('usage.')
            })
        },
        availableLimitationPermissions() {
            const limitationPermissions = this.availableLimitPermissions()

            limitationPermissions.unshift({
                key: 'none',
                label: 'None',
                description: 'No limitations applied',
            })

            limitationPermissions.push({
                key: 'custom',
                label: 'Custom',
                description: 'Custom limitations applied',
            })

            return limitationPermissions
        },
    },

    watch: {
        isSfStaff(value) {
            if (value === false) {
                this.$router.push({ name: 'landing' })
            }
        },
    },

    created() {
        this.state.permissions.isLoaded = false
        this.getOrganizationPermissions()
        this.getPermissionSets()
    },

    methods: {
        syncToPreset() {
            let presetType = 'custom'

            const sortedAvailablePermissions = _.sortBy(this.permissions.grantedPermissions).join('_')

            _.forEach(this.options.presets, (preset) => {
                const sortedPresetPermissions = _.sortBy(preset.permissions).join('_')

                if (sortedAvailablePermissions === sortedPresetPermissions) {
                    presetType = preset.key
                }
            })

            this.state.preset = presetType

            this.syncLimitPreset()
        },

        syncLimitPreset() {
            const assignedLimitPreset = _.find(this.permissions.grantedPermissions, (permission) => {
                return permission.startsWith('usage.')
            })

            if (assignedLimitPreset) {
                this.state.permissions.limitations.role = assignedLimitPreset
            } else if (this.state.permissions.limitations.role !== 'custom') {
                this.state.permissions.limitations.role = 'none'
            }

            this.setLimitsFromPreset(this.state.permissions.limitations.role)
        },

        async onPresetChange(presetKey) {
            const preset = _.find(this.options.presets, (item) => item.key === presetKey)

            if (this.isSfStaff && preset) {
                await permissionsAPI.setPermissions(this.$axios, this.selfActiveOrganizationId, preset.permissions)
                this.getOrganizationPermissions()
            }
        },

        async onLimitPresetChange(limitRole) {
            this.setLimitsFromPreset(limitRole)
            this.onLimitChange()
        },

        async onChangePermission(permission, grantType) {
            if (grantType === 'grant') {
                await this.grantPermission(permission)
            } else if (grantType === 'deny') {
                await this.denyPermission(permission)
            }

            await this.getOrganizationPermissions()
        },

        availableLimitPermissions() {
            let limitPermissions = _.filter(this.permissions.availablePermissions, (permission) => {
                return permission.startsWith('usage.')
            })

            limitPermissions = _.map(limitPermissions, (permission) => {
                return {
                    key: permission,
                    label: this.getFromDictionary(`permissions.['${permission}']`, 'label'),
                    description: this.getFromDictionary(`permissions.['${permission}']`, 'description'),
                }
            })

            return limitPermissions
        },

        async addLimitRole() {
            const existingLimitPermissions = _.filter(this.permissions.grantedPermissions, (permission) => {
                return permission.startsWith('usage.')
            })

            this.permissions.grantedPermissions = _.filter(this.permissions.grantedPermissions, (permission) => {
                return !permission.startsWith('usage.')
            })
            if (!['none', 'custom'].includes(this.state.permissions.limitations.role)) {
                this.permissions.grantedPermissions.push(this.state.permissions.limitations.role)
            }

            const updatedLimitPermissions = _.filter(this.permissions.grantedPermissions, (permission) => {
                return permission.startsWith('usage.')
            })

            let somethingChanged = false

            // eslint-disable-next-line no-plusplus
            for (let i = 0; i < existingLimitPermissions.length; i++) {
                const existing = existingLimitPermissions[i]
                if (!updatedLimitPermissions.includes(existing)) {
                    // eslint-disable-next-line no-await-in-loop
                    await this.denyPermission(existing)
                    somethingChanged = true
                }
            }

            // eslint-disable-next-line no-plusplus
            for (let i = 0; i < updatedLimitPermissions.length; i++) {
                const updated = updatedLimitPermissions[i]
                if (!existingLimitPermissions.includes(updated)) {
                    // eslint-disable-next-line no-await-in-loop
                    await this.grantPermission(updated)
                    somethingChanged = true
                }
            }

            if (somethingChanged) {
                this.getOrganizationPermissions()
            }
        },

        setLimitsFromPreset(limitPreset) {
            const defaults = this.defaultDataLimits

            if (limitPreset === 'none') {
                _.forEach(this.state.permissions.limitations.limits, (limit) => {
                    limit.enabled = false
                    limit.value = null
                })
            } else if (limitPreset === 'custom') {
                // No change
            } else {
                const presetLimits = defaults[limitPreset.replace('usage.tier.', '')]
                _.forEach(this.state.permissions.limitations.limits, (limit) => {
                    const limitSetting = _.find(presetLimits, (presetLimit) => {
                        return presetLimit.key === limit.key
                    })

                    if (limitSetting && limitSetting.value) {
                        limit.enabled = true
                        limit.value = limitSetting.value
                    } else {
                        limit.enabled = false
                        limit.value = null
                    }
                })
            }

            this.addLimitRole()
        },

        async onLimitChange() {
            this.state.permissions.limitations.role = 'custom'
            const defaults = this.defaultDataLimits

            let allNone = true
            const limitPermissions = _.map(this.availableLimitPermissions(), (limit) => {
                return limit.key.replace('usage.tier.', '')
            })

            const matchedLimitPermissions = {}

            _.forEach(limitPermissions, (limitPermission) => {
                matchedLimitPermissions[limitPermission] = true
            })

            let limitationsToSave = []

            _.forEach(this.state.permissions.limitations.limits, (limit) => {
                if (limit.enabled && limit.value && limit.value !== -1) {
                    allNone = false
                    limitationsToSave.push({
                        key: limit.key,
                        value: limit.value,
                    })
                }

                _.forEach(limitPermissions, (limitPermission) => {
                    let permisionValue = _.get(defaults[limitPermission], limit.key)
                    let newValue = null

                    if (limit.enabled && limit.value && limit.value !== -1) {
                        newValue = limit.value
                    }

                    if (permisionValue === -1) {
                        permisionValue = null
                    }

                    if (permisionValue !== newValue) {
                        matchedLimitPermissions[limitPermission] = false
                    }
                })
            })

            let isNotCustom = false

            if (allNone) {
                this.state.permissions.limitations.role = 'none'
                isNotCustom = true
            }

            _.forEach(limitPermissions, (limitPermission) => {
                if (matchedLimitPermissions[limitPermission] === true) {
                    this.state.permissions.limitations.role = `usage.tier.${limitPermission}`
                    isNotCustom = true
                }
            })

            this.addLimitRole()

            if (isNotCustom) {
                limitationsToSave = []
            }

            await this.saveLimitations(limitationsToSave)
        },

        async getOrganizationPermissions() {
            if (this.isSfStaff) {
                const result = await permissionsAPI.getOrganizationPermissions(
                    this.$axios,
                    this.selfActiveOrganizationId,
                )

                if (result.success) {
                    this.state.permissions.isLoaded = true

                    const everythingPreset = _.find(this.options.presets, (preset) => preset.key === 'everything')

                    everythingPreset.permissions = result.value.availablePermissions

                    this.permissions.availablePermissions = result.value.availablePermissions
                    this.permissions.grantedPermissions = result.value.grantedPermissions

                    if (result.value.limitations) {
                        _.forEach(result.value.limitations, (limit) => {
                            if (limit.value && limit.value !== -1) {
                                this.state.permissions.limitations.limits[limit.key].value = limit.value
                                this.state.permissions.limitations.limits[limit.key].enabled = true
                                this.state.permissions.limitations.role = 'custom'
                            }
                        })
                    }

                    this.syncToPreset()
                }
            }
        },

        async getPermissionSets() {
            if (this.isSfStaff) {
                const result = await permissionsAPI.getPermissionSets(this.$axios, this.selfActiveOrganizationId)
                _.forOwn(result.value.products, (value) => {
                    this.options.presets.push({
                        key: value.key,
                        label: value.label,
                        permissions: value.roles,
                    })
                })
            }
        },

        async denyPermission(permission) {
            if (this.isSfStaff) {
                await permissionsAPI.denyPermission(this.$axios, this.selfActiveOrganizationId, permission)
            }
        },

        async grantPermission(permission) {
            if (this.isSfStaff) {
                await permissionsAPI.grantPermission(this.$axios, this.selfActiveOrganizationId, permission)
            }
        },

        async saveLimitations(limitations) {
            if (this.isSfStaff) {
                await permissionsAPI.saveLimitations(this.$axios, this.selfActiveOrganizationId, limitations)
            }
        },
    },
}
</script>
