<template>
    <div class="mb-8 w-full md:mb-0 md:w-60">
        <div class="no-scrollbar md:sticky md:top-16 md:h-[calc(100vh-64px)] md:overflow-y-auto md:overflow-x-hidden">
            <div class="md:py-8">
                <!-- Title -->
                <header class="mb-8">
                    <h1 class="text-2xl font-bold text-slate-800 md:text-3xl">{{ organization?.name }}</h1>
                    <!-- <div class="text-xs inline-flex font-medium bg-slate-700 text-slate-100 rounded-full text-center px-2.5 py-1">{{ $voca.capitalize(organization?.type) }}</div> -->
                </header>

                <!-- Links -->
                <div
                    class="no-scrollbar -mx-4 flex flex-nowrap overflow-x-scroll px-4 md:block md:space-y-3 md:overflow-auto"
                >
                    <!-- Group 1 -->
                    <div>
                        <div class="mb-3 text-xs font-semibold uppercase text-slate-400 md:sr-only">Menu</div>
                        <ul class="mr-3 flex flex-nowrap md:mr-0 md:block">
                            <li class="mr-0.5 opacity-50 md:mb-0.5 md:mr-0">
                                <a class="flex items-center whitespace-nowrap rounded px-2.5 py-2">
                                    <svg class="mr-2 h-4 w-4 shrink-0 fill-current text-slate-400" viewBox="0 0 16 16">
                                        <path
                                            d="M10 16h4c.6 0 1-.4 1-.998V6.016c0-.3-.1-.6-.4-.8L8.6.226c-.4-.3-.9-.3-1.3 0l-6 4.992c-.2.2-.3.5-.3.799v8.986C1 15.6 1.4 16 2 16h4c.6 0 1-.4 1-.998v-2.996h2v2.996c0 .599.4.998 1 .998Zm-4-5.99c-.6 0-1 .399-1 .998v2.995H3V6.515L8 2.32l5 4.194v7.488h-2v-2.995c0-.6-.4-.999-1-.999H6Z"
                                        />
                                    </svg>
                                    <span class="text-sm font-medium">Overview</span>
                                </a>
                            </li>

                            <router-link
                                v-slot="{ href, navigate, isExactActive }"
                                :to="{
                                    name: 'organization-users',
                                }"
                                custom
                            >
                                <li class="mr-0.5 md:mb-0.5 md:mr-0">
                                    <a
                                        class="flex items-center whitespace-nowrap rounded px-2.5 py-2"
                                        :class="isExactActive && 'bg-white'"
                                        :href="href"
                                        @click="navigate"
                                    >
                                        <font-awesome-icon
                                            :icon="['fas', 'users']"
                                            class="mr-2 h-4 w-4 shrink-0 fill-current text-slate-400"
                                            :class="isExactActive ? 'text-indigo-500' : 'text-slate-400'"
                                        />

                                        <span
                                            class="text-sm font-medium"
                                            :class="isExactActive ? 'text-indigo-500' : 'text-slate-600'"
                                        >
                                            Users
                                        </span>
                                    </a>
                                </li>
                            </router-link>

                            <router-link
                                v-if="isSfStaff"
                                v-slot="{ href, navigate, isExactActive }"
                                :to="{
                                    name: 'organization-permissions',
                                }"
                                custom
                            >
                                <li class="mr-0.5 md:mb-0.5 md:mr-0">
                                    <a
                                        class="flex items-center whitespace-nowrap rounded px-2.5 py-2"
                                        :class="isExactActive && 'bg-white'"
                                        :href="href"
                                        @click="navigate"
                                    >
                                        <font-awesome-icon
                                            :icon="['fas', 'unlock']"
                                            class="mr-2 h-4 w-4 shrink-0 fill-current text-slate-400"
                                            :class="isExactActive ? 'text-indigo-500' : 'text-slate-400'"
                                        />

                                        <span
                                            class="text-sm font-medium"
                                            :class="isExactActive ? 'text-indigo-500' : 'text-slate-600'"
                                        >
                                            Permissions
                                        </span>
                                    </a>
                                </li>
                            </router-link>

                            <router-link
                                v-if="isSfStaff"
                                v-slot="{ href, navigate, isExactActive }"
                                :to="{
                                    name: 'organization-subscriptions',
                                }"
                                custom
                            >
                                <li class="mr-0.5 md:mb-0.5 md:mr-0">
                                    <a
                                        class="flex items-center whitespace-nowrap rounded px-2.5 py-2"
                                        :class="isExactActive && 'bg-white'"
                                        :href="href"
                                        @click="navigate"
                                    >
                                        <font-awesome-icon
                                            :icon="['fas', 'cart-shopping']"
                                            class="mr-2 h-4 w-4 shrink-0 fill-current text-slate-400"
                                            :class="isExactActive ? 'text-indigo-500' : 'text-slate-400'"
                                        />

                                        <span
                                            class="text-sm font-medium"
                                            :class="isExactActive ? 'text-indigo-500' : 'text-slate-600'"
                                        >
                                            Subscriptions
                                        </span>
                                    </a>
                                </li>
                            </router-link>

                            <!-- <router-link
                                v-if="
                                    isSfStaff ||
                                    ['admin', 'superadmin'].includes(
                                        selfActiveOrganization?.organization_operator?.role,
                                    )
                                "
                                v-slot="{ href, navigate, isExactActive }"
                                :to="{
                                    name: 'organization-subscriptions',
                                }"
                                custom
                            >
                                <li class="mr-0.5 md:mb-0.5 md:mr-0">
                                    <a
                                        class="flex items-center whitespace-nowrap rounded px-2.5 py-2"
                                        :class="isExactActive && 'bg-white'"
                                        :href="href"
                                        @click="navigate"
                                    >
                                        <font-awesome-icon
                                            :icon="['fas', 'cart-shopping']"
                                            class="mr-2 h-4 w-4 shrink-0 fill-current text-slate-400"
                                            :class="isExactActive ? 'text-indigo-500' : 'text-slate-400'"
                                        />

                                        <span
                                            class="text-sm font-medium"
                                            :class="isExactActive ? 'text-indigo-500' : 'text-slate-600'"
                                        >
                                            Subscriptions
                                        </span>
                                    </a>
                                </li>
                            </router-link> -->

                            <li class="mr-0.5 opacity-50 md:mb-0.5 md:mr-0">
                                <a class="flex items-center whitespace-nowrap rounded px-2.5 py-2">
                                    <font-awesome-icon
                                        :icon="['fas', 'server']"
                                        class="mr-2 h-4 w-4 shrink-0 fill-current text-slate-400"
                                    />
                                    <span class="text-sm font-medium">Datasources</span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    name: 'OrganizationSidebar',

    props: {
        organization: {
            type: Object,
        },
    },

    computed: {
        ...mapGetters(['getFromDictionary', 'isSfStaff', 'selfActiveOrganization']),
    },
}
</script>
