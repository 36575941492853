<template>
    <main class="bg-white">
        <div class="relative flex">
            <!-- Content -->
            <div class="w-full">
                <div class="flex h-full min-h-screen flex-col after:flex-1">
                    <div class="flex h-screen flex-col">
                        <!-- Content area -->
                        <div class="my-auto">
                            <div class="mx-auto w-full max-w-9xl px-4 py-8 sm:px-6 lg:px-8">
                                <div class="m-auto max-w-2xl">
                                    <div class="mb-16 px-4 text-center">
                                        <div class="mb-8 inline-flex">
                                            <img
                                                src="@/images/404-illustration.svg"
                                                width="176"
                                                height="176"
                                                alt="404 illustration"
                                            />
                                        </div>
                                        <h2 class="mb-2 text-2xl font-bold text-slate-800">Unable to access</h2>
                                        <div class="mb-6">
                                            You're account does not currently have permission to access this page. If
                                            you think this is a mistake please contact support.
                                        </div>

                                        <router-link
                                            :to="{ path: '/' }"
                                            class="btn bg-indigo-500 text-white hover:bg-indigo-600"
                                            >Back To Dashboard</router-link
                                        >
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>

<script>
export default {
    name: 'NoAccessPage',

    components: {},
}
</script>
