<script setup>
import { set } from 'lodash'
import { useMutation, useQueryClient } from '@tanstack/vue-query'
import { computed, ref, watch } from 'vue'
import { notify } from '@kyvg/vue3-notification'
import { axios } from '@/utils/axios'
import Banner from '@/components/Banner2.vue'
import SocialInput from '@/partials/profiles/CreatorProfile/SocialInput.vue'
import { useCreator } from '@/utils/api/hooks'
import EmailField from '@/partials/profiles/CreatorProfile/EmailField.vue'
import CountryField from '@/partials/profiles/CreatorProfile/CountryField.vue'
import CityField from '@/partials/profiles/CreatorProfile/CityField.vue'
import { getFromDictionary } from '@/store/dictionary'

const props = defineProps({
    onCancel: {
        type: Function,
        required: true,
    },
    creatorId: {
        type: String,
        required: true,
    },
    onSaved: {
        type: Function,
        required: false,
        default: () => {},
    },
    linkableUsers: {
        type: Object,
        required: false,
    },
    onLinkUser: {
        type: Function,
        required: false,
    },
    onUnlinkUser: {
        type: Function,
        required: false,
    },
})

const creatorTypes = [
    'individual',
    'team',
    'media',
    'show',
    'esports',
    'traditional_sports',
    'brand',
    'publisher_developer',
]

const queryClient = useQueryClient()

const query = useCreator(props.creatorId)

const creator = computed(() => query.data.value?.data)

const mutation = useMutation({
    mutationFn: (data) => axios.put(`/creators/${props.creatorId}`, data),
    onSuccess: async () => {
        await queryClient.invalidateQueries({ queryKey: ['creators', props.creatorId] })
    },
})

const form = ref({
    metadata: {
        type: query.data.value?.data?.metadata?.type || 'individual',
        is_blacklisted: query.data.value?.data?.metadata?.is_blacklisted || false,
    },
})

const patchCreatorMutation = useMutation({
    mutationFn: () => axios.patch(`/creators/${props.creatorId}`, form.value),
    onSuccess: async () => {
        await queryClient.invalidateQueries({ queryKey: ['creators', props.creatorId] })
        notify({
            title: 'Creator Profile Updated',
            text: `Your changes have been saved.`,
            type: 'success',
        })
    },
})

watch(
    query.isSuccess,
    () => {
        for (const key of Object.keys(form.value.metadata)) {
            set(form.value.metadata, key, creator.value.metadata[key])
        }
    },
    { deep: true },
)
</script>
<template>
    <div class="relative">
        <!-- Submitting animation -->
        <div v-if="mutation.isLoading.value" class="absolute inset-0 z-20 flex h-full w-full bg-black opacity-40">
            <svg
                xmlns="http://www.w3.org/2000/svg"
                class="m-auto animate-spin"
                width="128"
                height="128"
                viewBox="0 0 512 512"
            >
                <path
                    fill-opacity="0.25"
                    fill="#FFF"
                    d="M471.346 361.447C432.283 441.057 350.662 496 256 496C123.451 496 16 388.549 16 256S123.451 16 256 16C261.209 16 266.301 16.453 271.428 16.779C262.959 16.98 256 24.088 256 32.734V64.791C256 73.312 262.732 79.922 271.223 80.652C271.531 80.68 271.812 80.789 272.121 80.816C266.805 80.33 261.441 80 256 80C158.953 80 80 158.953 80 256S158.953 432 256 432C326.711 432 387.652 389.992 415.609 329.701C415.502 329.932 415.43 330.178 415.322 330.406C411.686 338.127 414.057 347.254 421.447 351.521L449.25 367.574C457.033 372.068 467.238 369.389 471.346 361.447Z"
                />
                <path
                    fill-opacity=".75"
                    fill="#FFF"
                    d="M496 256C496 293.785 487.129 329.438 471.535 361.211C467.527 369.373 457.125 372.121 449.25 367.574L421.447 351.521C414.057 347.254 411.686 338.127 415.322 330.406C425.971 307.795 432 282.609 432 256C432 164.08 361.17 88.393 271.223 80.652C262.732 79.922 256 73.312 256 64.791V32.734C256 23.617 263.668 15.949 272.764 16.576C397.492 25.182 496 129.086 496 256Z"
                />
            </svg>
        </div>

        <div class="flex w-full flex-col">
            <div class="mb-8 mt-8 px-8">
                <h2 class="text-2xl font-semibold">Creator Settings</h2>
                <p class="text-sm text-slate-500">
                    Here you can update information about the creator that will be reflected across the platform.
                </p>

                <Banner v-if="mutation.isSuccess.value" class="mt-4" type="success" :open="true" :has-close="false">
                    <p>Overwrite was successfully saved!</p>
                </Banner>
                <Banner v-if="mutation.isError.value" class="mt-4" type="error" :open="true" :has-close="false">
                    <p>There was an error saving the overwrite. Please try again.</p>
                </Banner>
            </div>

            <!-- Overlay body -->
            <div class="overflow-auto px-7">
                <div>
                    <div class="flex justify-between rounded bg-slate-100 px-3 py-2 text-sm">
                        <div>
                            <span class="font-semibold text-slate-700">Metadata</span>
                        </div>
                    </div>
                    <div class="grid grid-cols-2 gap-4 px-3 pb-6 pt-3">
                        <EmailField v-if="creator" :creator="creator" />
                        <CountryField v-if="creator" :creator="creator" />
                        <div>
                            <label class="block text-sm font-bold">Type</label>
                            <select
                                v-model="form.metadata.type"
                                name="is_blacklisted"
                                class="mt-1 block h-10 w-full rounded-md border-0 bg-slate-50 py-1.5 pl-3 pr-10 text-sm text-gray-900 ring-1 ring-inset ring-gray-200 focus:ring-2 focus:ring-indigo-600 sm:leading-6"
                            >
                                <option v-for="creatorType in creatorTypes" :key="creatorType" :value="creatorType">
                                    {{ getFromDictionary(`superview.type.${creatorType}`, 'label') }}
                                </option>
                            </select>
                        </div>
                        <CityField v-if="creator" :creator="creator" />
                    </div>
                </div>
                <div>
                    <div class="flex justify-between rounded bg-slate-100 px-3 py-2 text-sm">
                        <div>
                            <span class="font-semibold text-slate-700">Socials</span>
                        </div>
                    </div>
                    <div class="grid grid-cols-2 gap-4 px-3 pb-6 pt-3">
                        <template v-if="creator">
                            <SocialInput
                                v-for="platformId in [
                                    'twitch',
                                    'youtube',
                                    'tiktok',
                                    'twitter',
                                    'instagram',
                                    ...['facebook', 'discord'].filter(
                                        (platformId) => creator.socials[platformId]?.primary,
                                    ),
                                ]"
                                :key="platformId"
                                :creator="creator"
                                :on-create="() => {}"
                                :platform-id="platformId"
                        /></template>
                    </div>
                </div>
                <div>
                    <div class="flex justify-between rounded bg-slate-100 px-3 py-2 text-sm">
                        <div>
                            <span class="font-semibold text-slate-700">Hiring Considerations</span>
                        </div>
                    </div>
                    <div class="grid grid-cols-2 gap-4 px-3 pb-6 pt-3">
                        <div class="flex items-center space-x-2">
                            <input
                                id="do-not-hire"
                                v-model="form.metadata.is_blacklisted"
                                type="checkbox"
                                class="form-checkbox"
                            /><label for="do-not-hire" class="text-sm">Mark as Do Not Hire</label>
                        </div>
                    </div>
                </div>
                <div>
                    <template v-if="linkableUsers">
                        <div class="formkit-form mb-8">
                            <div class="mb-4 flex justify-between rounded bg-slate-100 px-3 py-2 text-sm">
                                <div>
                                    <span class="font-semibold text-slate-700">Linked User</span>
                                </div>
                            </div>
                            <div v-if="linkableUsers.current" class="flex basis-auto px-3">
                                <span class="grow text-sm font-bold">Name</span>
                                <span class="grow">{{ linkableUsers.current.name }}</span>
                                <span class="grow text-sm font-bold">Created</span>
                                <span class="grow">{{ $filters.formatDate(linkableUsers.current.created_at) }}</span>
                                <span class="grow text-sm font-bold">Last active</span>
                                <span class="grow">{{
                                    $filters.formatDate(linkableUsers.current.last_active_at)
                                }}</span>
                                <span class="grow text-sm font-bold">Profiles</span>
                                <div class="flex grow items-center">
                                    <template v-for="profile in linkableUsers.current.profiles">
                                        <div
                                            v-if="profile.platform_id === 'twitch'"
                                            :key="profile.name"
                                            :title="profile.name"
                                        >
                                            <font-awesome-icon
                                                :icon="['fab', 'twitch']"
                                                class="btn-sm group ml-2 h-5 w-8 shrink-0 bg-twitch fill-current text-slate-100 group-hover:text-white hover:opacity-80"
                                            />
                                        </div>
                                        <div
                                            v-if="profile.platform_id === 'youtube'"
                                            :key="profile.name"
                                            :title="profile.name"
                                        >
                                            <font-awesome-icon
                                                :icon="['fab', 'youtube']"
                                                :alt="profile.name"
                                                class="btn-sm group ml-2 h-5 w-8 shrink-0 bg-youtube fill-current text-slate-100 group-hover:text-white hover:opacity-80"
                                            />
                                        </div>
                                    </template>
                                </div>
                                <div class="w-46 flex items-center">
                                    <button
                                        class="cursor-pointer text-indigo-500 disabled:cursor-not-allowed disabled:text-slate-400"
                                        :disabled="linkableUsers.current.canUnlink ? false : true"
                                        :title="
                                            linkableUsers.current.canUnlink
                                                ? 'Click to unlink the user from this participant'
                                                : 'User cannot be unlinked from this participant'
                                        "
                                        @click="onUnlinkUser()"
                                    >
                                        Unlink
                                    </button>
                                </div>
                            </div>
                            <div v-if="!linkableUsers.current" class="flex basis-auto px-3">
                                Not currently linked to a user
                            </div>
                        </div>
                        <div v-if="!linkableUsers.current" class="formkit-form mb-8">
                            <div class="mb-4 flex justify-between rounded bg-slate-100 px-3 py-2 text-sm">
                                <div>
                                    <span class="font-semibold text-slate-700">Suggested Users</span>
                                </div>
                            </div>
                            <div
                                v-for="platformUser in linkableUsers.platformMatched"
                                :key="platformUser.id"
                                class="flex basis-auto px-3"
                            >
                                <span class="grow text-sm font-bold">Name</span>
                                <span class="grow">{{ platformUser.name }}</span>
                                <span class="grow text-sm font-bold">Created</span>
                                <span class="grow">{{ $filters.formatDate(platformUser.created_at) }}</span>
                                <span class="grow text-sm font-bold">Last active</span>
                                <span class="grow">{{ $filters.formatDate(platformUser.last_active_at) }}</span>
                                <span class="grow text-sm font-bold">Profiles</span>
                                <div class="flex grow items-center">
                                    <template v-for="profile in platformUser.profiles">
                                        <div
                                            v-if="profile.platform_id === 'twitch'"
                                            :key="profile.name"
                                            :title="profile.name"
                                        >
                                            <font-awesome-icon
                                                :icon="['fab', 'twitch']"
                                                class="btn-sm group ml-2 h-5 w-8 shrink-0 bg-twitch fill-current text-slate-100 group-hover:text-white hover:opacity-80"
                                            />
                                        </div>
                                        <div
                                            v-if="profile.platform_id === 'youtube'"
                                            :key="profile.name"
                                            :title="profile.name"
                                        >
                                            <font-awesome-icon
                                                :icon="['fab', 'youtube']"
                                                :alt="profile.name"
                                                class="btn-sm group ml-2 h-5 w-8 shrink-0 bg-youtube fill-current text-slate-100 group-hover:text-white hover:opacity-80"
                                            />
                                        </div>
                                    </template>
                                </div>
                                <div class="w-46 flex items-center">
                                    <button class="cursor-pointer text-indigo-500" @click="onLinkUser(platformUser.id)">
                                        Link
                                    </button>
                                </div>
                            </div>
                            <div
                                v-if="!linkableUsers.platformMatched || linkableUsers.platformMatched.length == 0"
                                class="flex basis-auto px-3"
                            >
                                No users matched by platform
                            </div>
                        </div>
                    </template>
                </div>
            </div>
        </div>
    </div>

    <!-- Overlay footer -->
    <div class="relative w-full">
        <div class="mt-auto w-full bg-slate-100 p-7">
            <div class="flex flex-wrap justify-between space-x-2">
                <button class="btn bg-slate-200 px-5 text-slate-800 hover:bg-slate-300" @click="props.onCancel">
                    Cancel
                </button>
                <button
                    class="btn grow bg-indigo-500 text-white hover:bg-indigo-600"
                    @click="
                        async () => {
                            await patchCreatorMutation.mutateAsync()
                            props.onSaved()
                        }
                    "
                >
                    Save Changes
                </button>
            </div>
        </div>
    </div>
</template>
