<script setup>
import { onMounted, ref, watch } from 'vue'
import { useMutation } from '@tanstack/vue-query'
import { axios } from '@/utils/axios'

const props = defineProps({
    onCancel: {
        type: Function,
        required: true,
    },
    onSave: {
        type: Function,
        required: true,
    },
    creator: {
        type: Object,
        required: true,
    },
})

const value = ref('')
const error = ref(undefined)

const handleCancel = () => {
    props.onCancel()
}

watch(value, () => {
    error.value = undefined
})

const mutation = useMutation({
    mutationFn: () =>
        axios.post(`/creators/${props.creator._id}/create-custom-value`, {
            value: value.value,
            key: 'city',
        }),
})

const handleSave = async () => {
    if (value.value === '') {
        error.value = 'Please enter a value'
        return undefined
    }

    const customValue = await mutation.mutateAsync()

    return props.onSave(customValue)
}

const customValueInput = ref(null)

onMounted(() => {
    customValueInput.value.focus()
})

watch(customValueInput, () => {
    if (customValueInput.value) {
        customValueInput.value.focus()
    }
})
</script>
<template>
    <div>
        <input
            ref="customValueInput"
            v-model="value"
            data-1p-ignore
            type="text"
            class="h-10 w-full rounded-sm border-0 bg-white py-1.5 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            placeholder="e.g. Los Angeles"
        />
        <div v-if="error" class="mt-1 text-sm text-red-500">{{ error }}</div>
        <div class="mt-2 flex justify-between">
            <div class="flex justify-end">
                <button
                    type="button"
                    class="btn btn-sm bg-slate-100 shadow-none disabled:opacity-50 hover:bg-slate-200"
                    :disabled="mutation.isLoading.value"
                    @click="handleCancel"
                >
                    Cancel
                </button>
                <button
                    type="button"
                    class="btn btn-sm ml-2 bg-indigo-500 text-white disabled:bg-indigo-300 enabled:hover:bg-indigo-600"
                    :disabled="mutation.isLoading.value"
                    @click="handleSave"
                >
                    Save
                </button>
            </div>
        </div>
    </div>
</template>
